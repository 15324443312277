import {axiosInstance} from '../axiosInstance';
import {
    Analysis,
    AnalysisProductDto,
    AnalysisReportType,
    CalculateAnalysisResponse,
    CreateAnalysisDto,
    UpdateAnalysisDto,
    UpdateAnalysisHeaderDto,
} from '../dtos/analysisDto';
import {analysisPath} from '../paths';

export const getAnalyses = async (companyId: number) =>
    await axiosInstance.get<Analysis[]>(`${analysisPath.base()}/GetAll?companyId=${companyId}`).then((res) => res.data);
export const getAnalysisById = async (analysisId: number) =>
    await axiosInstance.get<Analysis>(`${analysisPath.base()}/Get/${analysisId}`).then((res) => res.data);

export const getAnalysisProducts = async (companyId: number) =>
    await axiosInstance
        .get<AnalysisProductDto[]>(`${analysisPath.base()}/GetAnalysisProducts?companyId=${companyId}`)
        .then((res) => res.data);
export const createAnalysis = async (data: CreateAnalysisDto, companyId: number) =>
    await axiosInstance
        .post<{analysisId: number}>(`${analysisPath.base()}/Create?companyId=${companyId}`, data)
        .then((res) => res.data);
export const updateAnalysis = async (analysisId: number, data: UpdateAnalysisDto) =>
    await axiosInstance.patch<number>(`${analysisPath.base()}/Update/${analysisId}`, data);

export const updateAnalysisHeader = async (analysisId: number, data: UpdateAnalysisHeaderDto) =>    
    await axiosInstance.patch<number>(`${analysisPath.base()}/Update/${analysisId}`, data).then((res) => res.data)

export const makeCalculations = async (analysisId: number) =>
    await axiosInstance
        .post<CalculateAnalysisResponse[]>(`${analysisPath.calculation()}/${analysisId}`)
        .then((res) => res.data);

export const createCopyOfAnalysis = async (analysisId: number) =>
    await axiosInstance.post(`${analysisPath.base()}/Copy/${analysisId}`).then((res) => res.data);

export const deleteAnalysisById = async (analysisId: number) =>
    await axiosInstance.delete(`${analysisPath.base()}/Delete/${analysisId}`).then((res) => res.data);
  
export const exportAnalysis = async (analysisId: number, reportType: AnalysisReportType) =>
    await axiosInstance
        .get(`${analysisPath.export()}?analysisId=${analysisId}&reportType=${reportType}`, {
            responseType: 'blob',
        })
        .then((res) => res.data);
