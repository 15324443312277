import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {PackagingHandlingCapability} from '../../api/dtos/productConfigDto';
import {useLanguage} from '../../language/LanguageProvider';
import {usePackagingHandlingCapabilityText} from '../../language/usePackagingHandlingCapabilityText';
import {CircularProgressOverlay} from '../ProgressComponents';

export type AddGWPValuesFormFields = {
    name: string;
    GWP: string;
    comment: string;
    handlingCapability?: string;
    rGWP?: string;
    trip?: string;
    primaryGroup?: string;
};
type DialogProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: (form: AddGWPValuesFormFields) => void;
    headerText: string;
    inProgress?: boolean;
    includeTrip?: boolean;
    includeRGWP?: boolean;
    primaryGroupOptions?: Record<number, string>;
};
const inputStyle = {
    my: 1,
};
export const DialogAddGWPItem: React.FC<DialogProps> = (props) => {
    const {getLangString} = useLanguage();
    const defaultValues: AddGWPValuesFormFields = {
        name: '',
        GWP: '',
        comment: '',
        handlingCapability: '',
        rGWP: props.includeRGWP ? '' : undefined,
        trip: props.includeTrip ? '' : undefined,
        primaryGroup: '',
    };

    const handleCloseClick = (event: object, reason: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        props.handleClose();
    };
    const {handleSubmit, control} = useForm<AddGWPValuesFormFields>({defaultValues});

    const {getPackagingHandlingCapabilityText, getPackagingHandlingOptions} = usePackagingHandlingCapabilityText();

    const commonRules = {required: getLangString('FIELD_IS_REQUIRED')};

    return (
        <Dialog open={props.isOpen} onClose={handleCloseClick}>
            <DialogTitle variant={'h3'} my={1}>
                {props.headerText}
            </DialogTitle>
            <DialogContent sx={{width: 500}}>
                <form onSubmit={handleSubmit(props.handleCreate)}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Controller
                            name={'name'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    label={getLangString('NAME')}
                                    sx={inputStyle}
                                />
                            )}
                        />
                        {props.primaryGroupOptions && (
                            <Controller
                                name={'primaryGroup'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <FormControl fullWidth={true} sx={inputStyle} error={!!fieldState.error}>
                                        <InputLabel>{getLangString('SELECT_PRIMARY_GROUP')}</InputLabel>
                                        <Select {...field}>
                                            {Object.keys(props.primaryGroupOptions!).map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {props.primaryGroupOptions![Number(option)]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        )}
                        <Box>
                            <Controller
                                control={control}
                                name={'GWP'}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('GWP')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{...inputStyle, mr: 1}}
                                        inputProps={{step: 0.0001, min: 0}}
                                    />
                                )}
                            />
                            {props.includeRGWP && (
                                <Controller
                                    control={control}
                                    name={'rGWP'}
                                    rules={commonRules}
                                    render={({field, fieldState}) => (
                                        <TextField
                                            {...field}
                                            label={getLangString('RGWP')}
                                            type={'number'}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            sx={{...inputStyle, mr: 1}}
                                            inputProps={{step: 0.0001, min: 0}}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box my={2}>
                        {props.includeTrip && (
                            <>
                                <Box>
                                    <Controller
                                        name={'handlingCapability'}
                                        control={control}
                                        rules={commonRules}
                                        render={({field, fieldState}) => (
                                            <FormControl fullWidth={true} sx={inputStyle} error={!!fieldState.error}>
                                                <InputLabel>
                                                    {getLangString('SELECT_PACKAGING_HANDLING_CAPABILITY')}
                                                </InputLabel>
                                                <Select {...field}>
                                                    {getPackagingHandlingOptions().map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {getPackagingHandlingCapabilityText(
                                                                option as PackagingHandlingCapability,
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <Controller
                                        control={control}
                                        name={'trip'}
                                        rules={commonRules}
                                        render={({field, fieldState}) => (
                                            <TextField
                                                {...field}
                                                label={getLangString('TRIP')}
                                                type={'number'}
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                sx={{...inputStyle, mr: 1}}
                                                inputProps={{min: 0}}
                                            />
                                        )}
                                    />
                                </Box>
                            </>
                        )}
                        <Controller
                            name={'comment'}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label={getLangString('COMMENT')}
                                    rows={3}
                                    fullWidth={true}
                                    multiline={true}
                                    sx={inputStyle}
                                />
                            )}
                        />
                    </Box>
                    <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={props.handleClose} variant={'outlined'} sx={{mr: 1}}>
                            {getLangString('CANCEL')}
                        </Button>
                        <Button type={'submit'} variant={'contained'}>
                            {getLangString('SAVE')}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            {props.inProgress && <CircularProgressOverlay />}
        </Dialog>
    );
};
