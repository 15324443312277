import {ReportsData, ValueChainEmissionsResp, WeightedCalculationGroup} from '../../api/dtos/reportsDto';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {ChartData} from '../StackedBarChart';

export const valueChainEmissionText: Record<keyof ValueChainEmissionsResp, string> = {
    gwpPackaging: 'GWP Emballasje',
    gwpProduct: 'GWP Produkt',
    gwpTransportation: 'GWP Transport',
    gwpWaste: 'GWP Svinn',
};
export const transformToChartData = (
    data: ReportsData | undefined,
    calcGroup: keyof WeightedCalculationGroup,
    dataType: ReportDataSet,
): ChartData[] => {
    if (data) {
        const getMaxValue = (): number => {
            let maxValue;
            if (dataType === 'packagingResults') {
                maxValue = data.maxPackagingValues[calcGroup];
            } else if (dataType === 'emissionResults') {
                maxValue = data.maxEmissionValues[calcGroup];
            } else {
                maxValue = data.maxValueChainValue;
            }
            return maxValue > 50 ? Math.ceil(maxValue / 100) * 100 : Math.ceil(maxValue / 10) * 10;
        };

        return data.reportData.map((groupedKF) => {
            return {
                id: groupedKF.groupingId,
                chartName: groupedKF.groupingName,
                data: groupedKF.calculations.map((calc) => {
                    if (dataType === 'valueChainEmissions') {
                        return {
                            label: calc.year,
                            values: Object.keys(calc.valueChainEmissions).map((value) => ({
                                name: valueChainEmissionText[value as keyof ValueChainEmissionsResp],
                                value: calc.valueChainEmissions[value as keyof ValueChainEmissionsResp].toFixed(2),
                            })),
                        };
                    } else {
                        return {
                            label: calc.year,
                            values: calc.packagings.map((value) => {
                                return {
                                    name: value.packagingGroupName,
                                    value: value[dataType][calcGroup].toFixed(2),
                                };
                            }),
                        };
                    }
                }),
                maxValue: getMaxValue(),
            };
        });
    }
    return [];
};
