import {ArrowBack} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {SubNavBar} from '../SubNavBar';

export const RegistrySettingsPage: React.FC = () => {
    const {getLangString} = useLanguage();
    const navigate = useNavigate();
    const goToProductList = () => {
        navigate('/registry');
    };

    const tabs = [
        {value: '', label: getLangString('PRODUCT_GROUPS')},
        {value: 'packaging', label: getLangString('PACKAGING_TYPES')},
        {value: 'transport', label: getLangString('TRANSPORT_TYPES')},
    ];
    const [currentTab, setCurrentTab] = React.useState<string>('');
    const handleChange = (e: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    return (
        <Box mt={2} mb={12}>
            <Button onClick={goToProductList} startIcon={<ArrowBack />}>
                {getLangString('BACK')}
            </Button>
            <Box mt={3}>
                <SubNavBar onChange={handleChange} activeTab={currentTab} tabs={tabs} />
            </Box>
            <Outlet />
        </Box>
    );
};
