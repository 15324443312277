import {axiosInstance} from '../axiosInstance';
import {
    CreateDeclarationRequest,
    DeclarationQuestion,
    DeclarationResponse,
    UpdateDeclarationRequest,
} from '../dtos/declarationDto';
import {declarationPath} from '../paths';

export const getDeclarationQuestions = async () =>
    await axiosInstance.get<DeclarationQuestion[]>(declarationPath.questions()).then((res) => res.data);

export const removeDeclarationAttachment = async (declarationAttachmentId: number) =>
    await axiosInstance.delete(declarationPath.removeAttachment(declarationAttachmentId)).then((res) => res.data);

export const addDeclarationAttachment = async (declarationId: number, data: FormData) =>
    await axiosInstance.post(declarationPath.addAttachment(declarationId), data).then((res) => res.data);

export const getDeclarationById = async (declarationId: number) => {
    return await axiosInstance.get<DeclarationResponse>(declarationPath.byId(declarationId)).then((res) => res.data);
};
export const createDeclarationRequest = async (data: CreateDeclarationRequest) => {
    return await axiosInstance
        .post<{declarationId: number}>(declarationPath.create(), data, {
            headers: {'Content-Type': 'application/json'},
        })
        .then((res) => res.data);
};
export const updateDeclarationRequest = async (requestData: {
    declarationId: number;
    data: UpdateDeclarationRequest;
}) => {
    return await axiosInstance
        .patch(declarationPath.update(requestData.declarationId), requestData.data, {
            headers: {'Content-Type': 'application/json'},
        })
        .then((res) => res.data);
};
