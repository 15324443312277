import {Box, CircularProgress} from '@mui/material';
import {CircularProgressPropsColorOverrides} from '@mui/material/CircularProgress/CircularProgress';
import {FC} from 'react';

export const CircularProgressOverlay: FC<{
    iconSize?: number;
    bgColor?: string;
    iconColor?: CircularProgressPropsColorOverrides;
}> = ({iconSize, iconColor, bgColor}) => {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                background: bgColor || 'rgba(255,255,255,0.5)',
                zIndex: 1000,
            }}
        >
            <CircularProgress size={iconSize} sx={{color: iconColor}} />
        </Box>
    );
};

export const CircularProgressCentered: FC<{iconSize?: number}> = ({iconSize}) => (
    <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
    >
        <CircularProgress size={iconSize} />
    </Box>
);
