import {Add} from '@mui/icons-material';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ProductConfigResponse} from '../../api/dtos/productConfigDto';
import {useGetTransport} from '../../api/hooks/transports';
import {useLanguage} from '../../language/LanguageProvider';
import {UpdateProductFormFields} from '../views/ProductPage';

export const EditProductForm: React.FC<{
    data: ProductConfigResponse;
    handleSave: (form: UpdateProductFormFields) => void;
    handleCancel: () => void;
}> = ({data, handleCancel, handleSave}) => {
    const {getLangString} = useLanguage();

    const currentCompanyId = useCurrentCompanyId();

    const transports = useGetTransport(currentCompanyId);

    const defaultValues: UpdateProductFormFields = {
        productId: data.product.productId,
        transportId: data.transport.transportId,
        netWeightGrams: data.netWeightGrams,
        fpakDpak: data.fpakDpak,
        dpakPallet: data.dpakPallet,
        validFrom: new Date(data.validFrom),
        validTo: data.validTo ? new Date(data.validTo) : undefined,
        comment: data.comment,
    };

    const {handleSubmit, control, getValues, trigger, formState} = useForm<UpdateProductFormFields>({
        defaultValues,
    });
    const commonRules = {rules: {required: getLangString('FIELD_IS_REQUIRED')}};

    const [isAddingEndDate, setIsAddingEndDate] = useState(false);

    const isValidToDate = () =>
        getValues('validFrom').getFullYear() <= (getValues('validTo') || new Date(Date.now())).getFullYear();

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Box my={1} sx={{'& > div': {mt: 1.5}}}>
                <Controller
                    name={'netWeightGrams'}
                    control={control}
                    {...commonRules}
                    render={({field, fieldState}) => (
                        <TextField
                            {...field}
                            label={getLangString('NET_WEIGHT')}
                            type={'number'}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputProps={{endAdornment: <InputAdornment position='end'>gram</InputAdornment>}}
                        />
                    )}
                />
                <Box>
                    <Controller
                        name={'fpakDpak'}
                        control={control}
                        {...commonRules}
                        render={({field, fieldState}) => (
                            <TextField
                                {...field}
                                label={getLangString('FPAK_DPAK_AMOUNT')}
                                type={'number'}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                sx={{mr: 1}}
                            />
                        )}
                    />
                    <Controller
                        name={'dpakPallet'}
                        control={control}
                        {...commonRules}
                        render={({field, fieldState}) => (
                            <TextField
                                {...field}
                                label={getLangString('DPAK_PALLET_AMOUNT')}
                                type={'number'}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Box>

                <Controller
                    name={'transportId'}
                    control={control}
                    {...commonRules}
                    render={({field, fieldState}) => (
                        <FormControl fullWidth={true} error={!!fieldState.error}>
                            <InputLabel>{getLangString('TRANSPORT')}</InputLabel>
                            <Select {...field}>
                                {transports.data?.map((t) => (
                                    <MenuItem key={t.transportId} value={t.transportId}>
                                        {t.transportName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Box display={'flex'} alignItems={'center'}>
                    <Controller
                        name={'validFrom'}
                        control={control}
                        {...commonRules}
                        render={({field, fieldState}) => (
                            <DatePicker
                                {...field}
                                views={['year']}
                                label={'I produksjon fra år'}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{mr: 1}}
                                    />
                                )}
                            />
                        )}
                    />
                    {data.validTo || isAddingEndDate ? (
                        <Controller
                            name={'validTo'}
                            control={control}
                            render={({field, fieldState}) => (
                                <DatePicker
                                    {...field}
                                    views={['year']}
                                    label={'I produksjon til år'}
                                    minDate={getValues('validFrom')}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                error={!isValidToDate()}
                                                helperText={!isValidToDate() && 'Tildato må være etter startdato'}
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                    ) : (
                        <Button startIcon={<Add />} onClick={() => setIsAddingEndDate(true)}>
                            Legg til avslutningsdato
                        </Button>
                    )}
                </Box>
            </Box>
            <Box my={2}>
                <Controller
                    name={'comment'}
                    control={control}
                    render={({field, fieldState}) => (
                        <TextField
                            {...field}
                            label={getLangString('COMMENT')}
                            rows={3}
                            fullWidth={true}
                            multiline={true}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Box>
            <Box mt={2}>
                <Button variant={'outlined'} onClick={handleCancel} sx={{mr: 1}}>
                    {getLangString('CANCEL')}
                </Button>
                <Button variant={'contained'} type={'submit'} disabled={!formState.isValid}>
                    {getLangString('SAVE')}
                </Button>
            </Box>
        </form>
    );
};
