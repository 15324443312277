import {axiosInstance} from '../axiosInstance';
import {
    CreateProductGroupDto,
    CreateProductGroupTypeDto,
    ProductGroupDto,
    ProductGroupTypeDto,
    UpdateProductGroupDto,
} from '../dtos/productGroupDto';
import {productGroupPath, productGroupTypePath} from '../paths';

export const getProductGroups = async (companyId: number) =>
    await axiosInstance
        .get<ProductGroupDto[]>(`${productGroupPath.base()}?companyId=${companyId}`)
        .then((res) => res.data);

export const getProductGroupByID = async (productGroupId: number) =>
    await axiosInstance.get<ProductGroupDto>(`${productGroupPath.byId(productGroupId)}`).then((res) => res.data);

export const updateProductGroup = async (reqData: {productGroupId: number; data: UpdateProductGroupDto}) =>
    await axiosInstance.patch(`${productGroupPath.byId(reqData.productGroupId)}`, reqData.data);

export const createProductGroup = async (data: CreateProductGroupDto) =>
    await axiosInstance.post(`${productGroupPath.base()}`, data);
export const deleteProductGroup = async (productGroupId: number) =>
    await axiosInstance.delete(`${productGroupPath.byId(productGroupId)}`);

export const getProductGroupTypes = async (companyId: number) =>
    await axiosInstance
        .get<ProductGroupTypeDto[]>(`${productGroupTypePath.base()}?companyId=${companyId}`)
        .then((res) => res.data);

export const getProductGroupTypeByID = async (productGroupTypeId: number) =>
    await axiosInstance
        .get<ProductGroupTypeDto>(`${productGroupTypePath.byId(productGroupTypeId)}`)
        .then((res) => res.data);

export const createProductGroupType = async (data: CreateProductGroupTypeDto) =>
    await axiosInstance.post<{productGroupTypeId: number}>(productGroupTypePath.base(), data);

export const updateProductGroupType = async (reqData: {productTypeId: number; data: CreateProductGroupTypeDto}) =>
    await axiosInstance.patch(`${productGroupTypePath.base()}/${reqData.productTypeId}`, reqData.data);
export const deleteProductGroupType = async (productGroupTypeId: number) =>
    await axiosInstance.delete(`${productGroupTypePath.byId(productGroupTypeId)}`);
