import {Box} from '@mui/material';
import {GridRowModel} from '@mui/x-data-grid';
import {AxiosPromise} from 'axios';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {
    useCreateProductGroup,
    useCreateProductGroupType,
    useDeleteProductGroup,
    useDeleteProductGroupType,
    useGetProductGroups,
    useGetProductGroupTypes,
    useUpdateProductGroup,
    useUpdateProductGroupType,
} from '../../api/hooks/product';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressCentered} from '../ProgressComponents';
import {AddGWPValuesFormFields} from './DialogAddGWPItem';
import {GWPTable, GWPTableRowProps} from './GWPTable';
import {PrimaryGroupItems} from './PrimaryGroupItems';

export const ProductGroupList: React.FC = () => {
    const {getLangString} = useLanguage();

    const createProductType = useCreateProductGroupType();
    const updateProductType = useUpdateProductGroupType();
    const deleteProductType = useDeleteProductGroupType();

    const createProductGroupType = (name: string): AxiosPromise => {
        return createProductType.mutateAsync({productGroupTypeName: name, companyId: currentCompanyId});
    };
    const updateProductGroupType = (id: number, name: string): AxiosPromise => {
        return updateProductType.mutateAsync({
            productTypeId: id,
            data: {productGroupTypeName: name, companyId: currentCompanyId},
        });
    };
    const deleteProductGroupType = (productGroupTypeId: number) => {
        return deleteProductType.mutateAsync(productGroupTypeId);
    };

    const currentCompanyId = useCurrentCompanyId();

    const productGroupsTypes = useGetProductGroupTypes(currentCompanyId);
    const productGroups = useGetProductGroups(currentCompanyId);

    const updateExistingProductGroup = useUpdateProductGroup();
    const createNewProductGroup = useCreateProductGroup();
    const deleteProductGroup = useDeleteProductGroup();

    if (productGroupsTypes.isLoading || productGroups.isLoading) {
        return <CircularProgressCentered />;
    }
    if (productGroupsTypes.isError || productGroups.isError) {
        //TODO: add better error message
        return <Box>Noe feilet</Box>;
    }
    const getProductGroupsData = (): GWPTableRowProps[] => {
        return productGroups.data.map((p) => {
            const currentGroup = productGroupsTypes.data.find((g) => {
                if (g.productGroupTypeId === p.productGroupType.productGroupTypeId) {
                    return g.productGroupTypeId;
                }
            });
            return {
                id: p.productGroupId,
                name: p.productGroupName,
                primaryGroup: String(currentGroup?.productGroupTypeId),
                GWP: String(p.gwp),
                rGWP: String(p.rGwp),
                comment: p.comment,
                isNew: false,
            };
        });
    };

    const handleRowUpdate = (newRow: GridRowModel<GWPTableRowProps>) => {
        updateExistingProductGroup.mutate({
            productGroupId: newRow.id,
            data: {
                productGroupName: newRow.name,
                productGroupTypeId: Number(newRow.primaryGroup),
                gwp: parseFloat(newRow.GWP),
                rGwp: parseFloat(newRow.rGWP!),
                unit: '',
                comment: newRow.comment,
            },
        });
    };
    const handleCreate = (newRow: AddGWPValuesFormFields) => {
        createNewProductGroup.mutate({
            productGroupName: newRow.name,
            productGroupTypeId: Number(newRow.primaryGroup),
            companyId: currentCompanyId,
            gwp: parseFloat(newRow.GWP),
            rGwp: parseFloat(newRow.rGWP!),
            unit: '',
            comment: newRow.comment,
        });
    };
    const handleRowDelete = (productGroupId: number) => {
        return deleteProductGroup.mutateAsync(productGroupId);
    };
    const getProductGroupTypeOptions = (): Record<number, string> =>
        productGroupsTypes.data.reduce(
            (acc, currGroup) => ({
                ...acc,
                [currGroup.productGroupTypeId]: currGroup.productGroupTypeName,
            }),
            {},
        );
    const groups = productGroupsTypes.data.map((g) => ({id: g.productGroupTypeId, name: g.productGroupTypeName}));

    return (
        <>
            <GWPTable
                tableData={getProductGroupsData()}
                onCreate={handleCreate}
                onRowUpdate={handleRowUpdate}
                onRowDelete={handleRowDelete}
                rowCreateButtonText={getLangString('ADD_PRODUCT_GROUP')}
                primaryGroupOptions={getProductGroupTypeOptions()}
            />
            <PrimaryGroupItems
                headerText={'Hovedgrupper produkt'}
                groups={groups}
                onUpdateItem={updateProductGroupType}
                onCreateItem={createProductGroupType}
                onDeleteItem={deleteProductGroupType}
            />
        </>
    );
};
