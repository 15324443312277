import {Add} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCreateAnalysis, useGetAnalyses} from '../../api/hooks/analysis';
import {useLanguage} from '../../language/LanguageProvider';
import {AnalysesList} from '../analysis/AnalysesList';
import {CreateAnalysisFormFields, DialogCreateAnalysis} from '../analysis/DialogCreateAnalysis';

export const AnalysesListPage: React.FC = () => {
    const navigate = useNavigate();
    const {getLangString} = useLanguage();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

    const currentCompanyId = useCurrentCompanyId();

    const createAnalysis = useCreateAnalysis(currentCompanyId);

    const {data, isLoading, isError} = useGetAnalyses(currentCompanyId);

    if (isError) {
        return <Box>Noe feilet</Box>; //TODO: update error message
    }

    const onCreateClick = (form: CreateAnalysisFormFields) => {
        createAnalysis
            .mutateAsync({analysisName: form.analysisName, description: form.description, products: []}) //TODO: provide description, date, user, etc
            .then(({analysisId}) => navigate(`/analysis/${analysisId}`));
    };

    return (
        <Box my={3}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Typography variant={'h3'}>{getLangString('ANALYSES')}</Typography>
                    {/*<CheckBox />*/
                    /*TODO: Add filter for showing only 'mine'*/}
                </Box>
                <Button variant={'text'} size={'large'} startIcon={<Add />} onClick={() => setIsCreateDialogOpen(true)}>
                    {getLangString('CREATE_ANALYSIS')}
                </Button>
            </Box>
            <Box height={window.innerHeight - 200}>
                <AnalysesList analyses={data} isLoading={isLoading} />
            </Box>
            <DialogCreateAnalysis
                isOpen={isCreateDialogOpen}
                handleClose={() => setIsCreateDialogOpen(false)}
                handleCreate={onCreateClick}
                isInProgress={createAnalysis.isLoading}
            />
        </Box>
    );
};
