import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {AppBar, Box, MenuItem, Select, SelectChangeEvent, Tab, Tabs, Toolbar, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useApplicationState} from 'lib/context/applicationState';
import {useCurrentCompany, useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {Link, matchRoutes, NavLink, RouteMatch, useLocation} from 'react-router-dom';
import logo from '../assets/PackMan-logoHorizontal.svg';
import {useLanguage} from '../language/LanguageProvider';
import {AccountMenu} from './AccountMenu';
import {LoginButton} from './LoginButton';

const tabStyle = {
    fontSize: '1rem',
    fontFamily: 'Inter-SemiBold',
};
const routes = [
    {path: '/registry/*'},
    {path: 'reports/*'},
    {path: 'analysis/*'},
    {path: 'declaration/*'},
    {path: 'salesVolume/*'},
];

const manualText = {
    fontSize: '1rem',
    fontFamily: 'Inter-SemiBold',
    textDecoration: 'none',
    color: '#424242',
};

const manualDiv = {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: '16px',
    lineHeight: 0,
};

const useCurrentPath = (): RouteMatch | undefined => {
    const location = useLocation();
    const current = matchRoutes(routes, location);
    return current ? current[0] : undefined;
};
export const NavBar: React.FC = () => {
    const current = useCurrentPath()?.pathnameBase;
    const {getLangString} = useLanguage();
    const {state} = useApplicationState();
    const {setCurrentCompany} = useCurrentCompany();

    const {currentCompany} = useCurrentCompany();
    const currentCompanyId = useCurrentCompanyId();
    const userCompanies = state.currentUser?.companies;
    const companyCount = userCompanies != undefined ? userCompanies.length : 0;

    const queryClient = useQueryClient();
    const handleOnChange = (event: SelectChangeEvent<string>) => {
        const company = state.currentUser?.companies.filter((sc) => sc.companyId == parseInt(event.target.value));

        if (company != undefined) {
            setCurrentCompany(company[0]);
            queryClient.clear();
        }
    };

    return (
        <AppBar position={'sticky'} color={'transparent'} elevation={4}>
            <Toolbar sx={{backgroundColor: '#fff'}}>
                <Box sx={{display: 'flex', alignItems: 'center', mr: 5}}>
                    <img src={logo} width={130} alt={'logo'} />
                </Box>

                {state.authenticated && (
                    <Tabs value={current} textColor='primary' indicatorColor='primary' aria-label='primary navigation'>
                        <Tab
                            value='/registry'
                            label={getLangString('REGISTRY')}
                            component={NavLink}
                            to={'/registry'}
                            sx={tabStyle}
                        />
                        <Tab
                            value='/salesVolume'
                            label={getLangString('SALES_AND_WASTE')}
                            component={NavLink}
                            to={'/salesVolume'}
                            sx={tabStyle}
                        />
                        <Tab
                            value='/declaration'
                            label={getLangString('DECLARATION')}
                            component={NavLink}
                            to={'/declaration'}
                            sx={tabStyle}
                        />
                        <Tab
                            value='/analysis'
                            label={getLangString('ANALYSIS')}
                            component={NavLink}
                            to={'/analysis'}
                            sx={tabStyle}
                        />
                        <Tab
                            value='/reports'
                            label={getLangString('REPORTS')}
                            component={NavLink}
                            to={'/reports/keyFigures'}
                            sx={tabStyle}
                        />
                    </Tabs>
                )}
                <div style={manualDiv}>
                    <Link style={manualText} to="/Brukerveiledning PackMan Ultimate.pdf" target="_blank" download>{getLangString('MANUAL')}</Link>
                </div>

                <Box marginLeft={'auto'} display={'flex'} alignItems={'center'}>
                    <UnauthenticatedTemplate>
                        <LoginButton />
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        {companyCount > 1 ? (
                            <Select
                                value={currentCompanyId.toString()}
                                onChange={handleOnChange}
                                sx={{
                                    '& .MuiOutlinedInput-input': {py: 1.5},
                                    '& .MuiOutlinedInput-notchedOutline': {border: 0},
                                }}
                            >
                                {state.currentUser?.companies.map((company) => (
                                    <MenuItem key={company.companyId} value={company.companyId}>
                                        {company.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Box>
                                <Typography variant={'body1'} mr={1}>
                                    {currentCompany?.name}
                                </Typography>
                            </Box>
                        )}
                        <AccountMenu currentUser={state.currentUser} />
                    </AuthenticatedTemplate>
                </Box>
            </Toolbar>
        </AppBar>
    );
};
