import {Edit, InputRounded, OutputRounded} from '@mui/icons-material';
import {Box, Button, MenuItem, Select, SelectChangeEvent, Theme, Typography} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {UpdateVolumeDto, VolumeUnit} from '../../api/dtos/productVolumeDto';
import {getValidProductConfigYears} from '../../api/handlers/productConfig';
import {
    createSalesVolume,
    exportVolumeData,
    getProductVolumeByYear,
    importVolumeData,
    updateSalesVolume,
} from '../../api/handlers/volumes';
import {useLanguage} from '../../language/LanguageProvider';
import {createDateWithoutTimezone} from '../../utils/createDateWithoutTimezone';
import {handleFileDownload} from '../../utils/handleFileDownload';
import {CustomTableTextField} from '../CustomTableTextField';
import {FileUploader} from '../FileUploader';
import {CircularProgressCentered} from '../ProgressComponents';
import {useSnackBar} from '../SnackBarProvider';

const getEditableCellContent = (value: string) => (
    <Typography
        width={'80%'}
        display={'flex'}
        justifyContent={'flex-end'}
        sx={{
            '& .editable-indicator': {
                visibility: 'hidden',
            },
            cursor: 'text',
        }}
    >
        {value}
        <Edit
            color={'inherit'}
            fontSize={'small'}
            className={'editable-indicator'}
            sx={(theme: Theme) => ({ml: 1.5, color: theme.palette.grey['500']})}
        />
    </Typography>
);

type SalesVolumeRowType = {
    id: number;
    productConfigId: number;
    name: string;
    prodGroup: string;
    volumeId: number | undefined;
    salesVolume: number | undefined;
    productWaste: number | undefined;
    startDate: number;
    endDate: number | undefined;
};
export const SalesVolumePage: React.FC = () => {
    const {getLangString} = useLanguage();
    const {addSnackBar} = useSnackBar();

    const handleYearChange = (event: SelectChangeEvent) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const currentCompanyId = useCurrentCompanyId();

    const years = useQuery(['productYears'], () => getValidProductConfigYears(currentCompanyId), {
        enabled: currentCompanyId > 0,
    });
    const [selectedYear, setSelectedYear] = useState<number>(years.data ? years.data[0] : new Date().getFullYear());
    const {data, isLoading, isError} = useQuery(
        ['productVolumes', selectedYear],
        () => getProductVolumeByYear(selectedYear, currentCompanyId),
        {enabled: currentCompanyId > 0},
    );

    const createVolume = useMutation(createSalesVolume, {
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const updateVolume = useMutation(updateSalesVolume, {
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const toggleRowEdit = (id: number | string) => {
        setRowModesModel((oldModel) => {
            const ids = Object.keys(oldModel);
            const model: GridRowModel = ids.reduce((newModel: GridRowModel, id) => {
                return {...newModel, [id]: {mode: GridRowModes.View}};
            }, {});
            return {
                ...model,
                [id]: {mode: GridRowModes.Edit, fieldToFocus: 'salesVolume'},
            };
        });
    };
    const handleRowClick = (rowParams: GridRowParams) => {
        toggleRowEdit(rowParams.id);
    };
    const leaveEditMode = (id: number | string) => {
        setRowModesModel(() => ({
            [id]: {mode: GridRowModes.View},
        }));
    };
    const processRowUpdate = React.useCallback(
        (newRow: GridRowModel, oldRow: GridRowModel) => {
            const volumeData: UpdateVolumeDto = {
                productConfigId: newRow.productConfigId,
                salesVolume: parseInt(newRow.salesVolume) || 0,
                wasteVolume: parseInt(newRow.productWaste) || 0,
                unit: VolumeUnit['Quantity'],
                fromPeriod: createDateWithoutTimezone(selectedYear, 1, 1),
                toPeriod: createDateWithoutTimezone(selectedYear, 12, 31),
            };
            // updating existing volume
            if (oldRow.volumeId) {
                updateVolume.mutate({
                    volumeId: oldRow.volumeId,
                    volume: volumeData,
                });
            }
            // adding new volume
            else {
                createVolume.mutate(volumeData);
            }
            return newRow;
        },
        [selectedYear],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        addSnackBar({children: error.message, severity: 'error'}); //TODO: update error message
    }, []);
    const getRows = (): GridRowsProp<SalesVolumeRowType> => {
        if (data) {
            return data.map((p) => ({
                id: p.productId,
                productConfigId: p.productConfig.productConfigId,
                name: p.productName,
                prodGroup: p.productGroupName,
                volumeId: p.productConfig.volume ? p.productConfig.volume.volumeId : undefined,
                salesVolume: p.productConfig.volume ? p.productConfig.volume.salesVolume : undefined,
                productWaste: p.productConfig.volume ? p.productConfig.volume.wasteVolume : undefined,
                startDate: new Date(p.productConfig.validFrom).getFullYear(),
                endDate: p.productConfig.validTo ? new Date(p.productConfig.validTo).getFullYear() : undefined,
            }));
        }
        return [];
    };
    const findNextRow = (currentId: string | number) => {
        if (data) {
            const index = data.findIndex((d) => d.productId === currentId);
            const nextID = data[index + 1].productId;
            toggleRowEdit(nextID);
        }
    };
    const [rows, setRows] = useState<GridRowsProp<SalesVolumeRowType>>([]);
    useEffect(() => {
        const newRows = getRows();
        setRows(newRows);
    }, [data]);
    const queryClient = useQueryClient();
    const doImportSalesVolume = useMutation(importVolumeData, {
        onSuccess: () => {
            queryClient.invalidateQueries(['productVolumes', selectedYear]);
        },
    });
    const exportVolume = useQuery(
        ['exportVolumes'],
        () => exportVolumeData({year: selectedYear, companyId: currentCompanyId}),
        {enabled: false},
    );
    const handleImport = (e: ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => formData.append('file', file));
        }
        doImportSalesVolume.mutate({year: selectedYear, data: formData});
    };
    const handleExport = () => {
        exportVolume.refetch().then((resp) => {
            handleFileDownload(resp.data, 'SalgOgSvinn_' + selectedYear + '.xlsx');
        });
    };
    if (years.isLoading) {
        return <CircularProgressCentered />;
    }
    if (isError || years.isError) {
        return <Box>Produkt ikke funnet</Box>; //TODO: add better error message
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: getLangString('PRODUCT_NAME'),
            flex: 1,
        },
        {field: 'prodGroup', headerName: getLangString('PRODUCT_GROUP'), flex: 1},
        {
            field: 'salesVolume',
            headerName: getLangString('SALES_VOLUME'),
            align: 'right',
            editable: true,
            type: 'number',
            minWidth: 200,
            renderHeader: () => (
                <Typography
                    variant={'subtitle1'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    sx={{
                        pr: 5,
                        fontSize: '0.875rem',
                    }}
                >
                    {getLangString('SALES_VOLUME')}
                </Typography>
            ),
            renderCell: (params) => getEditableCellContent(params.value),
            renderEditCell: ({id, field, value}) => {
                return (
                    <CustomTableTextField
                        key={field}
                        type={'number'}
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        value={value}
                        cellId={id}
                        field={field}
                        autoFocus={true}
                        triggerSave={leaveEditMode}
                    />
                );
            },
        },
        {
            field: 'productWaste',
            headerName: getLangString('PRODUCT_WASTE'),
            align: 'right',
            editable: true,
            type: 'number',
            minWidth: 200,
            renderHeader: () => (
                <Typography
                    variant={'subtitle1'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    sx={{
                        pr: 5,
                        fontSize: '0.875rem',
                    }}
                >
                    {getLangString('PRODUCT_WASTE')}
                </Typography>
            ),
            renderCell: (params) => getEditableCellContent(params.value),
            renderEditCell: ({id, field, value}) => {
                return (
                    <CustomTableTextField
                        key={field}
                        type={'number'}
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        value={value}
                        cellId={id}
                        field={field}
                        triggerSave={leaveEditMode}
                        tabToNextRow={findNextRow}
                    />
                );
            },
        },
    ];

    return (
        <Box my={2}>
            <Select value={selectedYear.toString()} onChange={handleYearChange} sx={{mb: 0.5}}>
                {years.data?.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
            <Box height={window.innerHeight - 200}>
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
                            outline: 0,
                        },
                        '& .MuiDataGrid-row:hover': {
                            '.editable-indicator': {
                                visibility: 'visible',
                            },
                        },
                    }}
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    editMode={'row'}
                    onRowClick={handleRowClick}
                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    experimentalFeatures={{newEditingApi: true}}
                    components={{
                        Toolbar: () => (
                            <GridToolbarContainer sx={{'& > button': {mr: 1.5}}}>
                                <GridToolbarFilterButton />
                                <FileUploader
                                    handleFileChange={handleImport}
                                    allowMultiple={true}
                                    acceptedFileTypes={'.xls, .xlsx'}
                                    buttonText={getLangString('IMPORT')}
                                    buttonStartIcon={<InputRounded />}
                                />
                                <Button onClick={handleExport} startIcon={<OutputRounded />} size={'small'}>
                                    {getLangString('EXPORT')}
                                </Button>
                            </GridToolbarContainer>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};
