import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {FC} from 'react';
import {useLanguage} from '../language/LanguageProvider';

type DialogProps = {
    isOpen: boolean;
    headerText: string;
    bodyText: string;
    onCancel: () => void;
    onDelete: () => void;
};
export const DialogDelete: FC<DialogProps> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <Dialog open={props.isOpen}>
            <DialogTitle variant={'h4'}>{props.headerText}</DialogTitle>
            <DialogContent>
                <Typography>{props.bodyText}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>{getLangString('CANCEL')}</Button>
                <Button color={'error'} onClick={props.onDelete}>
                    {getLangString('DELETE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
