import {Box, SelectChangeEvent} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {CreateDeclarationRequest} from 'api/dtos/declarationDto';
import {addDeclarationAttachment, createDeclarationRequest, getDeclarationQuestions} from 'api/handlers/declaration';
import {useSnackBar} from 'components/SnackBarProvider';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DeclarationContent, DeclarationQuestionWithAnswer} from '../declaration/DeclarationContent';
import {CircularProgressCentered} from '../ProgressComponents';

export const CreateProductDeclarationPage: React.FC = () => {
    const {productId} = useParams();
    const currentCompanyId = useCurrentCompanyId();
    const navigate = useNavigate();
    const [files, setFiles] = useState(new Array<File>());
    const [wasteRegulations, setWasteRegulations] = useState<DeclarationQuestionWithAnswer[]>([]);
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();

    const createDeclaration = useMutation(createDeclarationRequest, {
        onSuccess: () => {
            queryClient.invalidateQueries(['wasteRegulationQuestions']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });

    const {data, isLoading, isError} = useQuery(['wasteRegulationQuestions'], getDeclarationQuestions);
    useEffect(() => {
        if (data) {
            const questions: DeclarationQuestionWithAnswer[] = data.map((w) => ({
                ...w,
                answer: '',
            }));
            setWasteRegulations(questions);
        }
    }, [data]);

    if (isLoading) return <CircularProgressCentered />;
    if (isError)
        return (
            <Box display={'flex'} justifyContent={'center'}>
                Deklarasjon ikke funnet
            </Box>
        );

    // ----------------------------------------------------------------------------------
    const navigateBack = () => {
        navigate('/declaration');
    };

    // ----------------------------------------------------------------------------------
    const addFiles = (event: ChangeEvent<HTMLInputElement>) => {
        Array.from(event.target.files!).forEach((file) => {
            if (!files.some((attachment) => attachment == file)) setFiles([...files, file]);
        });
    };
    const removeFile = (file: File) => {
        setFiles(files.filter((f: File) => f.name != file.name));
    };

    // ----------------------------------------------------------------------------------
    const updateWasteRegulation = (event: SelectChangeEvent, id: number) => {
        const newWasteRegulationAnswers = [...wasteRegulations];
        const index = newWasteRegulationAnswers.findIndex(
            (wasteRegulation) => wasteRegulation.wasteRegulationQuestionId == id,
        );
        if (index !== -1) {
            newWasteRegulationAnswers[index].answer = event.target.value;
        }

        setWasteRegulations(newWasteRegulationAnswers);
    };

    // ----------------------------------------------------------------------------------
    const onSubmit = async () => {
        const createDeclarationRequest: CreateDeclarationRequest = {
            productId: parseInt(productId!),
            companyId: currentCompanyId,
            wasteRegulationAnswers: getAnsweredQuestions(wasteRegulations),
        };

        // xxx
        if (createDeclarationRequest.wasteRegulationAnswers.length === 0) {
            addSnackBar({children: 'Spørsmål må være besvart', severity: 'error'});
        } else {
            const result = await createDeclaration.mutateAsync(createDeclarationRequest);
            if (files.length != 0) await addDeclarationAttachment(result.declarationId, getAttachments(files));
            navigateBack();
        }
    };
    const getAttachments = (files: Array<File>) => {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('file', file, file.name);
        });
        return formData;
    };
    const getAnsweredQuestions = (wasteRegulationAnswers: DeclarationQuestionWithAnswer[]) => {
        return wasteRegulationAnswers
            .filter((a) => !!a.answer)
            .map((da) => ({
                wasteRegulationQuestionId: da.wasteRegulationQuestionId,
                answer: da.answer,
            }));
    };

    const isDeclarationValid = (): boolean => {
        return getAnsweredQuestions(wasteRegulations).length === wasteRegulations.length;
    };
    return (
        <DeclarationContent
            productId={parseInt(productId!)}
            declarationQuestionsWithAnswers={wasteRegulations}
            updateWasteRegulation={updateWasteRegulation}
            localFiles={files}
            addLocalFiles={addFiles}
            removeLocalFile={removeFile}
            onSave={onSubmit}
            isFormValid={isDeclarationValid()}
        />
    );
};
