import {Store} from './Store';

/**
 * A wrapper around LocalStorage for convenience when storing objects, and needs
 * an interface for key type.
 */
export class LocalStore<T extends string, K> implements Store<T, K> {
    set(key: T, value: K): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    get(key: T): K | undefined {
        const item = localStorage.getItem(key);
        if (item) {
            return JSON.parse(item) as K;
        }
        return;
    }
    remove(key: T): void {
        localStorage.removeItem(key);
    }
    contains(key: T): boolean {
        return localStorage.getItem(key) ? true : false;
    }

    count(): number {
        return localStorage.length;
    }
}
