import {Box} from '@mui/material';
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import React from 'react';
import {ValueChainEmissionsResp} from '../../api/dtos/reportsDto';
import {useLanguage} from '../../language/LanguageProvider';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {valueChainEmissionText} from '../reports/transformToChartData';

export type AnalysisTableRowData = {
    id: string;
    packagingGroupName: string;
    dataPoints: {
        name: string;
        value: number;
    }[];
};

export const ResultsTable: React.FC<{
    data: AnalysisTableRowData[];
    groupHeaders: string[];
    dataType: ReportDataSet;
}> = ({data, groupHeaders, dataType}) => {
    const {getLangString} = useLanguage();
    const getColumnHeaders = (): GridColumns => {
        return groupHeaders.map((name) => ({
            field: name,
            headerName: name,
            align: 'right',
            type: 'number',
            minWidth: 120,
        }));
    };
    const getDataPoints = (d: AnalysisTableRowData) => {
        return d.dataPoints.reduce((acc, curr) => ({...acc, [curr.name]: curr.value}), {});
    };
    const getRows = () => {
        return data ? data.map((d) => ({id: d.id, packaging: d.packagingGroupName, ...getDataPoints(d)})) : [];
    };
    const columns: GridColumns = [
        {
            field: 'packaging',
            headerName: dataType === 'valueChainEmissions' ? getLangString('NAME') : getLangString('PACKAGING'),
            flex: 1,
            valueFormatter: (params) =>
                dataType === 'valueChainEmissions'
                    ? valueChainEmissionText[params.value as keyof ValueChainEmissionsResp]
                    : params.value,
        },
        ...getColumnHeaders(),
    ];

    return (
        <Box>
            <DataGrid columns={columns} rows={getRows()} autoHeight={true} />
        </Box>
    );
};
