import {AuthError, BrowserAuthError, InteractionRequiredAuthError} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import { ConnectingAirportsOutlined, Login } from '@mui/icons-material';
import {useEffect, useState} from 'react';

interface useAuthenticationProps {
    forgotPasswordAuthority: string;
    signInScopes: string[];
    signInAuthority: string;
}

/**
 * Hook for authenticating with Azure B2C.
 * Returns the token when it has authenticated, or aquired a token from silet request.
 * A status flag done is also provided when the authentication is completed.
 */
export const useB2cAuthentication = ({
    forgotPasswordAuthority,
    signInScopes,
    signInAuthority,
}: useAuthenticationProps) => {
    const {instance, accounts, inProgress} = useMsal();
    const [done, setDone] = useState(false);
    const [authenticated, setAuthenticated] = useState<undefined | {token: string}>();

    useEffect(() => {
        const initializeUser = async () => {
            setDone(false);
            await instance.handleRedirectPromise();
            const userAccount = accounts[0];
            if (inProgress === 'none' && userAccount) {
                if (!userAccount.idTokenClaims) return;
                const tfp = (userAccount.idTokenClaims as Record<string, string>)['tfp'];

                if (tfp && forgotPasswordAuthority.includes(tfp)) {
                    await instance.logout();
                    return;
                }

                const request = {
                    scopes: signInScopes,
                    account: userAccount,
                };
                try {
                    const tokenResponse = await instance.acquireTokenSilent(request);
                    if (!tokenResponse.account?.idTokenClaims) return;

                    setAuthenticated({
                        token: tokenResponse.accessToken,
                    });
                } catch (error) {
                    if (
                        (error instanceof AuthError && error.errorMessage.includes('AADB2C90077')) ||
                        error instanceof BrowserAuthError ||
                        error instanceof InteractionRequiredAuthError
                    ) {
                        return instance.acquireTokenRedirect({
                            ...request,
                            authority: signInAuthority,
                        });
                    }
                }
                setDone(true);
            } else {
                setDone(true);
                // do something if there are errors
            }
        };
        initializeUser();
    }, [instance, inProgress, accounts]);

    return {...authenticated, done};
};
