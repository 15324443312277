import {Check, Close} from '@mui/icons-material';
import {Box, IconButton, SxProps, TextField, TextFieldProps, Theme} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import {useLanguage} from '../language/LanguageProvider';

export const EditableText: React.FC<{
    onCancel: () => void;
    onSave: (text: string) => void;
    value?: string;
    textStyleOverrides?: {
        sx: SxProps<Theme>;
        variant: TextFieldProps['variant'];
    };
}> = ({onCancel, onSave, value, textStyleOverrides}) => {
    const {getLangString} = useLanguage();
    const [text, setText] = useState<string>(value || '');
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };
    const handleSave = () => {
        onSave(text);
    };
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <TextField value={text} {...textStyleOverrides} onChange={handleChange} autoFocus={true} />
            <Box>
                <IconButton onClick={onCancel}>
                    <Close />
                </IconButton>
                <IconButton onClick={handleSave}>
                    <Check />
                </IconButton>
            </Box>
        </Box>
    );
};
