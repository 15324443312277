import {DeclarationResponse} from './declarationDto';
import {VolumeDto} from './productVolumeDto';

export type ProductConfig = {
    productConfigId: number;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    transportName: string;
    transportId: number;
    validFrom: string;
    validTo: string;
    comment: string;
    isValid: boolean;
};

type Transport = {
    transportId: number;
    transportName: string;
    companyId: number;
    gwp: number;
    rGwp: number;
    unit: string;
    comment: string;
    createdAt: Date;
    updatedAt: Date;
};

export enum PackagingType {
    Fpak = 1,
    Dpak = 2,
    Pallet = 3,
}
export type PackagingHandlingCapability = 'reuse' | 'material_recycling' | 'energy_recycling' | 'composting';
export type ProductPackaging = {
    productPackagingId: number;
    productConfigId: number;
    packagingId: number;
    packagingName: string;
    packagingHandlingCapability: PackagingHandlingCapability;
    packagingType: PackagingType;
    netWeightGrams: number;
    recycablePercentage: number;
    // createdAt: Date;
    // updatedAt: Date;
    comment: string;
};
export type UpdateProductPackaging = {
    packagingId: number;
    packagingType: PackagingType;
    netWeightGrams: number;
    recycablePercentage: number;
    comment: string;
};

export type ProductConfigResponse = ProductConfig & {
    product: {
        productId: number;
        internalId: number;
        productName: string;
        productGroupId: number;
        productGroupName: string;
        productGroupTypeName: string;
    };
    transport: Transport;
    volumes: VolumeDto[];
    declaration: DeclarationResponse;
    productPackagings: ProductPackaging[];
};

export type CreateProductConfigRequest = {
    productId: number;
    transportId: number;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    validFrom: string;
    validTo?: string;
    comment: string;
};
