import {Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import {ReactElement} from 'react';
import {getTextWithLink} from './getTextWithLink';

export const getParagraphs = (text: string, variant?: Variant, url?: string): ReactElement[] => {
    const paragraphs = text.split('\n');
    return paragraphs.map((p, i) => {
        return (
            <Typography sx={{mb: 1, mt: 0}} key={i} variant={variant || 'inherit'} minHeight={16}>
                {url ? getTextWithLink(p, url) : p}
            </Typography>
        );
    });
};

// export const;
