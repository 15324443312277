import {axiosInstance} from '../axiosInstance';
import {KeyFiguresResponse, ReportsData, ReportType} from '../dtos/reportsDto';
import {keyFiguresPath} from '../paths';

export const getKeyFigures = async (companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<KeyFiguresResponse>(`${keyFiguresPath.base()}?companyId=${companyId}`)
        .then((res) => ({...res.data, reportData: res.data.keyFigures}));

export const getKeyFiguresByProductType = async (productTypeId: number, companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<KeyFiguresResponse>(`${keyFiguresPath.type()}?productTypeId=${productTypeId}&companyId=${companyId}`)
        .then((res) => ({...res.data, reportData: res.data.keyFigures}));

export const getKeyFiguresByProductGroup = async (productGroupId: number, companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<KeyFiguresResponse>(`${keyFiguresPath.group()}?productGroupId=${productGroupId}&companyId=${companyId}`)
        .then((res) => ({...res.data, reportData: res.data.keyFigures}));

export const exportProductKeyFigures = async (reqData: {companyId: number; reportType: ReportType}) =>
    await axiosInstance
        .get(`${keyFiguresPath.export()}?companyId=${reqData.companyId}&reportType=${reqData.reportType}`, {
            responseType: 'blob',
        })
        .then((res) => res.data);

export const exportProductGroupKeyFigures = async (reqData: {
    companyId: number;
    productGroupId: number;
    reportType: ReportType;
}) =>
    await axiosInstance
        .get(
            `${keyFiguresPath.exportGroup()}?companyId=${reqData.companyId}&productGroupId=${
                reqData.productGroupId
            }&reportType=${reqData.reportType}`,
            {
                responseType: 'blob',
            },
        )
        .then((res) => res.data);

export const exportProductTypeKeyFigures = async (reqData: {
    companyId: number;
    productTypeId: number;
    reportType: ReportType;
}) =>
    await axiosInstance
        .get(
            `${keyFiguresPath.exportType()}?companyId=${reqData.companyId}
            &productTypeId=${reqData.productTypeId}
            &reportType=${reqData.reportType}`,
            {
                responseType: 'blob',
            },
        )
        .then((res) => res.data);
