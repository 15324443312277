import {axiosInstance} from '../axiosInstance';
import {ReportsData, ReportType, YearlyReportResponse} from '../dtos/reportsDto';
import {yearlyReportPath} from '../paths';

export const getYearlyReport = async (companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<YearlyReportResponse>(`${yearlyReportPath.base()}?companyId=${companyId}`)
        .then((res) => ({...res.data, reportData: res.data.yearlyReports}));

export const getYearlyReportByProductType = async (productTypeId: number, companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<YearlyReportResponse>(`${yearlyReportPath.type()}?productTypeId=${productTypeId}&companyId=${companyId}`)
        .then((res) => ({...res.data, reportData: res.data.yearlyReports}));

export const getYearlyReportByProductGroup = async (productGroupId: number, companyId: number): Promise<ReportsData> =>
    await axiosInstance
        .get<YearlyReportResponse>(
            `${yearlyReportPath.group()}?productGroupId=${productGroupId}&companyId=${companyId}`,
        )
        .then((res) => ({...res.data, reportData: res.data.yearlyReports}));

export const exportProductYearlyReport = async (reqData: {companyId: number; reportType: ReportType}) =>
    await axiosInstance
        .get(`${yearlyReportPath.export()}?companyId=${reqData.companyId}&reportType=${reqData.reportType}`, {
            responseType: 'blob',
        })
        .then((res) => res.data);

export const exportProductGroupYearlyReport = async (reqData: {
    companyId: number;
    productGroupId: number;
    reportType: ReportType;
}) =>
    await axiosInstance
        .get(
            `${yearlyReportPath.exportGroup()}?companyId=${reqData.companyId}&productGroupId=${
                reqData.productGroupId
            }&reportType=${reqData.reportType}`,
            {
                responseType: 'blob',
            },
        )
        .then((res) => res.data);

export const exportProductTypeYearlyReport = async (reqData: {
    companyId: number;
    productTypeId: number;
    reportType: ReportType;
}) =>
    await axiosInstance
        .get(
            `${yearlyReportPath.exportType()}?companyId=${reqData.companyId}
            &productTypeId=${reqData.productTypeId}
            &reportType=${reqData.reportType}`,
            {
                responseType: 'blob',
            },
        )
        .then((res) => res.data);
