import {TextField} from '@mui/material';
import {OutlinedTextFieldProps} from '@mui/material/TextField/TextField';
import {GridEventListener, useGridApiContext, useGridApiEventHandler} from '@mui/x-data-grid';
import React, {FC, KeyboardEventHandler} from 'react';

export const CustomTableTextField: FC<
    Partial<OutlinedTextFieldProps> & {
        cellId: number | string;
        field: string;
        triggerSave?: (id: number | string) => void;
        tabToNextRow?: (id: number | string) => void;
        error?: boolean;
    }
> = (props) => {
    const apiRef = useGridApiContext();
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({id: props.cellId, field: props.field, value: newValue});
    };
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.code) {
            case 'Enter':
                if (props.triggerSave) {
                    props.triggerSave(props.cellId);
                }
                return;
            case 'Tab':
                if (props.tabToNextRow) {
                    props.tabToNextRow(props.cellId);
                }
                return;
            default:
                return;
        }
    };
    const handleEsc: GridEventListener<'cellKeyDown'> = (params, event) => {
        if (event.code === 'Escape') {
            props.triggerSave && props.triggerSave(params.id);
        }
    };
    useGridApiEventHandler(apiRef, 'cellKeyDown', handleEsc);

    return (
        <TextField
            type={props.type}
            variant={'outlined'}
            autoFocus={props.autoFocus}
            InputProps={props.InputProps}
            error={props.error}
            value={props.value}
            onError={props.onError}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            sx={{width: '100%'}}
        />
    );
};
