export const NO = {
    //--- Generic ---
    COMMENT: 'Kommentar',
    BACK: 'Tilbake',
    CANCEL: 'Avbryt',
    SAVE: 'Lagre',
    SAVE_CHANGES: 'Lagre endringer',
    EDIT: 'Rediger',
    DELETE: 'Slett',
    REMOVE: 'Fjern',
    SETTINGS: 'Innstillinger',
    YEAR: 'År',
    PACKAGING: 'Emballasje',
    CREATED_BY: 'Opprettet av',
    DATE: 'Dato',
    DESCRIPTION: 'Beskrivelse',
    CREATE: 'Opprett',
    SELECT: 'Velg',
    UPDATE: 'Oppdater',
    FROM: 'Fra',
    TO: 'Til',
    OK: 'Ok',
    LOG_IN: 'Logg inn',
    LOG_OUT: 'Logg ut',
    GRAM: 'Gram',
    LAST_UPDATED: 'Sist oppdatert',
    IMPORT: 'Importer',
    EXPORT: 'Eksporter',
    ALL: 'Alle',
    ADD: 'Legg til',

    //--- Navigation ---
    REPORTS: 'Rapporter',
    ANALYSIS: 'Analyse',
    DECLARATION: 'Deklarasjon',
    SALES_AND_WASTE: 'Salg og svinn',
    REGISTRY: 'Register',
    MANUAL: 'Manual',

    //--- Forms ---
    FIELD_IS_REQUIRED: 'Feltet er påkrevd',

    //--- Products ---
    PRODUCT_LIST: 'Produktliste',
    PRODUCT_GROUP: 'Produktgruppe',
    PRODUCT_GROUPS: 'Produktgrupper',
    PACKAGING_TYPES: 'Emballasjetyper',
    TRANSPORT: 'Transport',
    PRODUCTS: 'Produkter',
    TRANSPORT_TYPES: 'Transporttyper',

    CREATE_PRODUCT: 'Opprett nytt produkt',
    CREATE_PRODUCT_COPY: 'Opprett kopi av produkt',
    CREATE_PRODUCT_COPY_PREVIOUS: 'Opprett kopi av forrige produktkort',
    CREATE_PRODUCT_VERSION: 'Opprett ny versjon',
    DELETE_PRODUCT: 'Slett produkt',
    DELETE_PRODUCT_CONFIRMATION: 'Ønsker du å slette dette produktet?',
    DELETE_PRODUCT_CONFIG: 'Slett versjon',
    OLDER_VERSIONS: 'Eldre versjoner',

    MATERIALS: 'Materialer',
    MATERIAL: 'Materiale',
    ADD_MATERIAL: 'Legg til materiale',
    TYPE: 'Type',
    RECYCLED_PERCENT: 'Resirkulert %',
    PACKAGING_HANDLING_CAPABILITY: 'Gjenvinnbarhet',
    REUSED: 'Ombrukes',
    MATERIAL_RECYCLING: 'Materialgjenvinnes',
    ENERGY_RECYCLING: 'Energigjenvinnes',
    COMPOSTING: 'Komposeteres/brytes ned biologisk',
    SELECT_PACKAGING_HANDLING_CAPABILITY: 'Velg gjenvinnbarhet',

    //--- Registry ---
    BACKGROUND_DATA: 'Bakgrunnsdata',
    ADD_TRANSPORT_TYPE: 'Legg til transporttype',
    ADD_PRODUCT_GROUP: 'Legg til produktgruppe',
    ADD_PACKAGING_TYPE: 'Legg til emballasjetype',
    ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN_HEADER: 'Kunne ikke slette gruppe',
    ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN:
        'Gruppen du prøvde å slette er i bruk. For å slette denne må du først sørge for at ingen produkter tilhører denne gruppen.',
    PRODUCT_NET_WEIGHT_FPAK: 'Nettovekt produkt i Fpak',
    PACKAGING_NOT_FOUND: 'Fant ingen emballasje',
    IMPORT_PRODUCTS: 'Importer produkter',
    IMPORT_PACKAGING: 'Importer emballasjedata',
    EXPORT_PRODUCTS: 'Eksporter produkter',
    EXPORT_PACKAGING: 'Eksporter emballasjedata',
    ERROR_MSG__IMPORT_INCORRECT_FORMAT_HEADER: 'Kunne ikke importere data',
    ERROR_MSG__IMPORT_INCORRECT_FORMAT: 'Filen du prøver å importere har ikke riktig format.',

    //--- Declaration ---
    DECLARED: 'Deklarert',
    DECLARE: 'Deklarer',
    GO_TO_DECLARATION: 'Se deklarasjon',
    ADD_FILE: 'Legg til fil',

    //--- Reports ---
    ANNUAL_REPORT: 'Årsrapport',
    KEY_FIGURES: 'Nøkkeltall',
    PACKAGING_CONSUMPTION: 'Emballasjeforbruk',
    CLIMATE_FOOTPRINT_PACKAGING: 'Klimafotavtrykk emballasje',
    CLIMATE_FOOTPRINT_VALUE: 'Klimafotavtrykk verdikjede',

    TEXT_PACKAGING_CONSUMPTION_ANNUAL_REPORT:
        'Mengde emballasje per år er beregnet ved å multiplisere nøkkeltallet kg emballasje per tonn med årlig omsatt \n' +
        'mengde for det aktuelle produktet. Nøkkeltallet kg emballasje per tonn produkt er beregnet ut fra bakgrunnsdata\n' +
        '(antall tripper for ombruksemballasje) og inputdata (gram emballasje fordelt på materialtype, produktvekt per \n' +
        'F-pak, antall F-pak i D-pak og antall D-pak på pall).',
    TEXT_PACKAGING_CONSUMPTION_KEY_FIGURES:
        'Nøkkeltallet kg emballasje per tonn produkt er beregnet ut fra bakgrunnsdata (antall tripper for \n' +
        'ombruksemballasje) og inputdata (gram emballasje fordelt på materialtype, produktvekt per F-pak, \n' +
        'antall F-pak i D-pak og antall D-pak på pall).',

    TEXT_CLIMATE_FOOTPRINT_PACKAGING:
        'Klimafotavtrykket er basert på livsløpsanalysedata fra Ecoinventdatabasen og er sammenstilt av NORSUS. Klimaavtrykket inkluderer alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen.\n \n' +
        'OBS! Det påhviler brukerne av PackMan et ansvar å sikre at resultater fra verktøyet benyttes på en måte som er i tråd med markedsføringsloven. PackMan er et forenklet analyseverktøy, det anbefales derfor ikke å bruke resultater fra PackMan i markedsføring.\n' +
        'Viktige forutsetninger og avgrensninger:\n' +
        '- For emballasje er alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen inkludert.\n' +
        '- Dersom emballasjen kan materialgjenvinnes er det antatt at emballasjen blir materialgjenvunnet. Merk at i realiteten vil klimafotavtrykket for den materialgjenvinnbare emballasjen være større ettersom gjenvinningsgraden er under 100 %.\n' +
        '- Klimafotavtrykk knyttet til vask og returtransport av ombruksemballasje er ikke inkludert, resultatene kan derfor ikke brukes for sammenlikning av ombruks - og engangsemballasje.\n' +
        '- Denne rapporten viser klimagassutslipp knyttet til produksjon og avfallshåndtering av selve emballasjen. Ved utforming av emballasje bør hele livsløpet til produktet vurderes.\n' +
        '- Denne rapporten ser kun på klimaavtrykket knyttet til emballasje. Det er mange andre hensyn som bør vurderes ved utforming av emballasje, som andre miljøindikatorer (eutrofiering, forsuring, vannforbruk, biologisk mangfold mm), samt sosiale - og økonomiske indikatorer.\n',
    TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART1__FORMAT:
        'Klimafotavtrykket er basert på livsløpsanalysedata fra %href_start%Ecoinventdatabasen%href_end% og er sammenstilt av NORSUS. Klimaavtrykket inkluderer alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen.\n',
    TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART2:
        'OBS! Det påhviler brukerne av PackMan et ansvar å sikre at resultater fra verktøyet benyttes på en måte som er i tråd med markedsføringsloven. PackMan er et forenklet analyseverktøy, det anbefales derfor ikke å bruke resultater fra PackMan i markedsføring.\n \n' +
        'Viktige forutsetninger og avgrensninger:',
    TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART2_BULLETS:
        '- For emballasje er alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen inkludert.\n' +
        '- Dersom emballasjen kan materialgjenvinnes er det antatt at emballasjen blir materialgjenvunnet. Merk at i realiteten vil klimafotavtrykket for den materialgjenvinnbare emballasjen være større ettersom gjenvinningsgraden er under 100 %.\n' +
        '- Klimafotavtrykk knyttet til vask og returtransport av ombruksemballasje er ikke inkludert, resultatene kan derfor ikke brukes for sammenlikning av ombruks - og engangsemballasje.\n' +
        '- Denne rapporten viser klimagassutslipp knyttet til produksjon og avfallshåndtering av selve emballasjen. Ved utforming av emballasje bør hele livsløpet til produktet vurderes.\n' +
        '- Denne rapporten ser kun på klimaavtrykket knyttet til emballasje. Det er mange andre hensyn som bør vurderes ved utforming av emballasje, som andre miljøindikatorer (eutrofiering, forsuring, vannforbruk, biologisk mangfold mm), samt sosiale - og økonomiske indikatorer.\n',

    TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN:
        'Klimafotavtrykket er basert på livsløpsanalysedata fra Ecoinventdatabasen og er sammenstilt av NORSUS. Klimaavtrykket inkluderer alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen.\n\n' +
        'OBS! Det påhviler brukerne av PackMan et ansvar å sikre at resultater fra verktøyet benyttes på en måte som er i tråd med markedsføringsloven. PackMan er et forenklet analyseverktøy, det anbefales derfor ikke å bruke resultater fra PackMan i markedsføring.\n' +
        'Viktige forutsetninger og avgrensninger:\n' +
        '- For emballasje er alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen inkludert.\n' +
        '- Dersom emballasjen kan materialgjenvinnes er det antatt at emballasjen blir materialgjenvunnet. Merk at i realiteten vil klimafotavtrykket for den materialgjenvinnbare emballasjen være større ettersom gjenvinningsgraden er under 100 %.\n' +
        '- Klimafotavtrykk knyttet til vask og returtransport av ombruksemballasje er ikke inkludert, resultatene kan derfor ikke brukes for sammenlikning av ombruks - og engangsemballasje.\n' +
        '- Denne rapporten viser klimagassutslipp knyttet til produksjon av produkt, transport av produkt, produksjon og avfallshåndtering av selve emballasjen samt produktsvinn. Ved utforming av emballasje bør hele livsløpet til produktet vurderes.\n' +
        '- Resultatene for transport og produksjon av produkt er statiske, hvilket betyr at endringer i fyllingsgrad ikke påvirker klimaresultatene.\n' +
        '- Denne rapporten ser kun på klimaeffekten. Det er mange andre hensyn som bør vurderes ved utforming av emballasje, som andre miljøindikatorer (eutrofiering, forsuring, vannforbruk, biologisk mangfold mm), samt sosiale - og økonomiske indikatorer.',
    TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART1__FORMAT:
        'Klimafotavtrykket er basert på livsløpsanalysedata fra %href_start%Ecoinventdatabasen%href_end% og er sammenstilt av NORSUS. Klimaavtrykket inkluderer alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen.\n',
    TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART2:
        'OBS! Det påhviler brukerne av PackMan et ansvar å sikre at resultater fra verktøyet benyttes på en måte som er i tråd med markedsføringsloven. PackMan er et forenklet analyseverktøy, det anbefales derfor ikke å bruke resultater fra PackMan i markedsføring.\n \n' +
        'Viktige forutsetninger og avgrensninger:',
    TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART2_BULLETS:
        '- For emballasje er alle utslipp av klimagasser knyttet til produksjon og avfallshåndtering av emballasjen inkludert.\n' +
        '- Dersom emballasjen kan materialgjenvinnes er det antatt at emballasjen blir materialgjenvunnet. Merk at i realiteten vil klimafotavtrykket for den materialgjenvinnbare emballasjen være større ettersom gjenvinningsgraden er under 100 %.\n' +
        '- Klimafotavtrykk knyttet til vask og returtransport av ombruksemballasje er ikke inkludert, resultatene kan derfor ikke brukes for sammenlikning av ombruks - og engangsemballasje.\n' +
        '- Denne rapporten viser klimagassutslipp knyttet til produksjon av produkt, transport av produkt, produksjon og avfallshåndtering av selve emballasjen samt produktsvinn. Ved utforming av emballasje bør hele livsløpet til produktet vurderes.\n' +
        '- Resultatene for transport og produksjon av produkt er statiske, hvilket betyr at endringer i fyllingsgrad ikke påvirker klimaresultatene.\n' +
        '- Denne rapporten ser kun på klimaeffekten. Det er mange andre hensyn som bør vurderes ved utforming av emballasje, som andre miljøindikatorer (eutrofiering, forsuring, vannforbruk, biologisk mangfold mm), samt sosiale - og økonomiske indikatorer.',

    //--- Analysis ---
    ANALYSES: 'Analyser',
    ANALYSIS_NAME: 'Navn analyse',
    CREATE_ANALYSIS: 'Opprett analyse',
    ANALYZE: 'Analyser',
    RESULTS: 'Resultater',
    SELECT_FROM_PRODUCT_LIST: 'Velg fra produktlista',
    SELECT_PRODUCT: 'Velg produkt',
    ADD_NEW_PRODUCT: 'Lag nytt produkt',
    CREATE_ANALYSIS_COPY: 'Opprett kopi av analyse',
    SELECT_TRANSPORT: 'Velg transport',
    DELETE_ANALYSIS: 'Slett analyse',
    DELETE_ANALYSIS_CONFIRMATION: 'Ønsker du å slette denne analysen?',

    //--- Table headers ---
    PRODUCT_NAME: 'Produktnavn',
    NAME: 'Navn',
    NET_WEIGHT: 'Nettovekt gram',
    FPAK: 'Fpak',
    DPAK: 'Dpak',
    PALLET: 'Pall',
    FPAK_DPAK: 'Fpak Dpak',
    DPAK_PALLET: 'Dpak pall',
    PRODUCT_START: 'Oms. start',
    PRODUCT_END: 'Oms. slutt',
    SELECT_PRIMARY_GROUP: 'Velg hovedgruppe',
    PRIMARY_GROUP: 'Hovedgruppe',
    PRIMARY_GROUPS: 'Hovedgrupper',
    GWP: 'GWP',
    RGWP: 'rGWP',
    TRIP: 'Trip',
    CUSTOMER: 'Kunde',
    SALES_VOLUME: 'Kg solgt',
    PRODUCT_WASTE: 'Kg produktsvinn',

    FPAK_DPAK_AMOUNT: 'Antall Fpak i Dpak',
    DPAK_PALLET_AMOUNT: 'Antall Dpak på pall',
};

export type TranslationKeys = keyof typeof NO;

export type SupportedLanguage = 'NO';

type Dictionaries = {[key: string]: {[key: string]: string}};
export const dictionaries: Dictionaries = {
    NO,
};
