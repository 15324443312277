import {Company, CurrentAdminUser, Role} from 'lib/models';
import {ApplicationStateAction} from './ApplicationStateActions';

export type ApplicationState = {
    /**
     * Flag to indicate that we are authenticated
     */
    authenticated: boolean;
    /**
     * The current role we are signed in as
     */
    activeRole: Role;
    /**
     * The current user we are signed in as
     */
    currentUser?: CurrentAdminUser;
    /**
     * the current active company for the current user.
     */
    currentCompany?: Company;
};

export const applicationStateReducer = (state: ApplicationState, action: ApplicationStateAction) => {
    const newState = {...state};
    switch (action.type) {
        case 'setActiveRole':
            newState['activeRole'] = action.payload;
            break;
        case 'setCurrentUser':
            newState['currentUser'] = action.payload;
            break;

        case 'setAuthenticated':
            newState['authenticated'] = action.payload;
            break;
        case 'setCurrentCompany':
            newState['currentCompany'] = action.payload;
            break;
        default:
            break;
    }
    return newState;
};
