import {Box} from '@mui/material';
import {GridRowModel} from '@mui/x-data-grid';
import {AxiosPromise} from 'axios';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {
    useCreatePackaging,
    useCreatePackagingGroup,
    useDeletePackaging,
    useDeletePackagingGroup,
    useGetPackaging,
    useGetPackagingGroups,
    useUpdatePackaging,
    useUpdatePackagingGroup,
} from '../../api/hooks/packaging';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressCentered} from '../ProgressComponents';
import {AddGWPValuesFormFields} from './DialogAddGWPItem';
import {GWPTable, GWPTableRowProps} from './GWPTable';
import {PrimaryGroupItems} from './PrimaryGroupItems';

export const PackagingList: React.FC = () => {
    const {getLangString} = useLanguage();

    const currentCompanyId = useCurrentCompanyId();

    const packagingGroups = useGetPackagingGroups(currentCompanyId);
    const packaging = useGetPackaging(currentCompanyId);

    const updatePackagingGroup = useUpdatePackagingGroup();
    const createPackagingGroup = useCreatePackagingGroup();
    const deletePackagingGroup = useDeletePackagingGroup();
    const createNewPackagingGroup = (name: string): AxiosPromise => {
        return createPackagingGroup.mutateAsync({packagingGroupName: name});
    };
    const updateExistingPackagingGroup = (id: number, name: string): AxiosPromise => {
        return updatePackagingGroup.mutateAsync({
            packagingGroupId: id,
            data: {packagingGroupName: name},
        });
    };
    const doDeletePackagingGroup = (id: number): AxiosPromise => {
        return deletePackagingGroup.mutateAsync(id);
    };

    const updateExistingPackaging = useUpdatePackaging();
    const createNewPackaging = useCreatePackaging();
    const deletePackaging = useDeletePackaging();

    if (packagingGroups.isLoading || packaging.isLoading) {
        return <CircularProgressCentered />;
    }
    if (packagingGroups.isError || packaging.isError) {
        //TODO: add better error message
        return <Box>Noe feilet</Box>;
    }
    const getPackagingTableData = (): GWPTableRowProps[] => {
        return packaging.data.map((p) => {
            const currentGroup = packagingGroups.data.find((g) => {
                if (g.packagingGroupId === p.packagingGroupId) {
                    return g.packagingGroupName;
                }
            });
            return {
                id: p.packagingId,
                name: p.packagingName,
                primaryGroup: String(currentGroup?.packagingGroupId),
                GWP: String(p.gwp),
                rGWP: String(p.rGwp),
                trip: String(p.trip),
                comment: p.comment,
                handlingCapability: p.handlingCapability,
                isNew: false,
            };
        });
    };

    const handleRowUpdate = (newRow: GridRowModel<GWPTableRowProps>) => {
        updateExistingPackaging.mutate({
            packagingId: newRow.id,
            data: {
                packagingName: newRow.name,
                packagingGroupId: Number(newRow.primaryGroup),
                gwp: parseFloat(newRow.GWP),
                rGwp: parseFloat(newRow.rGWP!),
                unit: '',
                trip: parseInt(newRow.trip!),
                handlingCapability: newRow.handlingCapability!,
                comment: newRow.comment,
            },
        });
    };
    const handleCreate = (newRow: AddGWPValuesFormFields) => {
        createNewPackaging.mutate({
            packagingName: newRow.name,
            packagingGroupId: Number(newRow.primaryGroup),
            gwp: parseFloat(newRow.GWP),
            rGwp: parseFloat(newRow.rGWP!),
            unit: '',
            trip: parseInt(newRow.trip!),
            handlingCapability: newRow.handlingCapability!,
            comment: newRow.comment,
        });
    };
    const handleRowDelete = (packagingId: number) => {
        return deletePackaging.mutateAsync(packagingId);
    };

    const getPackagingGroupOptions = (): Record<number, string> =>
        packagingGroups.data.reduce(
            (acc, currGroup) => ({
                ...acc,
                [currGroup.packagingGroupId]: currGroup.packagingGroupName,
            }),
            {},
        );
    const groups = packagingGroups.data.map((g) => ({id: g.packagingGroupId, name: g.packagingGroupName}));

    return (
        <>
            <GWPTable
                tableData={getPackagingTableData()}
                includeRGWP={true}
                includeTrip={true}
                onRowUpdate={handleRowUpdate}
                onCreate={handleCreate}
                onRowDelete={handleRowDelete}
                rowCreateButtonText={getLangString('ADD_PACKAGING_TYPE')}
                primaryGroupOptions={getPackagingGroupOptions()}
            />
            <Box mt={3}>
                <PrimaryGroupItems
                    headerText={'Hovedgrupper emballasje'}
                    groups={groups}
                    onUpdateItem={updateExistingPackagingGroup}
                    onCreateItem={createNewPackagingGroup}
                    onDeleteItem={doDeletePackagingGroup}
                />
            </Box>
        </>
    );
};
