import {useMsal} from '@azure/msal-react';
import React, {useEffect} from 'react';
import {LOGIN_REQUEST} from '../../lib/config/AzureB2cConfig';
import {CircularProgressCentered} from '../ProgressComponents';

export const LoginPage: React.FC = () => {
    const {instance, inProgress, accounts} = useMsal();
    useEffect(() => {
        if (inProgress === 'none' && accounts.length === 0) {
            instance.loginRedirect(LOGIN_REQUEST);
        }
    }, [inProgress]);

    return <CircularProgressCentered />;
};
