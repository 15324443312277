import {Cancel, CheckCircle, RemoveCircle} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {useQuery} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {DeclarationResponse} from '../../api/dtos/declarationDto';
import {DeclarationState} from '../../api/dtos/productDto';
import {getProductsWithDeclaration} from '../../api/handlers/product';
import {useLanguage} from '../../language/LanguageProvider';

type ProductRowType = {
    id: number;
    name: string;
    productGroupName: string;
    declarationState: DeclarationState;
    declaration?: DeclarationResponse;
    updatedAt?: string;
};
export const DeclarationPage: React.FC = () => {
    const {getLangString} = useLanguage();

    const currentCompanyId = useCurrentCompanyId();

    const {status, data} = useQuery(['productWithDeclaration'], () => getProductsWithDeclaration(currentCompanyId), {
        enabled: currentCompanyId > 0,
    });
    const navigate = useNavigate();

    const goToCreateProductDeclarationPage = (productId: number) => {
        navigate('/declaration/create/' + productId);
    };
    const goToUpdateProductDeclarationPage = (declarationId: number) => {
        navigate('/declaration/update/' + declarationId);
    };
    const getRows = (): GridRowsProp<ProductRowType> => {
        if (data) {
            return data.map((p) => ({
                id: p.productId,
                name: p.productName,
                productGroupName: p.productGroupName,
                declarationState: p.declarationState,
                declarationId: p.declaration ? p.declaration.declarationId : undefined,
                updatedAt: p.declaration ? new Date(p.declaration.declaredAt).toLocaleDateString() : undefined,
            }));
        }
        return [];
    };
    const getDeclarationStatusIcon = (state: DeclarationState) => {
        switch (state) {
            case DeclarationState.DECLARED:
                return <CheckCircle color={'success'} />;
            case DeclarationState.PARTIALLY_DECLARED:
                return <RemoveCircle color={'warning'} />;
            default:
                return <Cancel color={'disabled'} />;
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: getLangString('PRODUCT_NAME'),
            flex: 1,
        },
        {
            field: 'productGroupName',
            headerName: getLangString('PRODUCT_GROUP'),
            flex: 1,
        },
        {
            field: 'declarationState',
            headerName: getLangString('DECLARED'),
            renderCell: (params) => getDeclarationStatusIcon(params.value),
            align: 'center',
        },
        {
            field: 'updatedAt',
            headerName: getLangString('LAST_UPDATED'),
            minWidth: 140,
        },
        {
            field: '',
            minWidth: 160,
            valueGetter: (params) => params.row.declarationId,
            renderCell: (params) =>
                params.value ? (
                    <Button
                        fullWidth={true}
                        onClick={() => goToUpdateProductDeclarationPage(params.row.declarationId)}
                        color={'primary'}
                        variant={'outlined'}
                    >
                        {getLangString('GO_TO_DECLARATION')}
                    </Button>
                ) : (
                    <Button
                        fullWidth={true}
                        onClick={() => goToCreateProductDeclarationPage(parseInt(params.row.id as string))}
                        variant={'outlined'}
                    >
                        {getLangString('DECLARE')}
                    </Button>
                ),
        },
    ];

    return (
        <Box my={4} height={window.innerHeight - 64 - 56}>
            <DataGrid columns={columns} rows={getRows()} loading={status === 'loading'} />
        </Box>
    );
};
