import {ArrowDropDown, OutputRounded} from '@mui/icons-material';
import {Box, Link} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRowsProp,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import {useMutation} from '@tanstack/react-query';
import {useApplicationState} from 'lib/context/applicationState';
import {Role} from 'lib/models';
import React, {ChangeEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ProductResponse} from '../../api/dtos/productDto';
import {createCopyOfProduct} from '../../api/handlers/product';
import {useDeleteProduct} from '../../api/hooks/product';
import {useLanguage} from '../../language/LanguageProvider';
import {ButtonWithImportOptions, ButtonWithMenu} from '../ButtonWithMenu';
import {DialogDelete} from '../DialogDelete';
import {TableRowMenu} from '../TableRowMenu';

type ProductRowType = {
    id: number;
    name: string;
    productGroupName: string;
    weight: number;
    fpakDpak: number;
    dpakPallet: number;
    transport: string;
    startDate: number;
    endDate: number | undefined;
    comment: string;
};
export const ProductList: React.FC<{
    data?: ProductResponse[];
    isLoading: boolean;
    handleImportProducts: (e: ChangeEvent<HTMLInputElement>) => void;
    handleImportPackaging: (e: ChangeEvent<HTMLInputElement>) => void;
    handleExportProducts: () => void;
    handleExportPackaging: () => void;
}> = ({data, isLoading, handleImportPackaging, handleImportProducts, handleExportProducts, handleExportPackaging}) => {
    const navigate = useNavigate();
    const {state} = useApplicationState();
    const goToProductPage = (id: number | string) => {
        navigate('/registry/' + id);
    };
    const {getLangString} = useLanguage();

    const getRows = (): GridRowsProp<ProductRowType> => {
        if (data) {
            return data.map((p) => {
                return {
                    id: p.productId,
                    name: p.productName,
                    productGroupName: p.productGroupName,
                    weight: p.currentProductConfig.netWeightGrams,
                    fpakDpak: p.currentProductConfig.fpakDpak,
                    dpakPallet: p.currentProductConfig.dpakPallet,
                    transport: p.currentProductConfig.transportName,
                    startDate: new Date(p.currentProductConfig.validFrom).getFullYear(),
                    endDate: p.currentProductConfig.validTo
                        ? new Date(p.currentProductConfig.validTo).getFullYear()
                        : undefined,
                    comment: p.currentProductConfig.comment,
                };
            });
        }
        return [];
    };
    const doCreateCopy = useMutation(createCopyOfProduct);
    const handleCreateCopy = (productId: number) => {
        doCreateCopy.mutateAsync(productId).then((resp) => goToProductPage(resp.productId));
    };

    const [productToBeDeleted, setProductToBeDeleted] = useState<number | undefined>();
    const clearProductDeletion = () => setProductToBeDeleted(undefined);
    const doDeleteProduct = useDeleteProduct();
    const handleDeleteProduct = async () => {
        if (productToBeDeleted) {
            await doDeleteProduct.mutateAsync(productToBeDeleted);
            clearProductDeletion();
        }
    };
    const getMenuItems = (id: number) => {
        const items = [
            {
                name: getLangString('CREATE_PRODUCT_COPY'),
                onClick: () => handleCreateCopy(id),
            },
            {
                name: getLangString('DELETE_PRODUCT'),
                onClick: () => setProductToBeDeleted(id),
            },
        ];

        return items;
    };
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: getLangString('PRODUCT_NAME'),
            flex: 1,
            renderCell: (params) => (
                <Link
                    component={'button'}
                    onClick={() => goToProductPage(params.id)}
                    sx={{
                        fontSize: '0.875rem',
                        textDecoration: 'none',
                        flex: 1,
                        textAlign: 'start',
                        height: '100%',
                        '&:hover': {textDecoration: 'underline'},
                    }}
                >
                    {params.value}
                </Link>
            ),
        },
        {field: 'productGroupName', headerName: getLangString('PRODUCT_GROUP'), flex: 1},
        {field: 'weight', headerName: getLangString('NET_WEIGHT'), width: 120, align: 'right'},
        {field: 'fpakDpak', headerName: getLangString('FPAK_DPAK'), align: 'right'},
        {field: 'dpakPallet', headerName: getLangString('DPAK_PALLET'), align: 'right'},
        {field: 'transport', headerName: getLangString('TRANSPORT'), flex: 1},
        {field: 'startDate', headerName: getLangString('PRODUCT_START')},
        {field: 'endDate', headerName: getLangString('PRODUCT_END')},
        {field: 'comment', headerName: getLangString('COMMENT'), flex: 1},
    ];

    if (state.activeRole > Role.User) {
        columns.push({
            field: '',
            type: 'actions',
            flex: 0,
            maxWidth: 100,
            disableColumnMenu: true,
            sortable: false,
            align: 'right',
            renderCell: ({id}) => <TableRowMenu menuItems={getMenuItems(id as number)} />,
        });
    }

    return (
        <>
            <DataGrid
                columns={columns}
                rows={getRows()}
                loading={isLoading}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer sx={{justifyContent: 'space-between'}}>
                            <Box sx={{display: 'flex', '& > *': {mr: 1.5}}}>
                                <GridToolbarFilterButton />
                                <GridToolbarDensitySelector />
                                <ButtonWithImportOptions
                                    handleProductImport={handleImportProducts}
                                    handlePackagingImport={handleImportPackaging}
                                />
                                <ButtonWithMenu
                                    buttonText={getLangString('EXPORT')}
                                    buttonProps={{
                                        startIcon: <OutputRounded />,
                                        endIcon: <ArrowDropDown />,
                                        size: 'small',
                                    }}
                                    menuItems={[
                                        {name: getLangString('EXPORT_PRODUCTS'), onClick: handleExportProducts},
                                        {name: getLangString('EXPORT_PACKAGING'), onClick: handleExportPackaging},
                                    ]}
                                />
                            </Box>
                        </GridToolbarContainer>
                    ),
                }}
            />
            <DialogDelete
                isOpen={!!productToBeDeleted}
                headerText={getLangString('DELETE_PRODUCT')}
                bodyText={getLangString('DELETE_PRODUCT_CONFIRMATION')}
                onCancel={clearProductDeletion}
                onDelete={handleDeleteProduct}
            />
        </>
    );
};
