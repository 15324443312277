import {useQuery} from '@tanstack/react-query';
import {getKeyFigures, getKeyFiguresByProductGroup, getKeyFiguresByProductType} from '../handlers/keyFigures';
import {getYearlyReport, getYearlyReportByProductGroup, getYearlyReportByProductType} from '../handlers/yearlyReport';

export const useGetKeyFigures = (companyId: number, enabled: boolean) => {
    return useQuery(['keyFigures'], () => getKeyFigures(companyId), {enabled});
};
export const useGetKeyFiguresByType = (companyId: number, productTypeId: number, enabled: boolean) => {
    return useQuery(['keyFiguresByType', productTypeId], () => getKeyFiguresByProductType(productTypeId, companyId), {
        enabled,
    });
};
export const useGetKeyFiguresByGroup = (companyId: number, productGroupId: number, enabled: boolean) => {
    return useQuery(
        ['keyFiguresByGroup', productGroupId],
        () => getKeyFiguresByProductGroup(productGroupId, companyId),
        {
            enabled,
        },
    );
};

export const useGetAnnualReport = (companyId: number, enabled: boolean) => {
    return useQuery(['annualReports'], () => getYearlyReport(companyId), {enabled});
};
export const useGetAnnualReportByType = (companyId: number, productTypeId: number, enabled: boolean) => {
    return useQuery(
        ['annualReportsByType', productTypeId],
        () => getYearlyReportByProductType(productTypeId, companyId),
        {
            enabled,
        },
    );
};
export const useGetAnnualReportByGroup = (companyId: number, productGroupId: number, enabled: boolean) => {
    return useQuery(
        ['annualReportsByGroup', productGroupId],
        () => getYearlyReportByProductGroup(productGroupId, companyId),
        {
            enabled,
        },
    );
};
