import {Role} from '../models/user/Role';
import {LocalStore} from './LocalStore';

export interface UserStoreConfig {
    onBoardedForRole: Role[];
    lastActiveRole: Role;
}

export interface UserInitialConfig extends UserStoreConfig {
    initDate: Date | string;
}

type All<T> = T & UserInitialConfig;

/**
 * A storage wrapper for storing user related data to local storage.
 *
 * The store is initiated with the a user ID.
 * Each time a store is initiated, a cleanup method is performed to remove old data.
 * The default is 14 days, but can be configured through the constructor.
 */
export class UserLocalStore<T extends UserStoreConfig> extends LocalStore<string, T> {
    private _msToDaysConstant = 1000 * 3600 * 24; // MS to days
    private _storeTime = 14; // how many days the item is stored in storage
    private _identifier = '@OID-';
    private _userId = '';

    constructor(userId: string, defaultConfig: All<T>, storageDays?: number) {
        super();
        userId = this._identifier + userId;
        this._userId = userId;
        if (storageDays && storageDays >= 0) this._storeTime = storageDays;
        this.cleanStore();
        this.initStore({...defaultConfig});
    }

    private initStore(defaultConfig: All<T>) {
        if (!this.contains(this._userId)) {
            super.set(this._userId, defaultConfig);
        } else {
            const data = this.getUserData() as All<T>;
            if (data) {
                data.initDate = new Date();
                super.set(this._userId, data);
            }
        }
    }

    /**
     * Sets the provided data for the user ID.
     * This will overwrite existing data for that user id.
     *
     * @param userId the user id to set data on
     * @param data data to set
     */
    public override set(userId: string, data: T) {
        super.set(this._identifier + userId, data);
    }

    /**
     * Updates the field with new data for the
     * user owning the store (saved user id when store was initiated).
     * @param field field to update
     * @param data data to update with
     */
    public update(field: keyof T, data: T[typeof field]) {
        const s = super.get(this._userId);
        if (s) {
            s[field] = data;
            super.set(this._userId, s);
        }
    }

    /**
     * Clears the store for entries that is older than X days.
     */
    private cleanStore() {
        const itemsInStore = this.count();
        for (let i = 0; i < itemsInStore; i++) {
            const entryKey = localStorage.key(i);
            if (entryKey && entryKey.startsWith(this._identifier)) {
                const entry = this.get(entryKey) as All<T>;

                if (!entry) return;

                const time = (new Date().getTime() - new Date(entry.initDate).getTime()) / this._msToDaysConstant;

                if (time >= this._storeTime) {
                    this.remove(entryKey);
                }
            }
        }
    }

    /**
     * Returns the data for the user id that the store was instantiated with
     * @returns data for the user id the store was instantiated with
     */
    public getUserData() {
        return this.get(this._userId) as T;
    }
}
