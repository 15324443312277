import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {useSnackBar} from '../../components/SnackBarProvider';
import {MixedRequestError} from '../../utils/useProblemDetailsError';
import {UpdateProductDto} from '../dtos/productDto';
import {
    createCopyOfProduct,
    createProduct,
    deleteProduct,
    getProductById,
    getProducts,
    updateProduct,
} from '../handlers/product';
import {createProductConfig, getProductConfigs} from '../handlers/productConfig';
import {
    createProductGroup,
    createProductGroupType,
    deleteProductGroup,
    deleteProductGroupType,
    getProductGroups,
    getProductGroupTypes,
    updateProductGroup,
    updateProductGroupType,
} from '../handlers/productGroup';

export const useGetProduct = (productId: number) => {
    return useQuery(['product', productId], () => getProductById(productId));
};
export const useGetProducts = (companyId: number) => {
    return useQuery(['products'], () => getProducts(companyId), {enabled: companyId > 0});
};

export const useGetProductConfigs = () => {
    return useQuery(['productConfigs'], getProductConfigs);
};

export const useCreateProduct = () => {
    //const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createProduct, {
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeleteProduct = () => {
    const client = useQueryClient();
    return useMutation(deleteProduct, {
        onSuccess: () => {
            client.invalidateQueries(['products']);
        },
    });
};
export const useCreateCopyOfProduct = () => {
    return useMutation(createCopyOfProduct);
};
export const useCreateProductConfig = () => {
    //const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createProductConfig, {
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};

export const useUpdateProduct = () => {
    const client = useQueryClient();
    return useMutation((data: {dto: UpdateProductDto; productId: number}) => updateProduct(data.productId, data.dto), {
        onSuccess: (res, variables) => {
            client.invalidateQueries(['product', variables.productId]);
        },
    });
};

export const useGetProductGroups = (companyId: number) => {
    return useQuery(['productGroups'], () => getProductGroups(companyId), {enabled: companyId > 0});
};
export const useUpdateProductGroup = () => {
    const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(updateProductGroup, {
        onSuccess: (response, variables) => {
            client.invalidateQueries(['productGroups']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useCreateProductGroup = () => {
    const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createProductGroup, {
        onSuccess: (response, variables) => {
            client.invalidateQueries(['productGroups']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeleteProductGroup = () => {
    const client = useQueryClient();
    return useMutation(deleteProductGroup, {
        onSuccess: (response, variables) => {
            client.invalidateQueries(['productGroups']);
        },
    });
};

export const useGetProductGroupTypes = (companyId: number) => {
    return useQuery(['productGroupTypes'], () => getProductGroupTypes(companyId), {enabled: companyId > 0});
};

export const useCreateProductGroupType = () => {
    const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createProductGroupType, {
        onSuccess: () => {
            client.invalidateQueries(['productGroupTypes']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useUpdateProductGroupType = () => {
    const client = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(updateProductGroupType, {
        onSuccess: () => {
            client.invalidateQueries(['productGroupTypes']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeleteProductGroupType = () => {
    const client = useQueryClient();
    return useMutation<AxiosResponse, MixedRequestError, number, unknown>(deleteProductGroupType, {
        onSuccess: () => {
            client.invalidateQueries(['productGroupTypes']);
        },
    });
};
