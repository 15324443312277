export enum VolumeUnit {
    NOK = 1,
    Quantity = 2,
}

export type VolumeDto = {
    volumeId: number;
    productConfigId: number;
    salesVolume: number;
    wasteVolume: number;
    unit: VolumeUnit;
    fromPeriod: Date;
    toPeriod: Date;
    createdAt: Date;
    updatedAt: Date;
};

export type ProductVolumeDto = {
    productId: number;
    internalId: string;
    productName: string;
    productGroupName: string;
    companyId: number;
    productConfig: {
        productConfigId: number;
        validFrom: Date;
        validTo: Date;
        volume: VolumeDto;
    };
};

export type UpdateVolumeDto = {
    productConfigId: number;
    salesVolume: number | undefined;
    wasteVolume: number | undefined;
    unit: VolumeUnit;
    fromPeriod: string;
    toPeriod: string;
};
