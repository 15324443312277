import {Box, Typography} from '@mui/material';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {useMemo} from 'react';
import {ReportsData, WeightedCalculationGroup} from '../../api/dtos/reportsDto';
import {getPackagingGroups} from '../../api/handlers/packaging';
import {useLanguage} from '../../language/LanguageProvider';
import {getChartColorsPackaging, getChartColorsValueChainEmission} from '../../utils/chartColors';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {CircularProgressCentered} from '../ProgressComponents';
import {StackedBarChartGroup} from '../StackedBarChart';
import {ReportTable} from './ReportTable';
import {transformToChartData} from './transformToChartData';
import {transformToTableData} from './transformToTableData';

export type ReportsContentProps = {
    headerText: string;
    data: UseQueryResult<ReportsData, unknown>;
    calcGroup: keyof WeightedCalculationGroup;
    dataType: ReportDataSet;
    onChartClick?: (id: number) => void;
};
export const ReportsContent: React.FC<ReportsContentProps> = (props) => {
    const {getLangString} = useLanguage();
    const {data, isLoading, isError} = props.data;
    const currentCompanyId = useCurrentCompanyId();

    const packaging = useQuery(['packagingGroups'], () => getPackagingGroups(currentCompanyId), {
        enabled: currentCompanyId > 0,
    });

    const chartData = useMemo(
        () => transformToChartData(data, props.calcGroup, props.dataType),
        [data, props.calcGroup, props.dataType],
    );

    const tableData = useMemo(
        () => transformToTableData(data, props.calcGroup, props.dataType),
        [data, props.calcGroup, props.dataType],
    );
    if (isLoading || packaging.isLoading) {
        return <CircularProgressCentered />;
    }
    if (isError || packaging.isError) {
        return <Box>Klarte ikke å hente data</Box>;
    }
    const tableHeaders = data.reportData.filter((d) => d.calculations.length > 0).map((kf) => kf.groupingName);

    const chartColors =
        props.dataType === 'valueChainEmissions'
            ? getChartColorsValueChainEmission()
            : getChartColorsPackaging(packaging.data);
    return (
        <>
            <Box mt={4} mb={4}>
                <Typography variant={'h3'}>{props.headerText}</Typography>
                {props.data && chartData.length > 0 ? (
                    <StackedBarChartGroup
                        chartsData={chartData}
                        onClick={props.onChartClick}
                        colorRecord={chartColors}
                    />
                ) : (
                    <Typography>Finner ingen data</Typography>
                )}
            </Box>
            <ReportTable data={tableData} groupHeaders={tableHeaders} dataType={props.dataType} />
        </>
    );
};
