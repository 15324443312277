import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useSnackBar} from '../../components/SnackBarProvider';
import {createTransport, deleteTransport, getTransports, updateTransport} from '../handlers/transport';

export const useGetTransport = (companyId:number) => {
    return useQuery(['transport'], () => getTransports(companyId), {enabled : companyId > 0});
};
export const useCreateTransport = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createTransport, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['transport']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useUpdateTransport = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(updateTransport, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['transport']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeleteTransport = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteTransport, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['transport']);
        },
    });
};
