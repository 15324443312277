export type WeightedCalculationGroup = {
    fpak: number;
    dpak: number;
    pallet: number;
    total: number;
};

export type ProductPackagingCalculationsResp = {
    packagingGroupName: string;
    packagingResults: WeightedCalculationGroup;
    emissionResults: WeightedCalculationGroup;
};
export type ValueChainEmissionsResp = {
    gwpPackaging: number;
    gwpProduct: number;
    gwpTransportation: number;
    gwpWaste: number;
};
export type ReportDataCalculationResp = {
    year: number;
    packagings: ProductPackagingCalculationsResp[];
    valueChainEmissions: ValueChainEmissionsResp;
};
export type GroupedReportDataResponse = {
    groupingId: number;
    groupingName: string;
    calculations: ReportDataCalculationResp[];
};
export type KeyFiguresResponse = {
    keyFigures: GroupedReportDataResponse[];
    maxPackagingValues: WeightedCalculationGroup;
    maxEmissionValues: WeightedCalculationGroup;
    maxValueChainValue: number;
};
export type YearlyReportResponse = {
    yearlyReports: GroupedReportDataResponse[];
    maxPackagingValues: WeightedCalculationGroup;
    maxEmissionValues: WeightedCalculationGroup;
    maxValueChainValue: number;
};

export type ReportsData = {
    reportData: GroupedReportDataResponse[];
    maxPackagingValues: WeightedCalculationGroup;
    maxEmissionValues: WeightedCalculationGroup;
    maxValueChainValue: number;
};

export enum ReportType {
    PackagingResultsFpak,
    PackagingResultsDpak,
    PackagingResultsPallet,
    PackagingResultsTotal,
    EmissionResultsFpak,
    EmissionResultsDpak,
    EmissionResultsPallet,
    EmissionResultsTotal,
    ValueChainEmissions,
}
