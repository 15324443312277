import {useMsal} from '@azure/msal-react';
import {Avatar, Box, Button, Divider, IconButton, Menu, Typography} from '@mui/material';
import React from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {CurrentAdminUser} from '../lib/models';

export const AccountMenu: React.FC<{currentUser: CurrentAdminUser | undefined}> = ({currentUser}) => {
    const {getLangString} = useLanguage();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {instance} = useMsal();

    const doHandleLogout = async () => {
        await instance.handleRedirectPromise();
        await instance.logoutRedirect();
    };
    const CustomAvatar = () => <Avatar sx={{width: 32, height: 32, background: '#dfe9e8'}} />;
    return (
        <>
            <IconButton size={'small'} onClick={handleClick}>
                <CustomAvatar />
            </IconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <Box px={2} pt={1} pb={2} minWidth={200}>
                    <Box display={'flex'} alignItems={currentUser?.email ? 'flex-start' : 'center'}>
                        <CustomAvatar />
                        <Box ml={2}>
                            <Typography variant={'body1'}>{currentUser?.displayName}</Typography>
                            <Typography variant={'body2'}>{currentUser?.email}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Button
                    fullWidth={true}
                    onClick={doHandleLogout}
                    color={'primary'}
                    sx={{justifyContent: 'flex-start', px: 2}}
                >
                    {getLangString('LOG_OUT')}
                </Button>
            </Menu>
        </>
    );
};
