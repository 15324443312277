import {Box, Button, ButtonProps} from '@mui/material';
import {FC} from 'react';
import {CircularProgressOverlay} from './ProgressComponents';

export const ButtonWithLoading: FC<
    {
        buttonProps: ButtonProps;
    } & {loading: boolean; loadingIconColor?: string; loadingBGColor?: string}
> = (props) => (
    <Box position={'relative'} display={'inline-flex'}>
        <Button {...props.buttonProps}>{props.buttonProps.children}</Button>
        {props.loading && (
            <CircularProgressOverlay iconSize={24} bgColor={props.loadingBGColor} iconColor={props.loadingIconColor} />
        )}
    </Box>
);
