import {Link, Typography} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridOverlay,
    GridRenderCellParams,
    GridRowsProp,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {Analysis} from '../../api/dtos/analysisDto';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressCentered} from '../ProgressComponents';
import { TableRowMenu } from 'components/TableRowMenu';
import { useApplicationState } from 'lib/context/applicationState';
import { Role } from 'lib/models';
import { useDeleteAnalysisById } from 'api/hooks/analysis';
import { useMutation } from '@tanstack/react-query';
import { createCopyOfAnalysis } from 'api/handlers/analysis';
import { DialogDelete } from 'components/DialogDelete';

type ProductRowType = {
    id: number;
    name: string;
    ownerName: string;
    date: Date;
};
export const AnalysesList: React.FC<{analyses?: Analysis[]; isLoading: boolean}> = ({analyses, isLoading}) => {
    const navigate = useNavigate();
    const {state} = useApplicationState();
    const goToAnalysisPage = (params: GridRenderCellParams) => {
        navigate('/analysis/' + params.id);
    };
    const gotToAnalysisPageAterCopy = (id: number) => {
        navigate('/analysis/' + id)
    }
    const {getLangString} = useLanguage();

    const getRows = (): GridRowsProp<ProductRowType> => {
        if (analyses) {
            return analyses.map((a) => ({
                id: a.analysisId,
                name: a.analysisName,
                ownerName: a.ownerName ? a.ownerName : 'Testuser',
                date: a.createdAt,
            }));
        }
        return [];
    };

    const doCreateCopy = useMutation(createCopyOfAnalysis);
    const handleCreateCopy = (analysisId: number) => {
        doCreateCopy.mutateAsync(analysisId).then((analysisIdOfCopy) => gotToAnalysisPageAterCopy(analysisIdOfCopy)
        )
    }

    const [analysisToBeDeleted, setAnalysisToBeDeleted] = useState<number | undefined>();
    const clearAnalysisDeletion = () => setAnalysisToBeDeleted(undefined);
    const doDeleteAnalysis = useDeleteAnalysisById();
    const handleRemoveAnalysis = async () => {      
        if(analysisToBeDeleted){
           await doDeleteAnalysis.mutateAsync(analysisToBeDeleted);
           clearAnalysisDeletion();
        }
    } 

    const getMenuItems = (id: number) => {
        const items = [
            {
                name: getLangString('CREATE_ANALYSIS_COPY'),
                onClick: () => handleCreateCopy(id)
            },
            {
                name: getLangString('DELETE_ANALYSIS'),
                onClick: () => setAnalysisToBeDeleted(id)
            },
        ];
        return items
    }
    
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: getLangString('ANALYSIS_NAME'),
            flex: 1,
            renderCell: (params) => (
                <Link
                    component={'button'}
                    onClick={() => goToAnalysisPage(params)}
                    sx={{
                        fontSize: '0.875rem',
                        textDecoration: 'none',
                        flex: 1,
                        textAlign: 'start',
                        height: '100%',
                        '&:hover': {textDecoration: 'underline'},
                    }}
                >
                    {params.value}
                </Link>
            ),
        },
        {field: 'ownerName', headerName: getLangString('CREATED_BY'), flex: 1},
        {
            field: 'date',
            headerName: getLangString('DATE'),
            align: 'right',
            renderCell: (params) => new Date(params.value).toLocaleDateString(),
        },
    ];

    if (state.activeRole > Role.User)
    columns.push({
        field: '',
        type: 'actions',
        flex: 0,
        maxWidth: 100,
        disableColumnMenu: true,
        sortable: false,
        align: 'right',
        renderCell: ({id}) => <TableRowMenu menuItems={getMenuItems(id as number)} />,
    });

    return (
        <>
        <DataGrid
            columns={columns}
            rows={getRows()}
            loading={isLoading}
            components={{
                Toolbar: () => (
                    <GridToolbarContainer>
                        <GridToolbarFilterButton />
                    </GridToolbarContainer>
                ),

                NoRowsOverlay: () => (
                    <GridOverlay>
                        <Typography>{'Ingen analyser opprettet enda'}</Typography>
                    </GridOverlay>
                ),
                LoadingOverlay: () => (
                    <GridOverlay>
                        <CircularProgressCentered />
                    </GridOverlay>
                ),
            }}
        />
        <DialogDelete
            isOpen={!!analysisToBeDeleted}
            headerText={getLangString('DELETE_ANALYSIS')}
            bodyText={getLangString('DELETE_ANALYSIS_CONFIRMATION')}
            onCancel={clearAnalysisDeletion}
            onDelete={handleRemoveAnalysis}
        />
        </>
    );
};
