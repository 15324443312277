import {Add, Check, Close} from '@mui/icons-material';
import {Button, IconButton} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import {AxiosPromise} from 'axios';
import React, {useEffect, useState} from 'react';
import {PackagingHandlingCapability} from '../../api/dtos/productConfigDto';
import {useLanguage} from '../../language/LanguageProvider';
import {usePackagingHandlingCapabilityText} from '../../language/usePackagingHandlingCapabilityText';
import {MixedRequestError, useProblemDetailsError} from '../../utils/useProblemDetailsError';
import {CustomAlertDialog} from '../CustomAlertDialog';
import {DialogDelete} from '../DialogDelete';
import {useSnackBar} from '../SnackBarProvider';
import {TableRowMenu} from '../TableRowMenu';
import {AddGWPValuesFormFields, DialogAddGWPItem} from './DialogAddGWPItem';

export type GWPTableRowProps = {
    id: number;
    name: string;
    GWP: string;
    comment: string;
    handlingCapability?: string;
    rGWP?: string;
    trip?: string;
    primaryGroup?: string;
};
type Props = {
    tableData: GridRowsProp<GWPTableRowProps>;
    onRowUpdate: (newRow: GridRowModel<GWPTableRowProps>) => void;
    onCreate: (newRow: AddGWPValuesFormFields) => void;
    onRowDelete: (id: number) => AxiosPromise;
    rowCreateButtonText: string;
    includeTrip?: boolean;
    includeRGWP?: boolean;
    primaryGroupOptions?: Record<number, string>;
};

export const GWPTable: React.FC<Props> = (props) => {
    const {getLangString} = useLanguage();
    const [rows, setRows] = useState<GridRowsProp<GWPTableRowProps>>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const [deletingRow, setDeletingRow] = useState<number | undefined>();
    const {addSnackBar} = useSnackBar();

    useEffect(() => {
        setRows(props.tableData);
    }, [props.tableData]);

    const {translatedError, translate, clearTranslatedError} = useProblemDetailsError({
        ILLEGAL_DELETE_ENTITY_HAS_CHILDREN: getLangString('ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN'),
    });

    const handleEditCancel = (id: number) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}});
    };
    const handleSave = (id: number) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };
    const handleDelete = async () => {
        if (deletingRow) {
            try {
                await props.onRowDelete(deletingRow);
                setDeletingRow(undefined);
            } catch (e) {
                translate(e as MixedRequestError);
            }
        }
    };
    const dismissAlertDialog = () => {
        setDeletingRow(undefined);
        clearTranslatedError();
    };

    const getMenuItems = (id: number) => [
        {
            name: getLangString('EDIT'),
            onClick: () => {
                setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
            },
        },
        {
            name: getLangString('DELETE'),
            onClick: () => setDeletingRow(id),
        },
    ];
    const {getPackagingHandlingCapabilityText, getPackagingHandlingOptions} = usePackagingHandlingCapabilityText();
    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const columnDefs: (GridColDef | undefined)[] = [
        {field: 'name', headerName: getLangString('NAME'), flex: 1, editable: true},
        props.primaryGroupOptions
            ? {
                  field: 'primaryGroup',
                  headerName: getLangString('PRIMARY_GROUP'),
                  type: 'singleSelect',
                  renderCell: ({value}) => (value ? props.primaryGroupOptions![value] : 'Velg hovedgruppe'),
                  valueOptions: Object.keys(props.primaryGroupOptions).map((option) => ({
                      value: option,
                      label: props.primaryGroupOptions![Number(option)],
                  })),
                  flex: 1,
                  minWidth: 200,
                  editable: true,
              }
            : undefined,
        {field: 'GWP', headerName: getLangString('GWP'), type: 'number', align: 'right', editable: true},
        props.includeRGWP
            ? {field: 'rGWP', headerName: getLangString('RGWP'), type: 'number', align: 'right', editable: true}
            : undefined,
        props.includeTrip
            ? {field: 'trip', headerName: getLangString('TRIP'), type: 'number', align: 'right', editable: true}
            : undefined,
        props.includeTrip
            ? {
                  field: 'handlingCapability',
                  headerName: getLangString('PACKAGING_HANDLING_CAPABILITY'),
                  editable: true,
                  type: 'singleSelect',
                  renderCell: ({value}) =>
                      getPackagingHandlingCapabilityText(value as PackagingHandlingCapability) ||
                      getLangString('SELECT_PACKAGING_HANDLING_CAPABILITY'),
                  valueOptions: getPackagingHandlingOptions().map((option) => ({
                      value: option,
                      label: getPackagingHandlingCapabilityText(option as PackagingHandlingCapability),
                  })),
                  minWidth: 300,
              }
            : undefined,
        {field: 'comment', headerName: getLangString('COMMENT'), flex: 1, editable: true},
        {
            field: '',
            flex: 0,
            maxWidth: 48,
            disableColumnMenu: true,
            sortable: false,
            type: 'actions',
            minWidth: 100,
            renderCell: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                return isInEditMode ? (
                    <>
                        <IconButton onClick={handleEditCancel(id as number)}>
                            <Close />
                        </IconButton>
                        <IconButton onClick={handleSave(id as number)}>
                            <Check />
                        </IconButton>
                    </>
                ) : (
                    <TableRowMenu menuItems={getMenuItems(id as number)} />
                );
            },
        },
    ];
    const columns: GridColDef[] = columnDefs.filter((colDef) => !!colDef) as GridColDef[];
    const processRowUpdate = React.useCallback(async (newRow: GridRowModel<GWPTableRowProps>) => {
        await props.onRowUpdate(newRow);
        return newRow;
    }, []);

    const handleCreate = async (formFields: AddGWPValuesFormFields) => {
        await props.onCreate(formFields);
        setOpenCreateDialog(false);
    };
    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        addSnackBar({children: error.message, severity: 'error'});
    }, []);
    return (
        <div style={{height: 580, width: '100%'}}>
            <DataGrid
                columns={columns}
                rows={rows}
                autoPageSize={false}
                rowModesModel={rowModesModel}
                disableSelectionOnClick={true}
                editMode={'row'}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <GridToolbarFilterButton />
                            <Button onClick={() => setOpenCreateDialog(true)} startIcon={<Add />}>
                                {props.rowCreateButtonText}
                            </Button>
                        </GridToolbarContainer>
                    ),
                }}
                hideFooter={true}
                experimentalFeatures={{newEditingApi: true}}
            />
            <DialogDelete
                isOpen={!!deletingRow}
                headerText={'Slette gruppen'}
                bodyText={'Ønsker du å slette denne grupper?'}
                onCancel={() => setDeletingRow(undefined)}
                onDelete={handleDelete}
            />
            <CustomAlertDialog
                isOpen={!!translatedError}
                headerText={getLangString('ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN_HEADER')}
                bodyText={translatedError!}
                onClose={dismissAlertDialog}
            />
            <DialogAddGWPItem
                isOpen={openCreateDialog}
                includeRGWP={props.includeRGWP}
                includeTrip={props.includeTrip}
                primaryGroupOptions={props.primaryGroupOptions}
                handleClose={() => setOpenCreateDialog(false)}
                handleCreate={handleCreate}
                headerText={props.rowCreateButtonText}
            />
        </div>
    );
};
