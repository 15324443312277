import {MoreHoriz} from '@mui/icons-material';
import {IconButton, Menu, MenuItem} from '@mui/material';
import React from 'react';

type MenuProps = {
    menuItems: {
        name: string;
        onClick: () => void;
    }[];
};
export const TableRowMenu: React.FC<MenuProps> = ({menuItems}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreHoriz />
            </IconButton>
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.name}
                        onClick={() => {
                            item.onClick();
                            handleClose();
                        }}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
