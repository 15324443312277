import {useGetCurrentUser} from 'api/hooks/users';
import {useApplicationState} from 'lib/context/applicationState';
import {useEffect, useState} from 'react';

/**
 * Responsible for getting the current authenticated user.
 * When the user is fetched, app state is updated with the info
 * and the done state is updated.
 * @returns completion status
 */
export const useCurrentUser = () => {
    const {state, dispatch} = useApplicationState();
    /**
     * Flag to indicate that the user is done loading
     */
    const [done, setDone] = useState(false);
    const {data: me, refetch: getMe} = useGetCurrentUser();

    /**
     * Effect for handling fetching of the current user.
     */
    useEffect(() => {
        if (state.authenticated && !me) {
            setDone(false);
            getMe();
        }
    }, [getMe, me, state.authenticated]);

    /**
     * Effect for handling user fetched and setting the application state
     * when the user fetching is done
     */
    useEffect(() => {
        if (!me) return;
        const user = me;
        dispatch({
            type: 'setCurrentUser',
            payload: {
                userId: user.userId,
                displayName: user.displayName,
                userRoles: user.userRoles,
                companies: user.companies,
                email: user.email,
            },
        });
        setDone(true);
    }, [dispatch, me]);

    return {done};
};
