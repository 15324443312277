import {Add, Check, Close, Edit, ExpandMore} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useApplicationState} from 'lib/context/applicationState';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import {Role} from 'lib/models';
import {FC, PropsWithChildren, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ProductConfig} from '../../api/dtos/productConfigDto';
import {getProductById} from '../../api/handlers/product';
import {deleteProductConfig, getProductConfigById, updateProductConfig} from '../../api/handlers/productConfig';
import {useCreateProductConfig, useGetProductGroups, useUpdateProduct} from '../../api/hooks/product';
import {useLanguage} from '../../language/LanguageProvider';
import {createDateWithoutTimezone} from '../../utils/createDateWithoutTimezone';
import {BackButton} from '../BackButton';
import {DialogDelete} from '../DialogDelete';
import {EditableText} from '../EditableText';
import {CircularProgressCentered} from '../ProgressComponents';
import {CreateProductConfigFormFields, DialogCreateProductConfig} from '../registry/DialogCreateProductConfig';
import {EditProductForm} from '../registry/EditProductForm';
import {ProductConfigMaterialsTable} from '../registry/ProductConfigMaterialsTable';
import {useSnackBar} from '../SnackBarProvider';

const ItemWithEditOnHover: FC<PropsWithChildren<{onEditClick: () => void; allowEdit: boolean}>> = ({
    children,
    onEditClick,
    allowEdit,
}) => (
    <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
            '& button': {
                visibility: 'hidden',
            },
            '&:hover': {
                '& button': {visibility: 'visible'},
            },
        }}
    >
        {children}
        {allowEdit && (
            <IconButton onClick={onEditClick} size={'medium'} sx={{m: 0.5}}>
                <Edit fontSize={'small'} />
            </IconButton>
        )}
    </Box>
);

export type UpdateProductFormFields = {
    productId: number;
    transportId: number;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    validFrom: Date;
    validTo?: Date;
    comment: string;
};
const ProductConfigContent: FC<{productConfig: ProductConfig; isLastConfig?: boolean}> = ({
    productConfig,
    isLastConfig,
}) => {
    const {getLangString} = useLanguage();
    const {state} = useApplicationState();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const setEditStart = () => setIsEditing(true);
    const setEditDone = () => setIsEditing(false);
    const {data, isLoading, isError} = useQuery(['productConfig', productConfig.productConfigId], () =>
        getProductConfigById(productConfig.productConfigId),
    );
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();

    const [isDeleting, setIsDeleting] = useState(false);
    const updateProduct = useMutation(updateProductConfig, {
        onSuccess: () => {
            queryClient.invalidateQueries(['productConfig', productConfig.productConfigId]);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });

    const deleteConfig = useMutation(deleteProductConfig, {
        onSuccess: () => {
            queryClient.invalidateQueries(['product']);
            if (isLastConfig) {
                navigate('/registry', {replace: true});
            }
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const handleDeleteConfig = () => {
        deleteConfig.mutate(productConfig.productConfigId);
        setIsDeleting(false);
    };

    const handleEditSave = (form: UpdateProductFormFields) => {
        const fromYear = form.validFrom.getFullYear();
        const toYear = form.validTo && form.validTo.getFullYear();
        updateProduct
            .mutateAsync({
                productConfigId: productConfig.productConfigId,
                data: {
                    ...form,
                    validFrom: createDateWithoutTimezone(fromYear, 1, 1),
                    validTo: toYear ? createDateWithoutTimezone(toYear, 12, 31) : undefined,
                },
            })
            .then(() => {
                setEditDone();
                queryClient.invalidateQueries(['product']);
            });
    };
    if (isLoading) {
        return <CircularProgressCentered />;
    }
    if (isError) {
        return <Box>Fant ikke produktversjonen</Box>; //TODO: add better error message
    }
    return (
        <Box>
            <Box sx={{background: 'rgba(19,111,99,0.05)', px: 2, pt: 1, pb: 3, borderRadius: 1}}>
                {!isEditing ? (
                    <>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                            <Box mt={2} maxWidth={400} flex={1}>
                                <Typography variant={'subtitle1'}>Volum</Typography>
                                <Box my={1} display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant={'body1'}>
                                        {getLangString('PRODUCT_NET_WEIGHT_FPAK')}:
                                    </Typography>
                                    <Typography variant={'body1'}>{data.netWeightGrams}</Typography>
                                </Box>
                                <Box my={1} display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant={'body1'}>{getLangString('FPAK_DPAK_AMOUNT')}:</Typography>
                                    <Typography variant={'body1'}>{data.fpakDpak}</Typography>
                                </Box>
                                <Box my={1} display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant={'body1'}>{getLangString('DPAK_PALLET_AMOUNT')}:</Typography>
                                    <Typography variant={'body1'}>{data.dpakPallet}</Typography>
                                </Box>
                            </Box>
                            {state.activeRole > Role.User && (
                                <Button onClick={setEditStart}>{getLangString('EDIT')}</Button>
                            )}
                        </Box>
                        <Box mt={3}>
                            <Typography my={1} variant={'subtitle1'}>
                                {getLangString('TRANSPORT')}
                            </Typography>
                            <Typography variant={'body1'}>{data.transport.transportName}</Typography>
                        </Box>
                        <Box mt={3} maxWidth={400}>
                            <Typography my={1} variant={'subtitle1'}>
                                {'Produksjonsår'}
                            </Typography>
                            <Box>
                                <Typography component={'span'}>
                                    {new Date(data.validFrom).getFullYear()} - {''}
                                </Typography>
                                {data.validTo && (
                                    <Typography component={'span'}>{new Date(data.validTo).getFullYear()}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <Typography my={1} variant={'subtitle1'}>
                                {getLangString('COMMENT')}
                            </Typography>
                            <Typography variant={'body1'}>{data.comment}</Typography>
                        </Box>
                    </>
                ) : (
                    <EditProductForm data={data} handleCancel={setEditDone} handleSave={handleEditSave} />
                )}
            </Box>
            <Box>
                <Typography variant={'h4'} mt={3} mb={1}>
                    {getLangString('PACKAGING')}
                </Typography>
                <ProductConfigMaterialsTable
                    productPackagings={data.productPackagings || []}
                    productConfigId={productConfig.productConfigId}
                />
            </Box>

            {state.activeRole > Role.User && (
                <Button variant={'outlined'} color={'error'} onClick={() => setIsDeleting(true)} sx={{mt: 1}}>
                    {getLangString('DELETE_PRODUCT_CONFIG')}
                </Button>
            )}
            <DialogDelete
                isOpen={isDeleting}
                headerText={'Slette versjonen'}
                bodyText={'Ønsker du å slette denne versjonen? Dette kan ikke angres'}
                onCancel={() => setIsDeleting(false)}
                onDelete={handleDeleteConfig}
            />
        </Box>
    );
};

export const ProductPage: FC = () => {
    const {prodID} = useParams();
    const {state} = useApplicationState();
    const {getLangString} = useLanguage();
    const productID = parseInt(prodID!);
    const queryClient = useQueryClient();
    const currentCompanyId = useCurrentCompanyId();

    const {data, isLoading, isError} = useQuery(['product', productID], () => getProductById(productID));
    const productGroups = useGetProductGroups(currentCompanyId);

    const [isCreatingConfig, setIsCreatingConfig] = useState(false);
    const createProductConfig = useCreateProductConfig();

    const [isEditingProductName, setIsEditingProductName] = useState(false);
    const [isEditingProductGroup, setIsEditingProductGroup] = useState(false);
    const updateProduct = useUpdateProduct();
    const [newGroupValue, setNewGroupValue] = useState<number>();
    const handleChange = (event: SelectChangeEvent<number>) => {
        setNewGroupValue(event.target.value as number);
    };

    if (isLoading) {
        return <CircularProgressCentered />;
    }
    if (isError) {
        return <Box>Produkt ikke funnet</Box>; //TODO: add better error message
    }

    const doCreateProductConfig = (form: CreateProductConfigFormFields) => {
        createProductConfig
            .mutateAsync({
                ...form,
                productId: productID,
            })
            .then((resp) => {
                queryClient.invalidateQueries(['product', productID]);
                setIsCreatingConfig(false);
            });
    };
    const doUpdateProductName = (newName: string) => {
        updateProduct
            .mutateAsync({
                dto: {
                    productGroupId: data.productGroupId,
                    productName: newName,
                    companyId: currentCompanyId,
                },
                productId: productID,
            })
            .then(() => setIsEditingProductName(false));
    };

    const doUpdateProductGroup = () => {
        updateProduct
            .mutateAsync({
                dto: {
                    productGroupId: newGroupValue!,
                    productName: data.productName,
                    companyId: currentCompanyId,
                },
                productId: productID,
            })
            .then(() => setIsEditingProductGroup(false));
    };
    const currentConfig = data.currentProductConfig;
    const otherConfigs = data.productConfigs.filter((pc) => pc.productConfigId !== currentConfig.productConfigId);

    return (
        <Box mt={2} mb={8}>
            <BackButton text={getLangString('PRODUCT_LIST')} />
            <Box mt={2}>
                <Grid container spacing={3}>
                    <Grid item sm={12} lg={8}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Box>
                                {isEditingProductName ? (
                                    <EditableText
                                        value={data.productName}
                                        onCancel={() => setIsEditingProductName(false)}
                                        onSave={doUpdateProductName}
                                        textStyleOverrides={{
                                            sx: (theme) => ({
                                                '& input': {
                                                    ...theme.typography.h2,
                                                },
                                            }),
                                            variant: 'standard',
                                        }}
                                    />
                                ) : (
                                    <ItemWithEditOnHover
                                        onEditClick={() => setIsEditingProductName(true)}
                                        allowEdit={state.activeRole > Role.User}
                                    >
                                        <Typography variant={'h2'} mr={2}>
                                            {data.productName}
                                        </Typography>
                                    </ItemWithEditOnHover>
                                )}
                                {isEditingProductGroup ? (
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Select
                                            defaultValue={data.productGroupId}
                                            value={newGroupValue || data.productGroupId}
                                            onChange={handleChange}
                                            sx={{'& .MuiOutlinedInput-input': {py: 1}}}
                                        >
                                            {(productGroups.data || []).map((group) => (
                                                <MenuItem key={group.productGroupId} value={group.productGroupId}>
                                                    {group.productGroupName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Box>
                                            <IconButton onClick={() => setIsEditingProductGroup(false)}>
                                                <Close />
                                            </IconButton>
                                            <IconButton onClick={doUpdateProductGroup}>
                                                <Check />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) : (
                                    <ItemWithEditOnHover
                                        onEditClick={() => setIsEditingProductGroup(true)}
                                        allowEdit={state.activeRole > Role.User}
                                    >
                                        <Typography variant={'subtitle1'}>{data.productGroupName}</Typography>
                                    </ItemWithEditOnHover>
                                )}
                            </Box>
                            {state.activeRole > Role.User && (
                                <Box>
                                    <Button onClick={() => setIsCreatingConfig(true)} startIcon={<Add />}>
                                        {getLangString('CREATE_PRODUCT_VERSION')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={8}>
                        <ProductConfigContent
                            productConfig={data.currentProductConfig}
                            isLastConfig={data.productConfigs.length === 1}
                        />
                    </Grid>
                    <Grid item sm={12} lg={8} flexDirection={'column'}>
                        {otherConfigs.length > 0 && (
                            <Typography variant={'h3'} mt={2} mb={2}>
                                {getLangString('OLDER_VERSIONS')}
                            </Typography>
                        )}
                        {otherConfigs.map((config) => {
                            return (
                                <Accordion key={config.productConfigId}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>{`${data.productName} ${new Date(
                                        config.validFrom,
                                    ).getFullYear()} - ${
                                        config.validTo ? new Date(config.validTo).getFullYear() : ''
                                    }`}</AccordionSummary>
                                    <AccordionDetails>
                                        <ProductConfigContent productConfig={config} />
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Grid>
                </Grid>
            </Box>
            {currentConfig && (
                <DialogCreateProductConfig
                    productId={data.productId}
                    productName={data.productName}
                    productGroupName={data.productGroupName}
                    isOpen={isCreatingConfig}
                    initialValues={{
                        ...currentConfig,
                        transportId: currentConfig.transportId,
                    }}
                    inProgress={createProductConfig.isLoading}
                    handleCreate={doCreateProductConfig}
                    handleClose={() => setIsCreatingConfig(false)}
                />
            )}
        </Box>
    );
};
