import {PackagingGroup} from '../api/dtos/packagingDto';
import {valueChainEmissionText} from '../components/reports/transformToChartData';

export type ChartColors = Record<string, string>;

const colors: string[] = [
    '#006979',
    '#1697a8',
    '#a4dc8a',
    '#d6c300',
    '#f2a31b',
    '#51d2bc',
    '#7da4eb',
    '#282d7a',
    '#3939d0',
    '#ff6351',
    '#d17393',
    '#cd34b5',
    '#9d02d7',
    '#7e6dcb',
    '#9db097',
    '#0ee39e',
    '#0bfa15',
    '#e96cf4',
    '#2c1b0a',
    '#fc072e',
    '#15aafc',
    '#055640',
    '#22d5eb',
];

export const getChartColorsPackaging = (packagingGroups: PackagingGroup[]): ChartColors => {
    return packagingGroups.reduce((acc, curr, i) => {
        return {...acc, [curr.packagingGroupName]: colors[i]};
    }, {});
};

export const getChartColorsValueChainEmission = (): ChartColors => {
    return Object.values(valueChainEmissionText).reduce((acc, curr, i) => {
        return {...acc, [curr]: colors[i]};
    }, {});
};
