import {axiosInstance} from 'api/axiosInstance';
import {useB2cAuthentication} from 'api/hooks/useB2cAuthentication';
import {authHeaderInterceptor} from 'api/interceptors/AuthHeaderInterceptor';
import {msalInstance} from 'index';
import {useApplicationState} from 'lib/context/applicationState';
import {useCurrentCompany} from 'lib/hooks/useCurrentCompany';
import {useUserLocalStore} from 'lib/hooks/useUserLocalStore';
import {Role} from 'lib/models';
import {ReactNode, useEffect, useState} from 'react';
import {APP_CONFIG} from '../../lib/config/AppConfig';
import {useCurrentUser} from './useCurrentUser';

const validRoles = [Role.User, Role.CompanyAdmin, Role.SuperUser];

export const AppInitializer = ({children}: {children: ReactNode}) => {
    const {state, dispatch} = useApplicationState();
    const {token, done: authenticationDone} = useB2cAuthentication({
        forgotPasswordAuthority: APP_CONFIG.b2c.authorities.forgotPassword.authority,
        signInScopes: APP_CONFIG.b2c.authorities.signIn.scopes,
        signInAuthority: APP_CONFIG.b2c.authorities.signIn.authority,
    });

    const [appInitiators, setAppInitiators] = useState({
        authentication: false,
        user: false,
    });

    const {userStore} = useUserLocalStore({userId: state?.currentUser?.userId});
    const [invalidRole, setInvalidRole] = useState(false);

    const {done: userLoadingDone} = useCurrentUser();
    useCurrentCompany();

    useEffect(() => {
        if (token) {
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axiosInstance.interceptors.request.use(
                authHeaderInterceptor.request(msalInstance, axiosInstance, APP_CONFIG.b2c.authorities.signIn.scopes),
                authHeaderInterceptor.onError,
            );

            dispatch({type: 'setAuthenticated', payload: true});
        }
    }, [dispatch, token]);

    /**
     * Handler for determining the active role to use.
     *
     * Selects the highest available role the user
     * has and sets it.
     */
    useEffect(() => {
        if (state.currentUser && userStore && state.activeRole === Role.None) {
            const userRoles = state.currentUser.userRoles;
            const lastActiveRole = userStore.getUserData().lastActiveRole;
            let roleToSet = Role.None;

            // Finds the highest role
            roleToSet = userRoles
                .filter(function (r) {
                    return validRoles.includes(r.roleId);
                })
                .reduce((a: Role, b) => {
                    if (b.roleId > a) return b.roleId;
                    return a as Role;
                }, Role.None);

            if (roleToSet === undefined || roleToSet === Role.None) {
                setInvalidRole(true);
            }

            dispatch({type: 'setActiveRole', payload: roleToSet});
            userStore.update('lastActiveRole', roleToSet);
        }
    }, [dispatch, state.activeRole, state.currentUser, userStore]);

    /**
     * Handler for setting app instantiation flags.
     */
    useEffect(() => {
        // Flag authentication done
        if (authenticationDone && !appInitiators.authentication) {
            setAppInitiators({...appInitiators, authentication: true});
        }

        // Flag user loading done
        if (userLoadingDone && !appInitiators.user) {
            setAppInitiators({...appInitiators, user: true});
        }
    }, [appInitiators, authenticationDone, token, userLoadingDone]);

    // Determines if all initializers are done
    const evaluateDone = () => {
        if (appInitiators.authentication && !token) return true;
        return Object.values(appInitiators).every((a) => a === true);
    };

    return <>{authenticationDone && children}</>;
};
