import {Role} from 'lib/models';
import {useEffect, useState} from 'react';
import {UserInitialConfig, UserLocalStore, UserStoreConfig} from '../storage/UserLocalStore';

/**
 * Initial user store config
 */
const defaultConfig: UserInitialConfig = {
    initDate: new Date(),
    onBoardedForRole: [],
    lastActiveRole: Role.None,
};

interface useUserLocalStoreProps {
    userId?: string | undefined;
}

/**
 * Returns a user local store manager if there is a current user.
 * If
 * @returns user local store manager
 */
export const useUserLocalStore = <T extends UserStoreConfig>(props: useUserLocalStoreProps) => {
    const [userStore, setUserStore] = useState<UserLocalStore<T | UserStoreConfig> | undefined>();

    /**
     * Initialize the user store if there is a current user,
     * and set the user store state to that store.
     *
     * Updates if the user changes.
     */
    useEffect(() => {
        if (props.userId !== undefined) {
            const store = new UserLocalStore(props.userId, defaultConfig);
            setUserStore(store);
        }
    }, [props.userId]);

    return {userStore};
};
