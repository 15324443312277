import {Add, Check, Close} from '@mui/icons-material';
import {Button, IconButton} from '@mui/material';
import {
    DataGrid,
    GridColumns,
    GridFooterContainer,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
} from '@mui/x-data-grid';
import {useApplicationState} from 'lib/context/applicationState';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import {Role} from 'lib/models';
import React, {useEffect, useState} from 'react';
import {PackagingType, ProductPackaging, UpdateProductPackaging} from '../../api/dtos/productConfigDto';
import {useGetPackaging} from '../../api/hooks/packaging';
import {useLanguage} from '../../language/LanguageProvider';
import {CustomTableAutocomplete} from '../CustomTableAutocomplete';
import {CustomTableTextField} from '../CustomTableTextField';
import {useSnackBar} from '../SnackBarProvider';
import {TableRowMenu} from '../TableRowMenu';
type TableProps = {
    id: number;
    type: string;
    material: {id: number; label: string} | undefined;
    weight: string;
    recycled: string;
    comment: string;
    isNew: boolean;
};
export type MaterialsTableProductPackaging = Omit<
    ProductPackaging,
    'productConfigId' | 'packagingName' | 'packagingHandlingCapability'
>;

type MaterialsTableProps = {
    productPackagings: MaterialsTableProductPackaging[];
    handleCreateMaterial: (data: UpdateProductPackaging) => void;
    handleUpdateMaterial: (productPackagingId: number, data: UpdateProductPackaging) => void;
    handleDeleteMaterial?: (productPackagingId: number) => void;
};

export const MaterialsTable: React.FC<MaterialsTableProps> = ({
    productPackagings,
    handleCreateMaterial,
    handleUpdateMaterial,
    handleDeleteMaterial,
}) => {
    const {getLangString} = useLanguage();
    const {addSnackBar} = useSnackBar();

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const currentCompanyId = useCurrentCompanyId();
    const {state} = useApplicationState();
    const {data, isLoading} = useGetPackaging(currentCompanyId);

    const packagingTypeText: Record<PackagingType, string> = {
        [PackagingType.Fpak]: getLangString('FPAK'),
        [PackagingType.Dpak]: getLangString('DPAK'),
        [PackagingType.Pallet]: getLangString('PALLET'),
    };
    const [packagingNameRecord, setPackagingNameRecord] = useState<Record<number, string>>({});
    React.useEffect(() => {
        if (data) {
            const nameRecord = data.reduce((acc, curr) => {
                return {...acc, [curr.packagingId]: curr.packagingName};
            }, {});
            setPackagingNameRecord(nameRecord);
        }
    }, [productPackagings, data]);

    const [rows, setRows] = useState<GridRowsProp<TableProps>>([]);
    useEffect(() => {
        const packaging = productPackagings.map((p) => ({
            id: p.productPackagingId,
            type: String(p.packagingType),
            material: {id: p.packagingId, label: packagingNameRecord[p.packagingId]},
            weight: String(p.netWeightGrams.toFixed(2)),
            recycled: String(p.recycablePercentage.toFixed(1)),
            comment: p.comment,
            isNew: false,
        }));
        setRows(packaging);
    }, [productPackagings, packagingNameRecord]);

    const handleAddMaterial = () => {
        const id = Math.random();
        setRows((oldRows) => [
            ...oldRows,
            {id, type: '', material: undefined, weight: '', recycled: '', comment: '', isNew: true},
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'type'},
        }));
    };
    const handleEditCancel = (id: number) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}});
        const editedRow = rows.find((r) => r.id === id);
        if (editedRow && editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };
    const handleSave = (id: number) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };
    const getMenuItems = (id: number) => {
        const items = [
            {
                name: getLangString('EDIT'),
                onClick: () => {
                    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
                },
            },
        ];
        if (handleDeleteMaterial) {
            items.push({
                name: getLangString('DELETE'),
                onClick: () => handleDeleteMaterial(id),
            });
        }

        return items;
    };

    const [rowHasError, setRowHasError] = useState(false);

    const columns: GridColumns = [
        {
            field: 'type',
            headerName: getLangString('TYPE'),
            editable: true,
            type: 'singleSelect',
            valueOptions: [
                {value: PackagingType.Fpak, label: packagingTypeText[PackagingType.Fpak]},
                {value: PackagingType.Dpak, label: packagingTypeText[PackagingType.Dpak]},
                {value: PackagingType.Pallet, label: packagingTypeText[PackagingType.Pallet]},
            ],
            renderCell: ({value}) => (value ? packagingTypeText[value as PackagingType] : 'Velg'),
            preProcessEditCellProps: (params) => {
                const hasError = !params.props.value;
                setRowHasError(hasError);
                return {...params.props, error: hasError};
            },
        },
        {
            field: 'material',
            headerName: getLangString('MATERIAL'),
            renderCell: ({value}) => (value ? packagingNameRecord[value.id] : 'Velg material'),
            renderEditCell: ({id, field, value, error}) => {
                return (
                    <CustomTableAutocomplete
                        cellId={id}
                        field={field}
                        value={value}
                        error={error}
                        options={data ? data.map((p) => ({id: p.packagingId, label: p.packagingName})) : []}
                    />
                );
            },
            preProcessEditCellProps: (params) => {
                const hasValue = !!params.props.value;
                setRowHasError(!hasValue);
                return {...params.props, error: !hasValue};
            },
            flex: 1,
            editable: true,
        },
        {
            field: 'weight',
            headerName: getLangString('GRAM'),
            type: 'number',
            align: 'right',
            editable: true,
            preProcessEditCellProps: async (params) => {
                const hasError = !params.props.value || params.props.value < 0;
                // await setFieldErrors({...fieldErrors, weight: hasError});
                setRowHasError(hasError);
                return {...params.props, error: hasError};
            },
            renderEditCell: ({id, field, value, error}) => {
                return (
                    <CustomTableTextField
                        type={'number'}
                        InputProps={{
                            inputProps: {min: 0, step: 0.1},
                        }}
                        value={value}
                        error={error}
                        cellId={id}
                        field={field}
                    />
                );
            },
        },
        {
            field: 'recycled',
            headerName: getLangString('RECYCLED_PERCENT'),
            type: 'number',
            align: 'right',
            editable: true,
            minWidth: 110,
            preProcessEditCellProps: (params) => {
                const hasError = params.props.value === null || params.props.value > 100 || params.props.value < 0.0;
                setRowHasError(hasError);
                return {...params.props, error: hasError};
            },
            renderEditCell: ({id, field, value, error}) => {
                return (
                    <CustomTableTextField
                        type={'number'}
                        InputProps={{
                            inputProps: {min: 0, max: 100, step: 0.1},
                        }}
                        error={error}
                        value={value}
                        cellId={id}
                        field={field}
                    />
                );
            },
        },
        {
            field: 'comment',
            headerName: 'Kommentar',
            flex: 1,
            editable: true,
            renderEditCell: ({id, field, value}) => {
                return <CustomTableTextField value={value} cellId={id} field={field} />;
            },
        },
    ];

    if (state.activeRole > Role.User) {
        columns.push({
            field: '',
            type: 'actions',
            flex: 0,
            maxWidth: 100,
            disableColumnMenu: true,
            sortable: false,
            align: 'right',
            renderCell: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                return isInEditMode ? (
                    <>
                        <IconButton onClick={handleEditCancel(id as number)}>
                            <Close />
                        </IconButton>
                        <IconButton onClick={handleSave(id as number)} disabled={rowHasError}>
                            <Check />
                        </IconButton>
                    </>
                ) : (
                    <TableRowMenu menuItems={getMenuItems(id as number)} />
                );
            },
        });
    }

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel<TableProps>) => {
        if (newRow.material) {
            const data = {
                packagingType: parseInt(newRow.type),
                packagingId: newRow.material?.id,
                netWeightGrams: parseFloat(newRow.weight),
                recycablePercentage: parseFloat(newRow.recycled),
                comment: newRow.comment,
            };
            if (!newRow.isNew) {
                await handleUpdateMaterial(newRow.id, data);
            } else {
                await handleCreateMaterial(data);
            }
        }
        return newRow;
    }, []);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        addSnackBar({children: error.message, severity: 'error'});
    }, []);
    return (
        <DataGrid
            headerHeight={40}
            disableColumnMenu={true}
            columns={columns}
            rows={rows}
            loading={isLoading}
            autoHeight={true}
            disableSelectionOnClick={true}
            editMode={'row'}
            rowModesModel={rowModesModel}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
                Footer: () =>
                    state.activeRole > Role.User ? (
                        <GridFooterContainer sx={{justifyContent: 'flex-end'}}>
                            <Button onClick={handleAddMaterial} startIcon={<Add />}>
                                {getLangString('ADD_MATERIAL')}
                            </Button>
                        </GridFooterContainer>
                    ) : (
                        <></>
                    ),
            }}
            experimentalFeatures={{newEditingApi: true}}
        />
    );
};
