import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useGetTransport} from '../../api/hooks/transports';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressOverlay} from '../ProgressComponents';
export type CreateProductConfigFormFields = {
    transportId: number;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    validFrom: string;
    comment: string;
};
type DialogProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: (form: CreateProductConfigFormFields) => void;
    productId: number;
    productName: string;
    productGroupName: string;
    initialValues: CreateProductConfigFormFields;
    inProgress?: boolean;
};
const inputStyle = {
    my: 1,
};
export const DialogCreateProductConfig: React.FC<DialogProps> = (props) => {
    const {getLangString} = useLanguage();
    const defaultValues: CreateProductConfigFormFields = props.initialValues;

    const handleCloseClick = (event: object, reason: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        props.handleClose();
    };
    const {handleSubmit, control} = useForm<CreateProductConfigFormFields>({defaultValues});

    const currentCompanyId = useCurrentCompanyId();
    const transports = useGetTransport(currentCompanyId);
    const commonRules = {required: getLangString('FIELD_IS_REQUIRED')};

    return (
        <Dialog open={props.isOpen} onClose={handleCloseClick}>
            <DialogTitle variant={'h3'} my={1}>
                {'Opprett ny variant av ' + props.productName}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(props.handleCreate)}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <TextField
                            disabled={true}
                            value={props.productName}
                            label={getLangString('NAME')}
                            sx={inputStyle}
                        />
                        <TextField
                            disabled={true}
                            value={props.productGroupName}
                            label={getLangString('PRODUCT_GROUP')}
                            sx={inputStyle}
                        />
                    </Box>
                    <Box my={2}>
                        <Controller
                            name={'netWeightGrams'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label={getLangString('NET_WEIGHT')}
                                    InputProps={{endAdornment: <InputAdornment position='end'>gram</InputAdornment>}}
                                    type={'number'}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    sx={inputStyle}
                                />
                            )}
                        />
                        <Box>
                            <Controller
                                control={control}
                                name={'fpakDpak'}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('FPAK_DPAK_AMOUNT')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{...inputStyle, mr: 1}}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={'dpakPallet'}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('DPAK_PALLET_AMOUNT')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={inputStyle}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box sx={{'& > div': {my: 2}}}>
                        <Controller
                            name={'transportId'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <FormControl fullWidth={true} sx={inputStyle} error={!!fieldState.error}>
                                    <InputLabel>{getLangString('TRANSPORT')}</InputLabel>
                                    <Select {...field}>
                                        {transports.data?.map((t) => (
                                            <MenuItem key={t.transportId} value={t.transportId}>
                                                {t.transportName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name={'validFrom'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <DatePicker
                                    {...field}
                                    views={['year']}
                                    label={'I produksjon fra år'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name={'comment'}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label={getLangString('COMMENT')}
                                    rows={3}
                                    fullWidth={true}
                                    multiline={true}
                                    sx={inputStyle}
                                />
                            )}
                        />
                    </Box>
                    <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={props.handleClose} variant={'outlined'} sx={{mr: 1}}>
                            {getLangString('CANCEL')}
                        </Button>
                        <Button type={'submit'} variant={'contained'}>
                            {getLangString('SAVE')}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            {props.inProgress && <CircularProgressOverlay />}
        </Dialog>
    );
};
