import {ExpandMore} from '@mui/icons-material';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import {FC} from 'react';
import {UpdateAnalysisProduct} from '../../api/dtos/analysisDto';
import {AnalysisProductForm, AnalysisProductFormFields} from './AnalysisProductForm';

type ProductItemProps = {
    product: UpdateAnalysisProduct;
    handleUpdate: (form: AnalysisProductFormFields) => void;
    handleRemove: (productId: string) => void;
    defaultExpanded?: boolean;
};
export const AnalysisProductItem: FC<ProductItemProps> = ({product, defaultExpanded, handleUpdate, handleRemove}) => {
    return (
        <Accordion key={product.productId} defaultExpanded={defaultExpanded}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h4'}>{product.productName}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{borderTop: '1px solid rgba(0,0,0,0.1)'}}>
                <AnalysisProductForm
                    baseProduct={product}
                    handleSave={handleUpdate}
                    handleDelete={handleRemove}
                    isNewProduct={false}
                />
            </AccordionDetails>
        </Accordion>
    );
};
