import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import React from 'react';
import {PackagingType} from '../../api/dtos/productConfigDto';
import {getProductPackaging} from '../../api/handlers/product';
import {useLanguage} from '../../language/LanguageProvider';
import {usePackagingHandlingCapabilityText} from '../../language/usePackagingHandlingCapabilityText';
import {CircularProgressCentered} from '../ProgressComponents';

export const SimplePackagingTable: React.FC<{productId: number}> = ({productId}) => {
    const {getLangString} = useLanguage();
    const packagingTypeText: Record<PackagingType, string> = {
        [PackagingType.Fpak]: getLangString('FPAK'),
        [PackagingType.Dpak]: getLangString('DPAK'),
        [PackagingType.Pallet]: getLangString('PALLET'),
    };
    const {getPackagingHandlingCapabilityText} = usePackagingHandlingCapabilityText();
    const {data, isError, isLoading} = useQuery(['productPackagings', productId], () => getProductPackaging(productId));

    if (isLoading) {
        return <CircularProgressCentered />;
    }
    if (isError) {
        return (
            <Box>
                <TableContainer>
                    <Typography variant={'h2'}>{getLangString('PACKAGING_NOT_FOUND')}</Typography>
                    <Table aria-label='material table'></Table>
                </TableContainer>
                <Divider />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant={'h2'} gutterBottom>
                {data?.productName}
            </Typography>
            <TableContainer>
                <Table aria-label='material table'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontFamily: 'inherit'}}>{getLangString('MATERIAL')}</TableCell>
                            <TableCell sx={{fontFamily: 'inherit'}}>{getLangString('TYPE')}</TableCell>
                            <TableCell sx={{fontFamily: 'inherit'}} align='right'>
                                {getLangString('NET_WEIGHT')}
                            </TableCell>
                            <TableCell sx={{fontFamily: 'inherit'}} align='right'>
                                {getLangString('RECYCLED_PERCENT')}
                            </TableCell>
                            <TableCell sx={{fontFamily: 'inherit'}}>
                                {getLangString('PACKAGING_HANDLING_CAPABILITY')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.productPackagings.map((packaging, index) => (
                            <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component='th' scope='row'>
                                    {packaging.packagingName}
                                </TableCell>
                                <TableCell>{packagingTypeText[packaging.packagingType]}</TableCell>
                                <TableCell align='right'>{packaging.netWeightGrams}</TableCell>
                                <TableCell align='right'>{packaging.recycablePercentage}</TableCell>
                                <TableCell>
                                    {getPackagingHandlingCapabilityText(packaging.packagingHandlingCapability)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider />
        </Box>
    );
};
