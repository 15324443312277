import {useMsal} from '@azure/msal-react';
import {Button} from '@mui/material';
import {LOGIN_REQUEST} from 'lib/config/AzureB2cConfig';

export const LoginButton = () => {
    const {instance, inProgress} = useMsal();

    const doHandleLogin = () => {
        if (inProgress === 'none') {
            instance.loginRedirect(LOGIN_REQUEST);
        }
    };

    return (
        <Button sx={{height: '100%', mr: 5}} onClick={doHandleLogin}>
            Logg inn
        </Button>
    );
};
