import {ReportType, WeightedCalculationGroup} from '../../api/dtos/reportsDto';
import {ReportDataSet} from '../DataTypeButtonGroup';

export const getReportType = (dataType: ReportDataSet, calcGroupValue: keyof WeightedCalculationGroup): ReportType => {
    switch (dataType) {
        case 'packagingResults':
            if (calcGroupValue === 'fpak') {
                return ReportType.PackagingResultsFpak;
            } else if (calcGroupValue === 'dpak') {
                return ReportType.PackagingResultsDpak;
            } else if (calcGroupValue === 'pallet') {
                return ReportType.PackagingResultsPallet;
            } else {
                return ReportType.PackagingResultsTotal;
            }
        case 'emissionResults':
            if (calcGroupValue === 'fpak') {
                return ReportType.EmissionResultsFpak;
            } else if (calcGroupValue === 'dpak') {
                return ReportType.EmissionResultsDpak;
            } else if (calcGroupValue === 'pallet') {
                return ReportType.EmissionResultsPallet;
            } else {
                return ReportType.EmissionResultsTotal;
            }
        default:
            return ReportType.ValueChainEmissions;
    }
};
