import {Box, SelectChangeEvent} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
    AnsweredDeclarationQuestion,
    DeclarationAttachmentResponse,
    DeclarationResponse,
    UpdateAttachmentRequest,
    UpdateDeclarationRequest,
} from 'api/dtos/declarationDto';

import {addDeclarationAttachment, getDeclarationById, updateDeclarationRequest} from 'api/handlers/declaration';
import {useSnackBar} from 'components/SnackBarProvider';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DeclarationContent} from '../declaration/DeclarationContent';
import {CircularProgressCentered} from '../ProgressComponents';

export const UpdateProductDeclarationPage: React.FC = () => {
    const {declarationId} = useParams();
    const navigate = useNavigate();
    const [files, setFiles] = useState<File[]>([]);
    const [removedAttachments, setRemovedAttachments] = useState<UpdateAttachmentRequest[]>([]);
    const [declaration, setDeclaration] = useState<DeclarationResponse>();
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();

    const updateDeclaration = useMutation(updateDeclarationRequest, {
        onSuccess: () => {
            queryClient.invalidateQueries(['declaration', declarationId]);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });

    const {data, isError, isLoading} = useQuery(['declaration', declarationId], () =>
        getDeclarationById(parseInt(declarationId!)),
    );
    useEffect(() => {
        if (data) {
            setDeclaration(data);
        }
    }, [data]);

    if (isLoading) return <CircularProgressCentered />;
    if (isError) {
        return (
            <Box display={'flex'} justifyContent={'center'}>
                Deklarasjon ikke funnet
            </Box>
        );
    }
    const navigateBack = () => {
        navigate('/declaration');
    };

    const addFile = (event: ChangeEvent<HTMLInputElement>) => {
        Array.from(event.target.files!).forEach((file) => {
            if (!files.some((attachment) => attachment == file)) setFiles([...files, file]);
        });
    };
    const removeFile = (file: File) => {
        setFiles(files.filter((f: File) => f.name != file.name));
    };
    const removeAttachment = (attachment: DeclarationAttachmentResponse) => {
        if (declaration == null) return; // XXX

        const newattachments = [...declaration.attachments];
        const newnewattachments = newattachments.filter(
            (wra: DeclarationAttachmentResponse) => wra.attachmentName != attachment.attachmentName,
        );
        setDeclaration({...declaration, attachments: newnewattachments});

        if (removedAttachments.includes(attachment)) return;
        setRemovedAttachments([...removedAttachments, attachment]);
    };

    // ----------------------------------------------------------------------------------
    const updateWasteRegulation = (event: SelectChangeEvent, id: number) => {
        if (declaration == null) return; // XXX

        const newWasteRegulationAnswers = [...declaration.wasteRegulationAnswers];
        const index = newWasteRegulationAnswers.findIndex((wra) => wra.wasteRegulationQuestionId == id);
        if (index !== -1) {
            newWasteRegulationAnswers[index].answer = event.target.value;
        }
        setDeclaration({...declaration, wasteRegulationAnswers: newWasteRegulationAnswers});
    };

    // ----------------------------------------------------------------------------------
    const onSubmit = async () => {
        if (declaration == null) return; // XXX
        const updateDeclarationRequest: UpdateDeclarationRequest = {
            removedAttachments: removedAttachments,
            wasteRegulationAnswers: getWasteRegulationAnswers(declaration.wasteRegulationAnswers),
        };

        await updateDeclaration.mutateAsync({declarationId: parseInt(declarationId!), data: updateDeclarationRequest});
        if (files.length != 0) {
            await addDeclarationAttachment(parseInt(declarationId!), getAttachments(files));
        }
        navigateBack();
    };
    const getAttachments = (files: File[]) => {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('file', file, file.name);
        });
        return formData;
    };
    const getWasteRegulationAnswers = (wasteRegulationAnswers: AnsweredDeclarationQuestion[]) => {
        return wasteRegulationAnswers.map((waste) => ({
            wasteRegulationAnswerId: waste.wasteRegulationAnswerId,
            wasteRegulationQuestionId: waste.wasteRegulationQuestionId,
            answer: waste.answer,
        }));
    };
    const declarationHasChanges = (): boolean => {
        return declaration ? getWasteRegulationAnswers(declaration.wasteRegulationAnswers).length > 0 : false;
    };

    return (
        <DeclarationContent
            productId={data.productId}
            localFiles={files}
            addLocalFiles={addFile}
            removeLocalFile={removeFile}
            declarationQuestionsWithAnswers={data.wasteRegulationAnswers}
            updateWasteRegulation={updateWasteRegulation}
            attachments={data.attachments}
            removeAttachment={removeAttachment}
            onSave={onSubmit}
            isFormValid={declarationHasChanges()}
        />
    );
};
