import {axiosInstance} from '../axiosInstance';
import {
    CreateProductDto,
    ProductPackagingsResponse,
    ProductResponse,
    ProductWithDeclarationResponse,
    UpdateProductDto,
} from '../dtos/productDto';
import {productPath} from '../paths';

export const getProducts = async (companyId: number, includeOld?: boolean) =>
    await axiosInstance
        .get<ProductResponse[]>(`${productPath.base()}?companyId=${companyId}&includeOld=${!!includeOld}`)
        .then((res) => res.data);
export const getProductById = async (productId: number) =>
    await axiosInstance.get<ProductResponse>(productPath.byId(productId)).then((res) => res.data);

export const updateProduct = async (productId: number, data: UpdateProductDto) =>
    await axiosInstance.patch<number>(productPath.byId(productId), data);

export const createProduct = async (data: CreateProductDto) =>
    await axiosInstance
        .post<{productId: number; productConfigId: number}>(productPath.base(), data)
        .then((res) => res.data);
export const createCopyOfProduct = async (productId: number) =>
    await axiosInstance.post<{productId: number}>(`${productPath.byId(productId)}/Copy`).then((res) => res.data);
export const deleteProduct = async (productId: number) => await axiosInstance.delete(`${productPath.byId(productId)}`);

export const getProductsWithDeclaration = async (companyId: number) =>
    await axiosInstance
        .get<ProductWithDeclarationResponse[]>(productPath.declarations(companyId))
        .then((res) => res.data);

export const getProductWithDeclaration = async (productId: number) =>
    await axiosInstance.get<ProductWithDeclarationResponse>(productPath.declaration(productId)).then((res) => res.data);

export const getProductPackaging = async (productId: number) =>
    await axiosInstance.get<ProductPackagingsResponse>(productPath.packaging(productId)).then((res) => res.data);

export const importProducts = async (reqData: {companyId: number; data: FormData}) =>
    await axiosInstance.post(`${productPath.import()}?companyId=${reqData.companyId}`, reqData.data);
export const exportProducts = async (companyId: number) =>
    await axiosInstance
        .get(`${productPath.export()}?companyId=${companyId}`, {
            responseType: 'blob',
        })
        .then((resp) => resp.data);

export const importProductPackaging = async (data: FormData) =>
    await axiosInstance.post(`${productPath.importPackaging()}`, data);
export const exportProductPackaging = async (companyId: number) =>
    await axiosInstance
        .get(`${productPath.exportPackaging()}?companyId=${companyId}`, {
            responseType: 'blob',
        })
        .then((resp) => resp.data);
