import {Box, List, Typography} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {DeclarationAttachmentResponse} from '../../api/dtos/declarationDto';
import {FileUploader} from '../FileUploader';
import {Attachment, UploadedAttachment, UploadedFile} from '../UploadedItem';

export const DeclarationDocuments: React.FC<{
    attachments?: DeclarationAttachmentResponse[];
    files: File[];
    removeFile: (file: File) => void;
    addFile: (event: ChangeEvent<HTMLInputElement>) => void;
    removeAttachment?: (attachment: Attachment) => void;
}> = ({attachments, files, removeFile, addFile, removeAttachment}) => {
    const handleRemoveAttachment = async (attachment: Attachment) => {
        removeAttachment && removeAttachment(attachment);
    };

    return (
        <Box maxWidth={'50%'}>
            <Typography variant={'h4'}>Dokumenter:</Typography>
            <List>
                {attachments?.map((attachment: Attachment, index: number) => (
                    <UploadedAttachment
                        key={index}
                        attachment={attachment}
                        handleRemoveAttachment={handleRemoveAttachment}
                    />
                ))}
                {files?.map((file: File, index: number) => (
                    <UploadedFile key={index} file={file} handleRemoveFile={removeFile} />
                ))}
            </List>
            <FileUploader
                handleFileChange={addFile}
                allowMultiple={true}
                acceptedFileTypes={'.pdf, .doc, .docx, .png, .jpg, .jpeg'}
            />
        </Box>
    );
};
