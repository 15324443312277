import {Alert, AlertProps, Snackbar} from '@mui/material';
import React, {createContext, PropsWithChildren, useState} from 'react';

type SnackBarProps = Pick<AlertProps, 'children' | 'severity'>;
type ContextProps = {
    addAlert: (alert: SnackBarProps) => void;
};
export const SnackBarContext = createContext<ContextProps | undefined>(undefined);

export const SnackBarProvider: React.FC<PropsWithChildren> = ({children}) => {
    const [snackbar, setSnackbar] = useState<SnackBarProps | null>(null);

    const provider = {
        addAlert: setSnackbar,
    };
    const handleCloseSnackbar = () => setSnackbar(null);

    return (
        <SnackBarContext.Provider value={provider}>
            {children}
            <Snackbar
                open={!!snackbar}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
            >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        </SnackBarContext.Provider>
    );
};

export const useSnackBar = () => {
    const context = React.useContext(SnackBarContext);
    const addSnackBar = (alert: SnackBarProps) => context && context.addAlert(alert);
    return {addSnackBar};
};
