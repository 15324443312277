import {Box, Container, CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ProtectedRoute} from 'components/routing/ProtectedRoute';
import {CreateProductDeclarationPage} from 'components/views/CreateProductDeclarationPage';
import {useApplicationState} from 'lib/context/applicationState';
import {Role} from 'lib/models';
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import {NavBar} from './components/NavBar';
import {PackagingList} from './components/registry/PackagingList';
import {ProductGroupList} from './components/registry/ProductGroupList';
import {RegistrySettingsPage} from './components/registry/RegistrySettingsPage';
import {TransportList} from './components/registry/TransportList';
import {AnnualReportsContent} from './components/reports/AnnualReportsContent';
import {KeyFiguresContent} from './components/reports/KeyFiguresContent';
import {SnackBarProvider} from './components/SnackBarProvider';
import {AnalysesListPage} from './components/views/AnalysesListPage';
import {AnalysisPage} from './components/views/AnalysisPage';
import {DeclarationPage} from './components/views/DeclarationPage';
import {NotFoundPage} from './components/views/NotFoundPage';
import {ProductPage} from './components/views/ProductPage';
import {RegistryPage} from './components/views/RegistryPage';
import {ReportAnnualPage, ReportKeyFiguresPage} from './components/views/ReportsPage';
import {SalesVolumePage} from './components/views/SalesVolumePage';
import {UpdateProductDeclarationPage} from './components/views/UpdateProductDeclarationPage';
import {customTheme} from './theme';

const App: React.FC = () => {
    const {state} = useApplicationState();

    return (
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackBarProvider>
                    <ThemeProvider theme={customTheme}>
                        <CssBaseline />
                        <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'}>
                            <NavBar />
                            <Container maxWidth={'xl'} sx={{flexGrow: 1, position: 'relative'}}>
                                <Routes>
                                    <Route
                                        path={'/'}
                                        element={
                                            <ProtectedRoute>
                                                <Navigate replace to={'/registry'} />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path='registry'
                                        element={<ProtectedRoute minimumRole={Role.User} children={<RegistryPage />} />}
                                    />
                                    <Route
                                        path='registry/:prodID'
                                        element={<ProtectedRoute minimumRole={Role.User} children={<ProductPage />} />}
                                    />
                                    <Route
                                        path='registry/settings'
                                        element={
                                            <ProtectedRoute
                                                minimumRole={Role.SuperUser}
                                                children={<RegistrySettingsPage />}
                                            />
                                        }
                                    >
                                        <Route index element={<ProductGroupList />} />
                                        <Route path={'packaging'} element={<PackagingList />} />
                                        <Route path={'transport'} element={<TransportList />} />
                                    </Route>
                                    <Route
                                        path='salesVolume'
                                        element={
                                            <ProtectedRoute>
                                                <SalesVolumePage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path='reports/keyFigures'
                                        element={
                                            <ProtectedRoute>
                                                <ReportKeyFiguresPage />
                                            </ProtectedRoute>
                                        }
                                    >
                                        <Route index element={<KeyFiguresContent />} />
                                        <Route path=':productTypeID' element={<KeyFiguresContent />} />
                                        <Route path=':productTypeID/:productGroupID' element={<KeyFiguresContent />} />
                                    </Route>
                                    <Route
                                        path='reports/annualReport'
                                        element={
                                            <ProtectedRoute>
                                                <ReportAnnualPage />
                                            </ProtectedRoute>
                                        }
                                    >
                                        <Route index element={<AnnualReportsContent />} />
                                        <Route path=':productTypeID' element={<AnnualReportsContent />} />
                                        <Route
                                            path=':productTypeID/:productGroupID'
                                            element={<AnnualReportsContent />}
                                        />
                                    </Route>
                                    <Route
                                        path='analysis'
                                        element={
                                            <ProtectedRoute>
                                                <AnalysesListPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path='analysis/:analysisID'
                                        element={
                                            <ProtectedRoute>
                                                <AnalysisPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path='declaration'
                                        element={
                                            <ProtectedRoute>
                                                <DeclarationPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path='declaration/create/:productId'
                                        element={<CreateProductDeclarationPage />}
                                    />
                                    <Route
                                        path='declaration/update/:declarationId'
                                        element={
                                            <ProtectedRoute>
                                                <UpdateProductDeclarationPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route path='*' element={<NotFoundPage />} />
                                </Routes>
                            </Container>
                        </Box>
                    </ThemeProvider>
                </SnackBarProvider>
            </LocalizationProvider>
        </BrowserRouter>
    );
};

export default App;
