import {AdminUserStore} from 'lib/AdminUserStore';
import {useApplicationState} from 'lib/context/applicationState';
import {Company, Role} from 'lib/models';
import {UserLocalStore} from 'lib/storage/UserLocalStore';
import {useCallback, useEffect} from 'react';
import {useUserLocalStore} from './useUserLocalStore';

/**
 * Hook to provide methods for getting, the current company
 * and setting a company as the current company.
 */
export const useCurrentCompany = () => {
    const {state, dispatch} = useApplicationState();
    const {userStore} = useUserLocalStore({userId: state?.currentUser?.userId}) as {
        userStore: UserLocalStore<AdminUserStore>;
    };

    /**
     * Sets the current  company
     * If store is set to false, the entry is not stored in local store.
     *
     * @param Company the company to set
     * @param store flag to determine if the value should be stored in local storage
     */
    const setCurrentCompany = useCallback(
        (company: Company, store = true) => {
            if (store) {
                userStore.update('lastCompanyId', company.companyId);
            }
            dispatch({type: 'setCurrentCompany', payload: company});
        },
        [dispatch, userStore],
    );

    /**
     * Effect for app initialization process for current Company.
     * Tries to load the last active Company and set the app state
     * to that Company if it still exist on the user.
     * Else it just picks a random company from the users available companies.
     */
    useEffect(() => {
        /**
         * We don't want to run this if there is already a current company set in application
         *  state, this is for app initialization
         */
        if (!userStore) return;
        if (state?.currentUser && userStore && !state?.currentCompany) {
            const currentUser = state.currentUser;
            const {lastCompanyId} = userStore.getUserData();
            let hasSet = false;

            const company = currentUser?.companies || [];
            // THERE IS ONE SET, CHECK IF IT IS VALID.
            // IF SO SET IT AS CURRENT
            if (lastCompanyId !== undefined) {
                // ensure number
                const companyId = Number.parseInt(`${lastCompanyId}`);
                if (company.length) {
                    const exists = company.find((o) => o.companyId === companyId);
                    if (exists) {
                        hasSet = true;
                        setCurrentCompany(exists, false);
                    }
                }
            }

            if (company.length && !hasSet) {
                const selected = company[0];
                setCurrentCompany(selected);
            }
        }
    }, [dispatch, setCurrentCompany, state?.currentCompany, state.currentUser, userStore]);

    /**
     * Reset the current company when changing role to
     * ensures re-evaluate current  company when we change role.
     * So we don't get stuck with another set company, if we move from training office to  company role.
     */
    useEffect(() => {
        if (state.activeRole === Role.CompanyAdmin) {
            dispatch({type: 'setCurrentCompany', payload: undefined});
        }
    }, [dispatch, state.activeRole]);

    return {setCurrentCompany, currentCompany: state.currentCompany};
};

export const useCurrentCompanyId = () =>{
    const currentCompany = useCurrentCompany().currentCompany;
    return currentCompany != undefined ? currentCompany.companyId : -1;
}