export const getTextWithLink = (text: string, url: string) => {
    const first = text.split('%href_start%');
    if (first.length > 1) {
        const second = first[1].split('%href_end%');
        return (
            <>
                {first[0]}
                <a href={url} target={'_blank'} rel={'noreferrer'}>
                    {second[0]}
                </a>
                {second[1]}
            </>
        );
    } else {
        return <>{text}</>;
    }
};
