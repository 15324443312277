import {} from '@azure/msal-browser';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {Box} from '@mui/material';
import {useApplicationState} from 'lib/context/applicationState';
import {Role} from 'lib/models';
import {ReactNode} from 'react';
import {LoginPage} from '../views/LoginPage';

export const ProtectedRoute = ({minimumRole, children}: {minimumRole?: Role; children: ReactNode}) => {
    const {state} = useApplicationState();

    return (
        <>
            <AuthenticatedTemplate>
                {minimumRole !== undefined && state.activeRole < minimumRole ? (
                    <Box mt={3}>Ingen tilgang</Box>
                ) : (
                    children
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginPage />
            </UnauthenticatedTemplate>
        </>
    );
};
