import {Button, SxProps, Theme} from '@mui/material';
import {ChangeEvent, FC, ReactNode, useRef} from 'react';
import {useLanguage} from '../language/LanguageProvider';

export const FileUploader: FC<{
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    acceptedFileTypes: string;
    allowMultiple?: boolean;
    buttonText?: string;
    buttonStartIcon?: ReactNode;
    sx?: SxProps<Theme>;
}> = (props) => {
    const {getLangString} = useLanguage();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };
    return (
        <>
            <input
                type={'file'}
                accept={props.acceptedFileTypes}
                multiple={props.allowMultiple}
                onChange={props.handleFileChange}
                ref={hiddenFileInput}
                style={{display: 'none'}}
            />
            <Button onClick={handleClick} sx={props.sx} startIcon={props.buttonStartIcon}>
                {props.buttonText || getLangString('ADD_FILE')}
            </Button>
        </>
    );
};
