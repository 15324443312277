export const productPath = {
    base: () => `api/Product`,
    byId: (productId: number) => `api/Product/${productId}`,
    active: () => `api/Product/GetAllActiveProduct`,
    volume: () => `api/Product/GetProductVolume`,
    packaging: (productId?: number) => `api/Product/${productId}/GetProductPackaging`,
    declarations: (companyId: number) => `api/Product/${companyId}/GetProductsWithDeclaration`,
    declaration: (productId: number) => `api/Product/${productId}/GetProductWithDeclaration`,
    createDeclaration: () => `api/Product/CreateProductWithDeclaration`,
    export: () => 'api/Product/ExportProducts',
    import: () => 'api/Product/ImportProducts',
    importPackaging: () => 'api/Product/ImportProductPackaging',
    exportPackaging: () => 'api/Product/ExportProductPackaging',
};

export const productConfigPath = {
    base: () => `api/ProductConfig`,
    byId: (productId: number) => `api/ProductConfig/${productId}`,
    active: () => `api/ProductConfig/GetAllActiveProductConfig`,
};

export const volumePath = {
    base: () => `api/Volume`,
    byId: (id: number) => `api/Volume/${id}`,
    import: () => `api/Volume/Import`,
    export: () => `api/Volume/Export`,
};

export const declarationPath = {
    base: () => 'api/Declaration',
    create: () => 'api/Declaration/CreateDeclaration',
    update: (declarationId: number) => `api/Declaration/UpdateDeclaration/${declarationId}`,
    byId: (id: number) => `api/Declaration/GetDeclaration?declarationId=${id}`,
    removeAttachment: (declarationAttachmentId: number) =>
        `api/Declaration/${declarationAttachmentId}/RemoveAttachment`,
    addAttachment: (declarationId: number) => `api/Declaration/${declarationId}/AddAttachment`,
    questions: () => 'api/Declaration/GetWasteRegulationQuestions',
};

export const transportPath = {
    base: () => 'api/Transport',
    byId: (id: number) => `api/Transport/${id}`,
};

export const productGroupPath = {
    base: () => 'api/ProductGroup',
    byId: (id: number) => `api/ProductGroup/${id}`,
};
export const productGroupTypePath = {
    base: () => 'api/ProductGroupType',
    byId: (id: number) => `api/ProductGroupType/${id}`,
};

export const packagingPath = {
    base: () => 'api/Packaging',
    byId: (id: number) => `api/Packaging/${id}`,
};

export const packagingGroupPath = {
    base: () => 'api/PackagingGroup',
    byId: (id: number) => `api/PackagingGroup/${id}`,
};

export const keyFiguresPath = {
    base: () => 'api/KeyFigures',
    type: () => 'api/KeyFigures/Type',
    group: () => 'api/KeyFigures/Group',
    export: () => 'api/KeyFigures/Export',
    exportGroup: () => 'api/KeyFigures/ExportGroup',
    exportType: () => 'api/KeyFigures/ExportType',
};
export const yearlyReportPath = {
    base: () => 'api/YearlyReport',
    type: () => 'api/YearlyReport/Type',
    group: () => 'api/YearlyReport/Group',
    export: () => 'api/YearlyReport/Export',
    exportGroup: () => 'api/YearlyReport/ExportGroup',
    exportType: () => 'api/YearlyReport/ExportType',
};
export const userPath = {
    base: () => 'api/User',
    me: () => 'api/User/me',
};
export const analysisPath = {
    base: () => 'api/Analysis',
    byId: (id: number) => `api/Analysis/${id}`,
    calculation: () => 'api/Analysis/CalculateProductAnalysis',
    export: () => 'api/Analysis/Export',
};
export const importPath = {
    products: () => 'api/Import/Products',
    productPackaging: () => 'api/Import/ProductPackaging',
    volume: () => 'api/Import/Volume',
};
