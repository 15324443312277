import {createTheme, SimplePaletteColorOptions} from '@mui/material';
import {nbNO} from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
    interface Theme {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customThemeProp: any; //example value
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customThemeProp?: any; //example value
    }

    interface Palette {
        accent: SimplePaletteColorOptions;
    }
    interface PaletteOptions {
        accent?: SimplePaletteColorOptions;
    }
}

const color = {
    grey900: '#212121',
    grey800: '#424242',
    grey700: '#616161',
    grey500: '#9e9e9e',
    grey200: '#eee',
    grey50: '#fafafa',
    // grey400: '#',
    grey300: '#e0e0e0',
    grey100: '#f5f5f5',

    paper: '#fff',

    primaryLight: '#f7f9f9',
    primaryDark: '#0a463e',
    primary200: '#90caf9',
    primary800: '#0a463e',
    primaryMain: '#136f63',
    // primaryMain: '#2196f3',

    secondaryDark: '#5e35b1',
    secondary200: '#b39ddb',
    secondary800: '#4527a0',
    secondaryMain: '#673ab7',

    secondaryLight: '#ede7f6',
    textDark: '#',

    darkPaper: '#111936',

    warningLight: '#fff8e1',
    warningMain: '#ffc107',
    warningDark: '#ffc107',

    successLight: '#b9f6ca',
    success200: '#69f0ae',
    successMain: '#16a451',
    successDark: '#0e7238',

    errorLight: '#ef9a9a',
    errorMain: '#f44336',
    errorDark: '#c62828',

    orangeLight: '#fbe9e7',
    orangeMain: '#ffab91',
    orangeDark: '#d84315',

    darkTextPrimary: '#bdc8f0',
    darkBackground: '#1a223f',
    darkLevel1: '#29314f',
    darkLevel2: '#212946',
};
const bgColor = '#fff';
const themeOptions = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey800,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.primaryDark,
    menuSelectedBack: color.primaryLight,
    divider: color.grey200,
};
export const customTheme = createTheme(
    {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1800,
            },
        },
        typography: {
            fontFamily: 'Inter-Regular',
            h6: {
                fontWeight: 500,
                color: themeOptions.heading,
                fontSize: '0.75rem',
            },
            h5: {
                fontSize: '0.875rem',
                color: themeOptions.heading,
                fontFamily: 'Inter-Semibold',
            },
            h4: {
                fontFamily: 'Inter-Bold',
                fontSize: '1rem',
                color: themeOptions.heading,
            },
            h3: {
                fontSize: '1.25rem',
                color: themeOptions.heading,
                fontFamily: 'Inter-Semibold',
            },
            h2: {
                fontSize: '1.5rem',
                color: themeOptions.heading,
                fontFamily: 'Inter-Bold',
            },
            h1: {
                fontSize: '2.125rem',
                color: themeOptions.heading,
                fontFamily: 'Inter-Bold',
            },
            subtitle1: {
                fontSize: '1rem',
                fontFamily: 'Inter-Semibold',
                color: themeOptions.textDark,
            },
            subtitle2: {
                fontSize: '0.875rem',
                fontWeight: 400,
                color: themeOptions.darkTextSecondary,
            },
            caption: {
                fontSize: '0.75rem',
                color: themeOptions.darkTextSecondary,
                fontWeight: 400,
            },
            body1: {
                fontSize: '1rem',
                color: themeOptions.darkTextPrimary,
                lineHeight: '1.334em',
            },
            body2: {
                letterSpacing: '0em',
                fontWeight: 400,
                lineHeight: '1.5em',
                color: themeOptions.darkTextPrimary,
            },
            button: {
                textTransform: 'inherit',
            },
        },
        palette: {
            common: {
                black: themeOptions.colors?.darkPaper,
            },
            primary: {
                light: themeOptions.colors?.primaryLight,
                main: themeOptions.colors?.primaryMain,
                dark: themeOptions.colors?.primaryDark,
                200: themeOptions.colors?.primary200,
                800: themeOptions.colors?.primary800,
            },
            secondary: {
                light: themeOptions.colors?.secondaryLight,
                main: themeOptions.colors?.secondaryMain,
                dark: themeOptions.colors?.secondaryDark,
                200: themeOptions.colors?.secondary200,
                800: themeOptions.colors?.secondary800,
            },
            error: {
                light: themeOptions.colors?.errorLight,
                main: themeOptions.colors?.errorMain,
                dark: themeOptions.colors?.errorDark,
            },
            warning: {
                light: themeOptions.colors?.warningLight,
                main: themeOptions.colors?.warningMain,
                dark: themeOptions.colors?.warningDark,
            },
            success: {
                light: themeOptions.colors?.successLight,
                200: themeOptions.colors?.success200,
                main: themeOptions.colors?.successMain,
                dark: themeOptions.colors?.successDark,
            },
            grey: {
                50: themeOptions.colors?.grey50,
                100: themeOptions.colors?.grey100,
                500: themeOptions.darkTextSecondary,
                600: themeOptions.heading,
                700: themeOptions.darkTextPrimary,
                900: themeOptions.textDark,
            },
            text: {
                primary: themeOptions.darkTextPrimary,
                secondary: themeOptions.darkTextSecondary,
            },
            background: {
                paper: themeOptions.paper,
                default: themeOptions.backgroundDefault,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 500,
                        borderRadius: '4px',
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 6%), 0px 1px 5px 0px rgb(0 0 0 / 5%)',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        alignItems: 'stretch',
                        '.MuiTabs-flexContainer': {
                            height: '100%',
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: themeOptions.darkTextPrimary,
                        fontSize: '1.125rem',
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    },
                    rounded: {
                        borderRadius: 12,
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        color: themeOptions.colors?.textDark,
                        padding: '24px',
                    },
                    title: {
                        fontSize: '1.125rem',
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    },
                },
            },
            MuiListItemButton: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        color: themeOptions.colors.primaryMain,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        '&.Mui-selected': {
                            color: themeOptions.menuSelected,
                            backgroundColor: themeOptions.menuSelectedBack,
                            '&:hover': {
                                backgroundColor: themeOptions.menuSelectedBack,
                            },
                            '& .MuiListItemIcon-root': {
                                color: themeOptions.menuSelected,
                            },
                        },
                        '&:hover': {
                            backgroundColor: themeOptions.menuSelectedBack,
                            color: themeOptions.menuSelected,
                            '& .MuiListItemIcon-root': {
                                color: themeOptions.menuSelected,
                            },
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: themeOptions.darkTextPrimary,
                        minWidth: '36px',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        color: themeOptions.textDark,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: themeOptions.textDark,
                        '&::placeholder': {
                            color: themeOptions.darkTextSecondary,
                            fontSize: '0.875rem',
                        },
                    },
                },
                defaultProps: {
                    inputProps: {min: 0},
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        background: bgColor,
                        borderRadius: 12,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeOptions.colors?.grey300,
                        },
                        '&:hover $notchedOutline': {
                            borderColor: themeOptions.colors?.primaryLight,
                        },
                        '&.MuiInputBase-multiline': {
                            padding: 1,
                        },
                    },
                    input: {
                        fontWeight: 500,
                        background: bgColor,
                        padding: '15.5px 14px',
                        borderRadius: 12,
                        '&.MuiInputBase-inputSizeSmall': {
                            padding: '10px 14px',
                            '&.MuiInputBase-inputAdornedStart': {
                                paddingLeft: 0,
                            },
                        },
                    },
                    inputAdornedStart: {
                        paddingLeft: 4,
                    },
                    notchedOutline: {
                        borderRadius: 12,
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: themeOptions.colors?.grey300,
                        },
                    },
                    mark: {
                        backgroundColor: themeOptions.paper,
                        width: '4px',
                    },
                    valueLabel: {
                        color: themeOptions?.colors?.primaryLight,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: themeOptions.divider,
                        opacity: 1,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: themeOptions.colors?.primaryDark,
                        background: themeOptions.colors?.primary200,
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.MuiChip-deletable .MuiChip-deleteIcon': {
                            color: 'inherit',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        color: themeOptions.paper,
                        background: themeOptions.colors?.grey700,
                    },
                },
            },
            MuiDataGrid: {
                defaultProps: {
                    disableColumnSelector: true,
                    disableSelectionOnClick: true,
                },
                styleOverrides: {
                    root: {border: 'none'},
                    columnHeader: {
                        color: 'secondary',
                        backgroundColor: themeOptions.colors.grey50,
                        fontFamily: 'Inter-SemiBold',
                        '&:focus, &:focus-within': {outline: 'none'},
                        '&.header-align-right ': {
                            textAlign: 'right',
                        },
                    },
                    columnHeaderTitle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1rem',
                    },
                    columnSeparator: {
                        color: 'transparent',
                    },
                    cell: {
                        '&:focus, &:focus-within': {outline: 'none'},
                        paddingRight: 20,
                    },
                    main: {
                        border: '1px solid rgba(224, 224, 224)',
                    },
                    footerContainer: {
                        border: '1px solid rgba(224, 224, 224)',
                        borderTop: 'none',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        color: 'secondary',
                        backgroundColor: themeOptions.colors.grey50,
                        fontFamily: 'Inter-SemiBold',
                        '&:focus, &:focus-within': {outline: 'none'},
                        '&.header-align-right ': {
                            textAlign: 'right',
                        },
                    },
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        border: '1px solid rgba(224, 224, 224)',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        marginTop: '4px',
                        marginBottom: '4px',
                        '&.Mui-expanded': {
                            marginTop: '4px',
                            marginBottom: '4px',
                        },
                        border: '1px solid',
                        borderColor: 'rgba(0,0,0, 0.15)',
                        borderRadius: '4px',
                    },
                },
            },
        },
    },
    nbNO,
);
