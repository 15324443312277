import {Box, Button, Dialog, DialogContent, DialogTitle, TextField} from '@mui/material';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressOverlay} from '../ProgressComponents';
export type CreateAnalysisFormFields = {
    analysisName: string;
    description: string;
};
type DialogProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: (form: CreateAnalysisFormFields) => void;
    isInProgress: boolean;
};
const inputStyle = {
    my: 1,
};
export const DialogCreateAnalysis: React.FC<DialogProps> = ({isOpen, handleClose, handleCreate, isInProgress}) => {
    const {getLangString} = useLanguage();
    const defaultValues: CreateAnalysisFormFields = {
        analysisName: '',
        description: '',
    };
    const {handleSubmit, control} = useForm<CreateAnalysisFormFields>({defaultValues});

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle variant={'h3'} my={1}>
                {getLangString('CREATE_ANALYSIS')}
            </DialogTitle>
            <DialogContent sx={{width: 500}}>
                <form onSubmit={handleSubmit(handleCreate)}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Controller
                            name={'analysisName'}
                            control={control}
                            rules={{required: getLangString('FIELD_IS_REQUIRED')}}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    label={getLangString('ANALYSIS_NAME')}
                                    sx={inputStyle}
                                />
                            )}
                        />
                        <Controller
                            name={'description'}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    multiline={true}
                                    minRows={5}
                                    label={getLangString('DESCRIPTION')}
                                    sx={inputStyle}
                                />
                            )}
                        />
                    </Box>
                    <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={handleClose} variant={'outlined'} sx={{mr: 1}}>
                            {getLangString('CANCEL')}
                        </Button>
                        <Button type={'submit'} variant={'contained'}>
                            {getLangString('CREATE')}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            {isInProgress && <CircularProgressOverlay />}
        </Dialog>
    );
};
