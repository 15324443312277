import {Role} from 'lib/models/user/Role';
import {createContext, Dispatch, ReactNode, useContext, useReducer} from 'react';
import {ApplicationStateAction} from './ApplicationStateActions';
import {ApplicationState, applicationStateReducer} from './ApplicationStateReducer';

/**
 * Application global state context
 */
const ApplicationContext = createContext<
    | {
          state: ApplicationState;
          dispatch: Dispatch<ApplicationStateAction>;
      }
    | undefined
>(undefined);

/**
 * Application state provider
 * @returns context provider
 */
const ApplicationStateProvider = ({children}: {children: ReactNode}) => {
    const [state, dispatch] = useReducer(applicationStateReducer, {
        authenticated: false,
        activeRole: Role.None,
    } as ApplicationState);

    return <ApplicationContext.Provider value={{state, dispatch}}>{children}</ApplicationContext.Provider>;
};

/**
 * Returns the application context, or throws
 * if used outside the context provider.
 * @returns appContext or throws error
 */
const useApplicationState = () => {
    const appContext = useContext(ApplicationContext);
    if (appContext === undefined) {
        throw new Error('useApplicationState must be used within ApplicationStateProvider');
    }
    return appContext;
};

//---
export {ApplicationStateProvider, useApplicationState};
