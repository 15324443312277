import {Box} from '@mui/material';
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import React from 'react';
import {ValueChainEmissionsResp} from '../../api/dtos/reportsDto';
import {useLanguage} from '../../language/LanguageProvider';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {valueChainEmissionText} from './transformToChartData';
import {getYearsInDataSet} from './transformToTableData';

export type TableRowData = {
    id: string;
    year: number;
    packagingGroupName: string;
    dataPoints: {
        name: string;
        value: number;
    }[];
};
export const ReportTable: React.FC<{data: TableRowData[]; groupHeaders: string[]; dataType: ReportDataSet}> = ({
    data,
    groupHeaders,
    dataType,
}) => {
    const {getLangString} = useLanguage();
    const getColumnHeaders = (): GridColumns => {
        const columnWidth = 1100 / groupHeaders.length;
        return groupHeaders.map((name) => ({
            field: name,
            headerName: name,
            align: 'right',
            type: 'number',
            minWidth: columnWidth,
            headerClassName: 'header-align-right',
        }));
    };
    const getDataPoints = (d: TableRowData) => {
        return d.dataPoints.reduce((acc, curr) => ({...acc, [curr.name]: curr.value}), {});
    };
    const getRows = () => {
        return data
            ? data.map((d) => ({id: d.id, year: d.year, packaging: d.packagingGroupName, ...getDataPoints(d)}))
            : [];
    };
    const columns: GridColumns = [
        {
            field: 'year',
            headerName: getLangString('YEAR'),
        },
        {
            field: 'packaging',
            headerName: dataType === 'valueChainEmissions' ? getLangString('NAME') : getLangString('PACKAGING'),
            flex: 1,
            minWidth: 200,
            valueFormatter: (params) =>
                dataType === 'valueChainEmissions'
                    ? valueChainEmissionText[params.value as keyof ValueChainEmissionsResp]
                    : params.value,
        },
        ...getColumnHeaders(),
    ];
    const years = getYearsInDataSet(data);

    return (
        <Box
            sx={{
                width: '100%',
                '& .row-style--even': {},
                '& .row-style--odd': {
                    backgroundColor: '#f7fbfd',
                },
            }}
        >
            <DataGrid
                sx={{overflowX: 'scroll'}}
                columns={columns}
                rows={getRows()}
                autoHeight={true}
                getRowClassName={(params) => {
                    const type = years.findIndex((el) => el === params.row.year) % 2 ? 'odd' : 'even';
                    return `row-style--${type}`;
                }}
            />
        </Box>
    );
};
