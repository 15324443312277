import {ArrowDropDown, InputRounded} from '@mui/icons-material';
import {Box, Button, ButtonProps, Menu, MenuItem, SxProps} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {ButtonWithLoading} from './ButtonWithLoading';
import {FileUploader} from './FileUploader';

type MenuProps = {
    buttonText: string;
    buttonProps: ButtonProps;
    menuItems: {
        name: string;
        onClick: () => void;
    }[];
    loading?: boolean;
};
export const ButtonWithMenu: React.FC<MenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <ButtonWithLoading
                loading={!!props.loading}
                buttonProps={{
                    ...props.buttonProps,
                    children: props.buttonText,
                    endIcon: <ArrowDropDown />,
                    onClick: handleClick,
                }}
            />
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                {props.menuItems.map((item) => (
                    <MenuItem
                        sx={{height: '40px'}}
                        key={item.name}
                        onClick={() => {
                            item.onClick();
                            handleClose();
                        }}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

type MenuProps2 = {
    handleProductImport: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePackagingImport: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const menuItemStyle: SxProps = {
    width: '100%',
    px: 2,
    justifyContent: 'flex-start',
    color: '#424242',
    fontSize: '1rem',
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)'},
};
export const ButtonWithImportOptions: React.FC<MenuProps2> = (props) => {
    const {getLangString} = useLanguage();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const doImportProducts = (e: ChangeEvent<HTMLInputElement>) => {
        handleClose();
        props.handleProductImport(e);
    };
    return (
        <>
            <Button size={'small'} onClick={handleClick} startIcon={<InputRounded />} endIcon={<ArrowDropDown />}>
                {getLangString('IMPORT')}
            </Button>
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl} sx={{minWidth: 200}}>
                <Box>
                    <FileUploader
                        handleFileChange={doImportProducts}
                        allowMultiple={true}
                        acceptedFileTypes={'.xls, .xlsx'}
                        buttonText={getLangString('IMPORT_PRODUCTS')}
                        sx={menuItemStyle}
                    />
                </Box>
                <Box>
                    <FileUploader
                        handleFileChange={props.handlePackagingImport}
                        allowMultiple={true}
                        acceptedFileTypes={'.xls, .xlsx'}
                        buttonText={getLangString('IMPORT_PACKAGING')}
                        sx={menuItemStyle}
                    />
                </Box>
            </Menu>
        </>
    );
};
