import {axiosInstance} from '../axiosInstance';
import {
    CreatePackagingDto,
    CreatePackagingGroup,
    PackagingDto,
    PackagingGroup,
    UpdatePackagingDto,
    UpdatePackagingGroup,
} from '../dtos/packagingDto';
import {packagingGroupPath, packagingPath} from '../paths';

export const getPackaging = async (companyId: number) =>
    await axiosInstance.get<PackagingDto[]>(`${packagingPath.base()}?companyId=${companyId}`).then((res) => res.data);
export const createPackaging = async (data: CreatePackagingDto) => await axiosInstance.post(packagingPath.base(), data);
export const updatePackaging = async (reqData: {packagingId: number; data: UpdatePackagingDto}) =>
    await axiosInstance.patch(`${packagingPath.base()}/${reqData.packagingId}`, reqData.data);
export const deletePackaging = async (packagingId: number) =>
    await axiosInstance.delete(`${packagingPath.byId(packagingId)}`);

export const getPackagingGroups = async (companyId: number) =>
    await axiosInstance
        .get<PackagingGroup[]>(`${packagingGroupPath.base()}?companyId=${companyId}`)
        .then((res) => res.data);
export const updatePackagingGroup = async (reqData: {packagingGroupId: number; data: UpdatePackagingGroup}) =>
    await axiosInstance.patch(`${packagingGroupPath.base()}/${reqData.packagingGroupId}`, reqData.data);
export const createPackagingGroup = async (data: CreatePackagingGroup) =>
    await axiosInstance.post<{packagingGroupId: number}>(`${packagingGroupPath.base()}`, data);
export const deletePackagingGroup = async (packagingId: number) =>
    await axiosInstance.delete(`${packagingGroupPath.byId(packagingId)}`);
