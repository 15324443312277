import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {CreateAnalysisDto, UpdateAnalysisDto, UpdateAnalysisHeaderDto} from '../dtos/analysisDto';
import {
    createAnalysis,
    getAnalyses,
    getAnalysisById,
    getAnalysisProducts,
    makeCalculations,
    updateAnalysis,
    deleteAnalysisById,
    updateAnalysisHeader,
} from '../handlers/analysis';

export const useGetAnalyses = (companyId:number) => {
    return useQuery(['analyses'], () => getAnalyses(companyId), {enabled : companyId > 0});
};
export const useGetAnalysisById = (analysisId: number) => {
    return useQuery(['analyses', analysisId], () => getAnalysisById(analysisId));
};

export const useCreateAnalysis = (companyId:number) => {
    return useMutation((data: CreateAnalysisDto) => createAnalysis(data, companyId));
};

export const useUpdateAnalysis = () => {
    const client = useQueryClient();
    return useMutation(
        (data: {analysisId: number; dto: UpdateAnalysisDto}) => updateAnalysis(data.analysisId, data.dto),
        {
            onSuccess: (response, variables) => {
                client.invalidateQueries(['analyses', variables.analysisId]);
            },
            // onError: (error: Error) => {
            //     addSnackBar({children: error.message, severity: 'error'});
            // },
        },
    );
};

export const useUpdateAnalysisHeader = () => {
    const client = useQueryClient();
    return useMutation(
        (data: {analysisId: number; dto: UpdateAnalysisHeaderDto}) => updateAnalysisHeader(data.analysisId, data.dto),
        {
            onSuccess: (response, variables) => {
                client.invalidateQueries(['analyses', variables.analysisId])
            }
        }
    )
}

export const useDeleteAnalysisById = () => {
    const client = useQueryClient();
    return useMutation(deleteAnalysisById, {
        onSuccess: () => {
            client.invalidateQueries(['analyses'])
        }
    })
}

export const useMakeCalculations = () => {
    return useMutation(makeCalculations);
};

export const useGetAnalysisProducts = (companyId:number) => {
    return useQuery(['analysisProducts'], () => getAnalysisProducts(companyId), {enabled : companyId > 0});
};
