import {UseAutocompleteProps} from '@mui/base';
import {Autocomplete, TextField} from '@mui/material';
import {useGridApiContext} from '@mui/x-data-grid';
import React, {FC} from 'react';

export const CustomTableAutocomplete: FC<
    Partial<UseAutocompleteProps<{id: number; label: string} | null, false, false, false>> & {
        cellId: number | string;
        field: string;
        options: {id: number; label: string}[];
        triggerSave?: (id: number | string) => void;
        tabToNextRow?: (id: number | string) => void;
        error?: boolean;
    }
> = (props) => {
    const apiRef = useGridApiContext();
    const handleValueChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: {id: number; label: string} | null,
    ) => {
        apiRef.current.setEditCellValue({id: props.cellId, field: props.field, value: value});
    };
    return (
        <Autocomplete
            fullWidth={true}
            onChange={handleValueChange}
            getOptionLabel={(option) => option.label}
            value={props.value || undefined}
            options={props.options}
            renderInput={(inputParams) => (
                <TextField
                    {...inputParams}
                    error={props.error}
                    value={props.value ? props.value.label : 'Velg materiale'}
                />
            )}
        />
    );
};
