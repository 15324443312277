import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';

export const generatePDF = async (element: HTMLElement, fileName: string, headerText: string, text?: string) => {
    const canvas = await html2canvas(element);
    const img = canvas.toDataURL('image/jpeg');
    const pdf = new jsPDF('landscape');

    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    let yPosition = 5;
    const xPosition = 5;
    let heightLeft = imgHeight;

    pdf.setFontSize(13);
    const headerTxt = pdf.splitTextToSize(headerText, pdfWidth);
    const headerTxtDimensions = pdf.getTextDimensions(headerTxt);
    pdf.text(headerTxt, 5, 10);

    pdf.setFontSize(9);

    const contentText = pdf.splitTextToSize(text || '', pdfWidth);

    pdf.text(contentText, 5, 15 + headerTxtDimensions.h);

    while (heightLeft >= 0) {
        pdf.addPage();
        pdf.addImage(img, 'JPEG', xPosition, yPosition, pdfWidth, imgHeight);
        yPosition = yPosition - (pdfHeight - 5); // top padding for other pages
        heightLeft -= pdfHeight;
    }
    await pdf.save(fileName, {returnPromise: true});
};
