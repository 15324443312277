import {Box} from '@mui/material';
import {GridRowModel} from '@mui/x-data-grid';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {useCreateTransport, useDeleteTransport, useGetTransport, useUpdateTransport} from '../../api/hooks/transports';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressCentered} from '../ProgressComponents';
import {AddGWPValuesFormFields} from './DialogAddGWPItem';
import {GWPTable, GWPTableRowProps} from './GWPTable';

export const TransportList: React.FC = () => {
    const {getLangString} = useLanguage();

    const currentCompanyId = useCurrentCompanyId();

    const transport = useGetTransport(currentCompanyId);

    const updateExistingTransport = useUpdateTransport();
    const createNewTransport = useCreateTransport();
    const deleteTransport = useDeleteTransport();

    if (transport.isLoading) {
        return <CircularProgressCentered />;
    }
    if (transport.isError) {
        //TODO: add better error message
        return <Box>Noe feilet</Box>;
    }
    const getTransportTableData = (): GWPTableRowProps[] => {
        return transport.data.map((p) => ({
            id: p.transportId,
            name: p.transportName,
            GWP: String(p.gwp),
            comment: p.comment,
            isNew: false,
        }));
    };
    const handleRowUpdate = (newRow: GridRowModel<GWPTableRowProps>) => {
        updateExistingTransport.mutate({
            transportId: newRow.id,
            data: {
                companyId: currentCompanyId,
                transportName: newRow.name,
                gwp: parseFloat(newRow.GWP),
                unit: '',
                comment: newRow.comment,
            },
        });
    };
    const handleCreate = (newRow: AddGWPValuesFormFields) => {
        createNewTransport.mutate({
            transportName: newRow.name,
            companyId: currentCompanyId,
            gwp: parseFloat(newRow.GWP),
            unit: '',
            comment: newRow.comment,
        });
    };
    const handleRowDelete = (transportId: number) => {
        return deleteTransport.mutateAsync(transportId);
    };

    return (
        <GWPTable
            tableData={getTransportTableData()}
            onCreate={handleCreate}
            rowCreateButtonText={getLangString('ADD_TRANSPORT_TYPE')}
            onRowUpdate={handleRowUpdate}
            onRowDelete={handleRowDelete}
        />
    );
};
