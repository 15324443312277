import {Add, SettingsOutlined} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useApplicationState} from 'lib/context/applicationState';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React, {ChangeEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
    exportProductPackaging,
    exportProducts,
    importProductPackaging,
    importProducts,
} from '../../api/handlers/product';
import {createProductConfig} from '../../api/handlers/productConfig';
import {useCreateProduct, useGetProducts} from '../../api/hooks/product';
import {ProblemDetailsError} from '../../api/problemDetails';
import {useLanguage} from '../../language/LanguageProvider';
import {Role} from '../../lib/models';
import {createDateWithoutTimezone} from '../../utils/createDateWithoutTimezone';
import {handleFileDownload} from '../../utils/handleFileDownload';
import {useProblemDetailsError} from '../../utils/useProblemDetailsError';
import {CustomAlertDialog} from '../CustomAlertDialog';
import {CreateProductFormFields, DialogCreateProduct} from '../registry/DialogCreateProduct';
import {ProductList} from '../registry/ProductList';
import {useSnackBar} from '../SnackBarProvider';

export const RegistryPage: React.FC = () => {
    const queryClient = useQueryClient();
    const {state} = useApplicationState();
    const {getLangString} = useLanguage();
    const {addSnackBar} = useSnackBar();

    const navigate = useNavigate();
    const goToSettingPage = () => {
        navigate('/registry/settings');
    };
    const currentCompanyId = useCurrentCompanyId();

    const {status, data} = useGetProducts(currentCompanyId);
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const handleDialogClose = () => {
        setCreateDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setCreateDialogOpen(true);
    };

    const createNewProductConfig = useMutation(createProductConfig, {
        onSuccess: () => {
            handleDialogClose();
            queryClient.invalidateQueries(['productConfigs']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const createNewProduct = useCreateProduct();
    const doCreateProduct = (form: CreateProductFormFields) => {
        const fromYear = form.validFrom.getFullYear();
        createNewProduct
            .mutateAsync({
                ...form,
                productGroupId: parseInt(form.productGroupId),
                companyId: currentCompanyId,
            })
            .then((data) =>
                createNewProductConfig
                    .mutateAsync({
                        ...form,
                        transportId: parseInt(form.transportId),
                        netWeightGrams: parseInt(form.netWeightGrams),
                        fpakDpak: parseInt(form.fpakDpak),
                        dpakPallet: parseInt(form.dpakPallet),
                        productId: data.productId,
                        comment: form.comment,
                        validFrom: createDateWithoutTimezone(fromYear, 1, 1),
                    })
                    .then((resp) => {
                        navigate(`/registry/${data.productId}`);
                    }),
            );
    };
    const {translatedError, translate, clearTranslatedError} = useProblemDetailsError({
        //TODO: handle incorrect format error code getLangString('ERROR_MSG__IMPORT_INCORRECT_FORMAT'),
    });
    const doImportProducts = useMutation(importProducts, {
        onSuccess: () => {
            queryClient.invalidateQueries(['products']);
        },
        onError: (error: AxiosError<ProblemDetailsError>) => {
            translate(error);
        },
    });
    const doImportPackaging = useMutation(importProductPackaging, {
        onError: (error: AxiosError<ProblemDetailsError>) => {
            translate(error);
        },
    });
    const doExportProducts = useQuery(['exportProducts'], () => exportProducts(currentCompanyId), {enabled: false});
    const doExportPackaging = useQuery(['exportPackaging'], () => exportProductPackaging(currentCompanyId), {
        enabled: false,
    });

    const handleProductImport = (e: ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => formData.append('file', file));
        }
        doImportProducts.mutate({companyId: currentCompanyId, data: formData});
    };
    const handlePackagingImport = (e: ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => formData.append('file', file));
        }
        doImportPackaging.mutate(formData);
    };
    const handleExportProducts = () => {
        doExportProducts.refetch().then((resp) => {
            handleFileDownload(resp.data, 'Produkter.xlsx');
        });
    };
    const handleExportPackaging = () => {
        doExportPackaging.refetch().then((resp) => {
            handleFileDownload(resp.data, 'Emballasjedata.xlsx');
        });
    };

    return (
        <Box mt={3}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h3'}>{getLangString('PRODUCTS')}</Typography>
                {state.activeRole > Role.User && (
                    <Box
                        sx={{
                            '& > *': {
                                ml: 1,
                            },
                        }}
                    >
                        <Button onClick={handleDialogOpen} size={'large'} startIcon={<Add />}>
                            {getLangString('CREATE_PRODUCT')}
                        </Button>
                        {state.activeRole == Role.SuperUser && (
                            <Button size={'large'} onClick={goToSettingPage} startIcon={<SettingsOutlined />}>
                                {getLangString('BACKGROUND_DATA')}
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
            <Box height={window.innerHeight - 200}>
                <ProductList
                    data={data}
                    isLoading={status === 'loading'}
                    handleImportProducts={handleProductImport}
                    handleImportPackaging={handlePackagingImport}
                    handleExportProducts={handleExportProducts}
                    handleExportPackaging={handleExportPackaging}
                />
            </Box>
            <DialogCreateProduct
                isOpen={createDialogOpen}
                handleClose={handleDialogClose}
                handleCreate={doCreateProduct}
            />
            <CustomAlertDialog
                isOpen={!!translatedError}
                headerText={getLangString('ERROR_MSG__IMPORT_INCORRECT_FORMAT_HEADER')}
                bodyText={translatedError!}
                onClose={clearTranslatedError}
            />
        </Box>
    );
};
