import {DeclarationResponse} from './declarationDto';
import {ProductConfig, ProductPackaging} from './productConfigDto';

export type ProductResponse = {
    productId: number;
    productName: string;
    productGroupId: number;
    productGroupName: string;
    productConfigs: ProductConfig[];
    currentProductConfig: ProductConfig;
};

export type CreateProductDto = {
    productName: string;
    productGroupId: number;
    companyId: number;
    productConfig?: {
        transportId: number;
        netWeightGrams: number;
        fpakDpak: number;
        dpakPallet: number;
        validFrom: Date;
        comment: string;
    };
    internalId?: string;
};
export type UpdateProductDto = {
    productName: string;
    productGroupId: number;
    companyId: number;
    internalId?: string;
};

export enum DeclarationState {
    NOT_DECLARED,
    PARTIALLY_DECLARED,
    DECLARED,
}
export type ProductWithDeclarationResponse = {
    productId: number;
    productName: string;
    productGroupName: string;
    declaration: DeclarationResponse;
    declarationState: DeclarationState;
    productPackagings: ProductPackaging[];
};

export type CreateProductDeclaration = {
    productId: number;
    companyId: number;
    attachments: FormData[];
    answers: {wasteRegulationQuestionId: number; answer: string};
};
export type ProductPackagingsResponse = {
    productName: string;
    productPackagings: ProductPackaging[];
};
