import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useGetProductGroups} from '../../api/hooks/product';
import {useGetTransport} from '../../api/hooks/transports';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressOverlay} from '../ProgressComponents';

export type CreateProductFormFields = {
    productName: string;
    productGroupId: string;
    transportId: string;
    netWeightGrams: string;
    fpakDpak: string;
    dpakPallet: string;
    validFrom: Date;
    comment: string;
};
type DialogProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: (form: CreateProductFormFields) => void;
    initialValues?: CreateProductFormFields;
    inProgress?: boolean;
};
const inputStyle = {
    my: 1,
};
export const DialogCreateProduct: React.FC<DialogProps> = ({
    isOpen,
    handleClose,
    handleCreate,
    initialValues,
    inProgress,
}) => {
    const {getLangString} = useLanguage();
    const defaultValues: CreateProductFormFields = initialValues
        ? {...initialValues}
        : {
              productName: '',
              productGroupId: '',
              netWeightGrams: '',
              fpakDpak: '',
              dpakPallet: '',
              transportId: '',
              validFrom: new Date(Date.now()),
              comment: '',
          };

    const handleCloseClick = (event: object, reason: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        handleClose();
    };
    const {handleSubmit, control} = useForm<CreateProductFormFields>({defaultValues});

    const currentCompanyId = useCurrentCompanyId();

    const transports = useGetTransport(currentCompanyId);
    const productGroups = useGetProductGroups(currentCompanyId);
    const commonRules = {required: getLangString('FIELD_IS_REQUIRED')};

    return (
        <Dialog open={isOpen} onClose={handleCloseClick}>
            <DialogTitle variant={'h3'} my={1}>
                {'Opprett produkt'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleCreate)}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Controller
                            name={'productName'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    label={getLangString('NAME')}
                                    sx={inputStyle}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={'productGroupId'}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <FormControl fullWidth={true} sx={inputStyle} error={!!fieldState.error}>
                                    <InputLabel>{getLangString('PRODUCT_GROUP')}</InputLabel>
                                    <Select {...field} placeholder={'Velg produktgruppe'}>
                                        {productGroups.data?.map((pg) => (
                                            <MenuItem key={pg.productGroupId} value={pg.productGroupId}>
                                                {pg.productGroupName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Box>
                    <Box my={2}>
                        <Controller
                            name={'netWeightGrams'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label={getLangString('NET_WEIGHT')}
                                    InputProps={{endAdornment: <InputAdornment position='end'>gram</InputAdornment>}}
                                    type={'number'}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    sx={inputStyle}
                                />
                            )}
                        />
                        <Box>
                            <Controller
                                control={control}
                                name={'fpakDpak'}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('FPAK_DPAK_AMOUNT')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{...inputStyle, mr: 1}}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={'dpakPallet'}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('DPAK_PALLET_AMOUNT')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={inputStyle}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box sx={{'& > div': {my: 2}}}>
                        <Controller
                            name={'transportId'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <FormControl fullWidth={true} sx={inputStyle} error={!!fieldState.error}>
                                    <InputLabel>{getLangString('TRANSPORT')}</InputLabel>
                                    <Select {...field}>
                                        {transports.data?.map((t) => (
                                            <MenuItem key={t.transportId} value={t.transportId}>
                                                {t.transportName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name={'validFrom'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <DatePicker
                                    {...field}
                                    views={['year']}
                                    label={'I produksjon fra år'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name={'comment'}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label={getLangString('COMMENT')}
                                    rows={3}
                                    fullWidth={true}
                                    multiline={true}
                                    sx={inputStyle}
                                />
                            )}
                        />
                    </Box>
                    <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={handleClose} variant={'outlined'} sx={{mr: 1}}>
                            {getLangString('CANCEL')}
                        </Button>
                        <Button type={'submit'} variant={'contained'}>
                            {getLangString('SAVE')}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            {inProgress && <CircularProgressOverlay />}
        </Dialog>
    );
};
