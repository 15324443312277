import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {AppInitializer} from 'components/state/AppInitializer';
import {ApplicationStateProvider} from 'lib/context/applicationState';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import {MsalSetup} from './lib/auth/b2c/MsalSetup';
import {MSAL_CONFIG} from './lib/config/AzureB2cConfig';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

// MSAL
export const msalInstance = new PublicClientApplication(MSAL_CONFIG);
MsalSetup(msalInstance);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <ApplicationStateProvider>
                    <AppInitializer>
                        <App />
                    </AppInitializer>
                </ApplicationStateProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </MsalProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
