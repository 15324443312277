import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import React from 'react';
import {useGetReportTexts} from '../language/useGetReportTexts';

export type ReportDataSet = 'packagingResults' | 'emissionResults' | 'valueChainEmissions';
export const DataTypeButtonGroup: React.FC<{
    currentTab: ReportDataSet;
    handleTabChange: (newValue: ReportDataSet) => void;
}> = ({currentTab, handleTabChange}) => {
    const {getReportDataSetText} = useGetReportTexts();

    const handleChange = (event: React.MouseEvent<HTMLElement>, newTab: ReportDataSet) => {
        if (newTab !== null) {
            handleTabChange(newTab);
        }
    };
    return (
        <ToggleButtonGroup value={currentTab} exclusive={true} onChange={handleChange}>
            <ToggleButton value={'packagingResults'}>{getReportDataSetText('packagingResults')}</ToggleButton>
            <ToggleButton value={'emissionResults'}>{getReportDataSetText('emissionResults')}</ToggleButton>
            <ToggleButton value={'valueChainEmissions'}>{getReportDataSetText('valueChainEmissions')}</ToggleButton>
        </ToggleButtonGroup>
    );
};
