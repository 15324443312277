import {ArrowDropDown} from '@mui/icons-material';
import {Box, Breadcrumbs, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    exportProductGroupKeyFigures,
    exportProductKeyFigures,
    exportProductTypeKeyFigures,
} from '../../api/handlers/keyFigures';
import {useGetKeyFigures, useGetKeyFiguresByGroup, useGetKeyFiguresByType} from '../../api/hooks/report';
import {useLanguage} from '../../language/LanguageProvider';
import {useGetReportTexts} from '../../language/useGetReportTexts';
import {handleFileDownload} from '../../utils/handleFileDownload';
import {ButtonWithMenu} from '../ButtonWithMenu';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {LinkRouter} from '../LinkRouter';
import {useReportContext} from '../views/ReportsPage';
import {getReportType} from './getReportType';
import {ReportsContent} from './ReportsContent';

const HeaderText: Record<ReportDataSet, string> = {
    packagingResults: 'Kg emballasje per tonn produkt',
    emissionResults: 'Kg CO2-ekv. per tonn produkt',
    valueChainEmissions: 'Kg CO2-ekv. per tonn produkt',
};

export const KeyFiguresContent: React.FC = () => {
    const {productTypeID, productGroupID} = useParams();
    const {currentDataType, calcGroupValue, productGroupName, productTypeName, exportPDF, isExportingPDF} =
        useReportContext();
    const navigate = useNavigate();
    const {getLangString} = useLanguage();
    const {getCalculationGroupText, getReportDataSetText} = useGetReportTexts();
    const currentCompanyId = useCurrentCompanyId();

    const constructFileName = (groupName: string | undefined) => {
        return (
            getLangString('KEY_FIGURES') +
            '_' +
            groupName +
            '_' +
            getReportDataSetText(currentDataType) +
            '_' +
            getCalculationGroupText(calcGroupValue) +
            '.xlsx'
        );
    };

    const keyFiguresMainProductGroups = useGetKeyFigures(
        currentCompanyId,
        !productGroupID && !productTypeID && currentCompanyId > 0,
    );
    const exportKeyFiguresMainGroup = useQuery(
        ['exportMainGroup'],
        () =>
            exportProductKeyFigures({
                companyId: currentCompanyId,
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportMainGroup = () => {
        exportKeyFiguresMainGroup.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName('hovedgrupper'));
        });
    };
    const keyFiguresProductGroups = useGetKeyFiguresByType(
        currentCompanyId,
        parseInt(productTypeID!),
        !productGroupID && !!productTypeID && currentCompanyId > 0,
    );
    const exportKeyFiguresProductGroups = useQuery(
        ['exportProductGroupType'],
        () =>
            exportProductTypeKeyFigures({
                companyId: currentCompanyId,
                productTypeId: parseInt(productTypeID!),
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportProductGroupType = () => {
        exportKeyFiguresProductGroups.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName(productTypeName));
        });
    };
    const keyFiguresProducts = useGetKeyFiguresByGroup(
        currentCompanyId,
        parseInt(productGroupID!),
        !!productGroupID && !!productTypeID && currentCompanyId > 0,
    );
    const exportKeyFiguresProducts = useQuery(
        ['exportProductGroup'],
        () =>
            exportProductGroupKeyFigures({
                companyId: currentCompanyId,
                productGroupId: parseInt(productGroupID!),
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportProductGroup = () => {
        exportKeyFiguresProducts.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName(productGroupName));
        });
    };
    const getExportButton = (exportExcelFunc: () => void) => (
        <ButtonWithMenu
            data-html2canvas-ignore='true'
            buttonText={getLangString('EXPORT')}
            buttonProps={{
                endIcon: <ArrowDropDown />,
                size: 'medium',
                variant: 'outlined',
            }}
            loading={isExportingPDF}
            menuItems={[
                {name: 'PDF', onClick: exportPDF},
                {name: 'Excel', onClick: exportExcelFunc},
            ]}
        />
    );

    let content;

    if (!!productGroupID && !!productTypeID) {
        content = (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs>
                        <LinkRouter to={`/reports/keyFigures`}>{getLangString('PRIMARY_GROUPS')}</LinkRouter>
                        <LinkRouter to={`/reports/keyFigures/${productTypeID}`}>{productTypeName}</LinkRouter>
                        <Typography>{productGroupName}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportProductGroup)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={keyFiguresProducts}
                />
            </>
        );
    } else if (productTypeID) {
        const onChartClick = (productGroupId: number) => {
            navigate(`/reports/keyFigures/${productTypeID}/${productGroupId}`);
        };
        content = (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs>
                        <LinkRouter to={`/reports/keyFigures`}>{getLangString('PRIMARY_GROUPS')}</LinkRouter>
                        <Typography>{productTypeName}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportProductGroupType)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={keyFiguresProductGroups}
                    onChartClick={onChartClick}
                />
            </>
        );
    } else {
        const onChartClick = (productTypeID: number) => {
            navigate(`/reports/keyFigures/${productTypeID}`);
        };
        content = (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs>
                        <Typography variant={'body1'}>{getLangString('PRIMARY_GROUPS')}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportMainGroup)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={keyFiguresMainProductGroups}
                    onChartClick={onChartClick}
                />
            </>
        );
    }
    return <Box mt={4}>{content}</Box>;
};
