import {Tab, Tabs} from '@mui/material';
import React from 'react';
import {NavLink} from 'react-router-dom';

type SubNavBarProps = {
    onChange: (e: React.SyntheticEvent, value: string) => void;
    activeTab: string;
    tabs: {value: string; label: string}[];
};
export const SubNavBar: React.FC<SubNavBarProps> = (props) => {
    return (
        <Tabs
            onChange={props.onChange}
            value={props.activeTab}
            textColor='inherit'
            aria-label='secondary navigation'
            TabIndicatorProps={{sx: {backgroundColor: 'transparent'}}}
        >
            {props.tabs.map((tab) => (
                <Tab
                    key={tab.label}
                    value={tab.value}
                    label={tab.label}
                    component={NavLink}
                    to={tab.value}
                    sx={{'&.Mui-selected': {fontFamily: 'Inter-Bold'}, '&:hover': {color: '#000'}}}
                />
            ))}
        </Tabs>
    );
};
