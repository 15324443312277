import {Add, Cancel, Edit, Save} from '@mui/icons-material';
import {Box, Button, CircularProgress, TextField, Theme, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CalculateAnalysisResponse, UpdateAnalysisProduct} from '../../api/dtos/analysisDto';
import {useGetAnalysisById, useMakeCalculations, useUpdateAnalysis, useUpdateAnalysisHeader} from '../../api/hooks/analysis';
import {useLanguage} from '../../language/LanguageProvider';
import {AnalysisProductForm, AnalysisProductFormFields} from '../analysis/AnalysisProductForm';
import {AnalysisProductItem} from '../analysis/AnalysisProductItem';
import {AnalysisResults} from '../analysis/AnalysisResults';
import {SelectExistingProduct} from '../analysis/SelectExistingProduct';
import {BackButton} from '../BackButton';
import {ButtonWithLoading} from '../ButtonWithLoading';

export const AnalysisPage: React.FC = () => {
    const {analysisID} = useParams();
    const {getLangString} = useLanguage();
    const currentAnalysis = useGetAnalysisById(Number(analysisID));
    const [addExisting, setAddExisting] = useState<boolean>();
    const toggleAddExisting = () => setAddExisting(!addExisting);

    const [addNew, setAddNew] = useState<boolean>();
    const [addCopy, setAddCopy] = useState<boolean>();

    const [selectedProducts, setSelectedProducts] = useState<UpdateAnalysisProduct[]>([]);
    const [results, setResults] = useState<CalculateAnalysisResponse[] | undefined>();

    const updateAnalysis = useUpdateAnalysis();
    const updateAnalysisHeader = useUpdateAnalysisHeader();

    const [newAnalysisName, setNewAnalysisName] = useState("");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    
    useEffect(() => {
        if (currentAnalysis.data) {
            const analysisData: UpdateAnalysisProduct[] = currentAnalysis.data.products.map((p) => ({
                productId: p.productId,
                productName: p.productName,
                productGroupId: p.productGroup.productGroupId,
                transportId: p.transport.transportId,
                netWeightGrams: p.netWeightGrams,
                fpakDpak: p.fpakDpak,
                dpakPallet: p.dpakPallet,
                wastePercentage: p.wastePercentage,
                comment: p.comment,
                productPackagings: p.productPackagings,
            }));
            setSelectedProducts(analysisData);
            setAddExisting(false);
        }
    }, [currentAnalysis.data]);

    const performAnalysis = useMakeCalculations();
    const doAnalyze = async () => {
        const resp = await performAnalysis.mutateAsync(Number(analysisID));
        setResults(resp);
        resultsRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const resultsRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (results) {
            resultsRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    }, [resultsRef, results]);

    if (currentAnalysis.isLoading) {
        return <CircularProgress />;
    }
    if (currentAnalysis.isError) {
        return <Box>Noe feilet</Box>; //TODO: update error message
    }
    const handleSelectProduct = async (product: UpdateAnalysisProduct) => {
        const newProducts = [...selectedProducts, product];

        await updateAnalysis.mutateAsync({
            analysisId: Number(analysisID),
            dto: {
                analysisName: currentAnalysis.data.analysisName,
                description: currentAnalysis.data.description,
                products: newProducts,
            },
        });
    };

    const handleRemoveProduct = async (productId: string) => {
        if (selectedProducts) {
            const newProducts = selectedProducts.filter((ap) => ap.productId !== productId);
            await updateAnalysis.mutateAsync({
                analysisId: Number(analysisID),
                dto: {
                    analysisName: currentAnalysis.data.analysisName,
                    description: currentAnalysis.data.description,
                    products: newProducts,
                },
            });
        }
    };
    const handleUpdateProduct = async (product: AnalysisProductFormFields) => {
        const index = selectedProducts.findIndex((el) => el.productId === product.productId);
        if (product) {
            selectedProducts[index] = {
                ...product,
                fpakDpak: product.fpakDpak!,
                dpakPallet: product.dpakPallet!,
                netWeightGrams: product.netWeightGrams!,
                transportId: product.transportId!,
                productGroupId: product.productGroupId!,
                wastePercentage: product.wastePercentage! / 100,
            };

            await updateAnalysis.mutateAsync({
                analysisId: Number(analysisID),
                dto: {
                    analysisName: currentAnalysis.data.analysisName,
                    description: currentAnalysis.data.description,
                    products: selectedProducts,
                },
            });
        }
    };
    const handleAddProduct = async (product: AnalysisProductFormFields) => {
        if (product) {
            const newProducts = [
                ...selectedProducts,
                {
                    ...product,
                    fpakDpak: product.fpakDpak!,
                    dpakPallet: product.dpakPallet!,
                    netWeightGrams: product.netWeightGrams!,
                    transportId: product.transportId!,
                    productGroupId: product.productGroupId!,
                    wastePercentage: product.wastePercentage! / 100,
                },
            ];

            await updateAnalysis.mutateAsync({
                analysisId: Number(analysisID),
                dto: {
                    analysisName: currentAnalysis.data.analysisName,
                    description: currentAnalysis.data.description,
                    products: newProducts,
                },
            });

            setAddCopy(false);
            setAddNew(false);
        }
    };
    const addNewProduct = () => {
        setAddNew(true);
    };

    const addCopyOfPrevious = () => {
        setAddCopy(true);
    };

    const handleEditName = () => {
        setIsEditing(true)
        setNewAnalysisName(currentAnalysis.data.analysisName);
    }

    const handleCancelEditName = () => {
        setIsEditing(false)
    }

    const handleSaveEditName = async () => {
        await updateAnalysisHeader.mutateAsync({
            analysisId: Number(analysisID),
            dto: {
                analysisName: newAnalysisName,
                description: currentAnalysis.data.description
            }
        })
        setIsEditing(false)
    }

    return (
        <>
            <Box mt={2} maxWidth={1500}>
                <BackButton text={getLangString('ANALYSES')} />
                <Box display={'flex'} justifyContent={'space-between'} my={2}>

                    {isEditing ? 
                    <div style={{display: 'flex'}}>
                        <TextField
                            onChange={(e) => setNewAnalysisName(e.target.value)}
                            defaultValue={newAnalysisName}
                        >
                        </TextField>
                    <Button
                        onClick={handleSaveEditName}
                        startIcon={<Save />}
                        size={'small'}
                        variant={'outlined'}
                        sx={{ml: 2,
                        alignContent: 'center'}}
                    >
                        {getLangString('SAVE_CHANGES')}
                    </Button>
                    <Button
                        onClick={handleCancelEditName}
                        startIcon={<Cancel/>}
                        size={'small'}
                        variant={'outlined'}
                        color={'error'}
                        sx={{ml: 2,
                        alignContent: 'center'}}
                    >
                        {getLangString('CANCEL')}
                    </Button>
                    </div>
                    : 
                        <Typography 
                        variant={'h2'}
                        sx={{cursor: 'pointer'}}
                        onClick={handleEditName} 
                        >
                        {currentAnalysis.data.analysisName}
                        <Edit
                            color={'inherit'}
                            fontSize={'small'}
                            className={'editable-indicator'}
                            sx={(theme: Theme) => ({ml: 1.5, color: theme.palette.grey['500']})}
                        />
                        </Typography>
                    }
                    <Typography>{new Date(currentAnalysis.data.createdAt).toLocaleDateString()}</Typography>
                </Box>
                <Box mb={3}>
                    <Typography>{currentAnalysis.data.description}</Typography>
                </Box>
                {selectedProducts.map((product) => (
                    <AnalysisProductItem
                        key={product.productId}
                        product={product}
                        handleUpdate={handleUpdateProduct}
                        handleRemove={handleRemoveProduct}
                        defaultExpanded={true}
                    />
                ))}
                {addNew && (
                    <Box
                        sx={{
                            borderRadius: 1,
                            mt: 4,
                            p: 2,
                            backgroundColor: 'rgba(200,211,204,0.13)',
                        }}
                    >
                        <Typography pb={2} variant={'h5'}>
                            {getLangString('ADD_NEW_PRODUCT')}
                        </Typography>
                        <AnalysisProductForm
                            baseProduct={{
                                productName: '',
                                productGroupId: undefined,
                                transportId: undefined,
                                netWeightGrams: undefined,
                                fpakDpak: undefined,
                                dpakPallet: undefined,
                                wastePercentage: undefined,
                                comment: '',
                                productPackagings: [],
                            }}
                            handleSave={handleAddProduct}
                            handleCancel={() => setAddNew(false)}
                            isNewProduct={true}
                        />
                    </Box>
                )}
                {addCopy && (
                    <Box
                        sx={{
                            borderRadius: 1,
                            mt: 4,
                            p: 2,
                            backgroundColor: 'rgba(200,211,204,0.13)',
                        }}
                    >
                        <Typography pb={2} variant={'h5'}>
                            {getLangString('ADD_NEW_PRODUCT')}
                        </Typography>
                        <AnalysisProductForm
                            baseProduct={{
                                productName: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].productName,
                                productGroupId: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].productGroup.productGroupId,
                                transportId: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].transport.transportId,
                                netWeightGrams: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].netWeightGrams,
                                fpakDpak: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].fpakDpak,
                                dpakPallet: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].dpakPallet,
                                wastePercentage: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].wastePercentage,
                                comment: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].comment,
                                productPackagings: currentAnalysis.data.products[currentAnalysis.data.products.length - 1].productPackagings,
                            }}
                            handleSave={handleAddProduct}
                            handleCancel={() => setAddCopy(false)}
                            isNewProduct={true}
                        />
                    </Box>
                )}
                <Box my={3}>
                    {addExisting && (
                        <Box sx={{border: 1, borderColor: 'rgba(0,0,0,0.1)', borderRadius: 1, p: 2, pb: 3}}>
                            <Typography variant={'subtitle1'} mb={1}>
                                {getLangString('SELECT_FROM_PRODUCT_LIST')}
                            </Typography>
                            <SelectExistingProduct onSelectProduct={handleSelectProduct} onCancel={toggleAddExisting} />
                        </Box>
                    )}
                    {!addExisting && !addNew && !addCopy && (
                        <>
                            <Button onClick={toggleAddExisting} startIcon={<Add />} size={'large'} variant={'outlined'}>
                                {getLangString('SELECT_FROM_PRODUCT_LIST')}
                            </Button>
                            <Button
                                onClick={addNewProduct}
                                startIcon={<Add />}
                                size={'large'}
                                variant={'outlined'}
                                sx={{ml: 2}}
                            >
                                {getLangString('ADD_NEW_PRODUCT')}
                            </Button>
                        </>
                    )}
                    {!addExisting && !addNew && !addCopy && currentAnalysis.data.products.length > 0 && (
                        <Button
                            onClick={addCopyOfPrevious}
                            startIcon={<Add />}
                            size={'large'}
                            variant={'outlined'}
                            sx={{ml: 2}}
                        >
                            {getLangString('CREATE_PRODUCT_COPY_PREVIOUS')}
                        </Button>
                    )}
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    {!results && (
                        <ButtonWithLoading
                            buttonProps={{
                                onClick: doAnalyze,
                                variant: 'contained',
                                size: 'large',
                                disabled: selectedProducts.length === 0,
                                sx: {width: 200},
                                children: getLangString('ANALYZE'),
                            }}
                            loading={performAnalysis.isLoading}
                            loadingIconColor={'#fff'}
                            loadingBGColor={'rgba(0,0,0,0.3)'}
                        />
                    )}
                </Box>
            </Box>
            <Box my={6} minHeight={1000} ref={resultsRef}>
                {results && (
                    <AnalysisResults
                        resultsData={results}
                        onRefresh={doAnalyze}
                        analysisId={currentAnalysis.data.analysisId}
                        analysisTitle={currentAnalysis.data.analysisName}
                        analysisText={currentAnalysis.data.description}
                    />
                )}
            </Box>
        </>
    );
};
