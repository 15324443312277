import {Delete} from '@mui/icons-material';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { useCurrentCompanyId } from 'lib/hooks/useCurrentCompany';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {UpdateAnalysisProduct, UpdateAnalysisProductPackaging} from '../../api/dtos/analysisDto';

import {UpdateProductPackaging} from '../../api/dtos/productConfigDto';
import {useGetProductGroups} from '../../api/hooks/product';
import {useGetTransport} from '../../api/hooks/transports';
import {useLanguage} from '../../language/LanguageProvider';
import {MaterialsTable} from '../registry/MaterialsTable';
type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

type BaseProductType = MakeOptional<
    UpdateAnalysisProduct,
    'transportId' | 'netWeightGrams' | 'productGroupId' | 'fpakDpak' | 'dpakPallet' | 'productId' | 'wastePercentage'
>;
export type AnalysisProductFormFields = {
    productName: string;
    productGroupId?: number;
    transportId?: number;
    netWeightGrams?: number;
    fpakDpak?: number;
    dpakPallet?: number;
    wastePercentage?: number; //decimal
    comment: string;
    productPackagings: UpdateAnalysisProductPackaging[];
    productId?: string; //defined if product already exists, undefined if new product
};
export const AnalysisProductForm: React.FC<{
    baseProduct: BaseProductType;
    handleSave: (form: AnalysisProductFormFields) => void;
    isNewProduct: boolean;
    handleDelete?: (productId: string) => void;
    handleCancel?: () => void;
}> = ({baseProduct, handleDelete, handleSave, isNewProduct, handleCancel}) => {
    const {getLangString} = useLanguage();
    const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false);
    const getProductPackagings = () => {
        return baseProduct.productPackagings.map((material, index) => ({
            packagingId: material.packagingId,
            packagingType: material.packagingType,
            netWeightGrams: material.netWeightGrams,
            recycablePercentage: material.recycablePercentage,
            comment: material.comment,
            productPackagingId: index + 1,
        }));
    };   
    
    const currentCompanyId = useCurrentCompanyId();

    const transports = useGetTransport(currentCompanyId);
    const productGroups = useGetProductGroups(currentCompanyId);

    const defaultValues: AnalysisProductFormFields = {
        ...baseProduct,
        productPackagings: getProductPackagings(),
        wastePercentage: (baseProduct.wastePercentage && baseProduct.wastePercentage * 100) || 0,
    };

    const {handleSubmit, control, setValue, formState, reset, getValues, trigger} = useForm<AnalysisProductFormFields>({
        defaultValues,
    });
    const handleDeleteClick = async () => {
        if (baseProduct.productId && handleDelete) {
            setIsBeingDeleted(true);
            await handleDelete(baseProduct.productId);
            setIsBeingDeleted(false);
        }
    };
    const handleSaveClick = async (form: AnalysisProductFormFields) => {
        await handleSave(form);
        reset(form);
    };

    const updateMaterial = (productPackagingId: number, data: UpdateProductPackaging) => {
        const p = getValues('productPackagings');
        const index = p.findIndex((el) => el.productPackagingId === productPackagingId);
        p[index] = {...data, productPackagingId};
        setValue('productPackagings', p, {shouldDirty: true});
        trigger('productPackagings');
    };
    const [packagings, setPackagings] = useState(getValues('productPackagings'));
    const addMaterial = (data: UpdateProductPackaging) => {
        const p = getValues('productPackagings');
        p.push({...data, productPackagingId: p.length + 1});
        setValue('productPackagings', p, {shouldDirty: true});
        trigger('productPackagings');
        setPackagings(p);
    };
    const removeMaterial = (productPackagingId: number) => {
        const p = getValues('productPackagings');
        const filteredP = p.filter((el) => el.productPackagingId !== productPackagingId);
        setValue('productPackagings', filteredP, {shouldDirty: true});
        trigger('productPackagings');
        setPackagings(filteredP);
    };

    const commonRules = {required: getLangString('FIELD_IS_REQUIRED')};
    return (
        <Box sx={{opacity: isBeingDeleted ? 0.5 : 1}}>
            <form onSubmit={handleSubmit(handleSaveClick)}>
                <Box display={'flex'} mt={1}>
                    <Box mr={3} sx={{'& > div': {mt: 1.5}, '& > div:first-of-type': {mt: 0}}}>
                        {/*{isNewProduct && (*/}
                        <>
                            <Controller
                                name={'productName'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        fullWidth={true}
                                        label={getLangString('PRODUCT_NAME')}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />

                            <Controller
                                name={'productGroupId'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <FormControl fullWidth={true} error={!!fieldState.error}>
                                        <InputLabel>{getLangString('PRODUCT_GROUP')}</InputLabel>
                                        <Select {...field}>
                                            {productGroups.data?.map((t) => (
                                                <MenuItem key={t.productGroupId} value={t.productGroupId}>
                                                    {t.productGroupName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </>
                        {/*)}*/}
                        <Box>
                            <Controller
                                name={'netWeightGrams'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('NET_WEIGHT')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>gram</InputAdornment>,
                                            inputProps: {min: 0},
                                        }}
                                        sx={{width: 230, mr: 1}}
                                    />
                                )}
                            />
                            <Controller
                                name={'wastePercentage'}
                                control={control}
                                rules={{
                                    ...commonRules,
                                    max: {
                                        value: 100,
                                        message: 'Verdi kan ikke være høyere enn 100',
                                    },
                                }}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('PRODUCT_WASTE')}
                                        type={'number'}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                            inputProps: {min: 0, max: 100},
                                        }}
                                        sx={{width: 230}}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name={'fpakDpak'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('FPAK_DPAK_AMOUNT')}
                                        type={'number'}
                                        InputProps={{
                                            inputProps: {min: 0},
                                        }}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{width: 230, mr: 1}}
                                    />
                                )}
                            />
                            <Controller
                                name={'dpakPallet'}
                                control={control}
                                rules={commonRules}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label={getLangString('DPAK_PALLET_AMOUNT')}
                                        type={'number'}
                                        InputProps={{
                                            inputProps: {min: 0},
                                        }}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{width: 230}}
                                    />
                                )}
                            />
                        </Box>

                        <Controller
                            name={'transportId'}
                            control={control}
                            rules={commonRules}
                            render={({field, fieldState}) => (
                                <FormControl fullWidth={true} error={!!fieldState.error}>
                                    <InputLabel>{getLangString('SELECT_TRANSPORT')}</InputLabel>
                                    <Select
                                        {...field}
                                        value={field.value || ''}
                                        renderValue={field.value ? undefined : () => <div>Velg transport</div>}
                                    >
                                        {transports.data?.map((t) => (
                                            <MenuItem key={t.transportId} value={t.transportId}>
                                                {t.transportName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Box>
                    <MaterialsTable
                        productPackagings={packagings}
                        handleUpdateMaterial={updateMaterial}
                        handleCreateMaterial={addMaterial}
                        handleDeleteMaterial={removeMaterial}
                    />
                </Box>
                <Box mt={2} display={'flex'} justifyContent={isNewProduct ? 'flex-end' : 'space-between'}>
                    {isNewProduct ? (
                        <>
                            <Button variant={'outlined'} color={'error'} onClick={handleCancel} sx={{mr: 1}}>
                                {getLangString('CANCEL')}
                            </Button>
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                disabled={!(getValues('productPackagings').length > 0 && formState.isDirty)}
                            >
                                {getLangString('SAVE')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant={'text'}
                                color={'error'}
                                onClick={handleDeleteClick}
                                sx={{mr: 1}}
                                disabled={isBeingDeleted}
                                startIcon={<Delete />}
                            >
                                {getLangString('REMOVE') + ' produkt'}
                            </Button>
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                disabled={!(getValues('productPackagings').length > 0 && formState.isDirty)}
                            >
                                {getLangString('SAVE_CHANGES')}
                            </Button>
                        </>
                    )}
                </Box>
            </form>
        </Box>
    );
};
