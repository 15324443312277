import {PackagingHandlingCapability} from '../api/dtos/productConfigDto';
import {useLanguage} from './LanguageProvider';

export const usePackagingHandlingCapabilityText = () => {
    const {getLangString} = useLanguage();
    const handlingCapabilities: Record<PackagingHandlingCapability, string> = {
        reuse: getLangString('REUSED'),
        material_recycling: getLangString('MATERIAL_RECYCLING'),
        energy_recycling: getLangString('ENERGY_RECYCLING'),
        composting: getLangString('COMPOSTING'),
    };

    const getPackagingHandlingCapabilityText = (handlingCapability: PackagingHandlingCapability) =>
        handlingCapabilities[handlingCapability];
    const getPackagingHandlingOptions = () => Object.keys(handlingCapabilities);

    return {getPackagingHandlingCapabilityText, getPackagingHandlingOptions};
};
