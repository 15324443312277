import {useMutation, useQueryClient} from '@tanstack/react-query';
import {FC} from 'react';
import {ProductPackaging, UpdateProductPackaging} from '../../api/dtos/productConfigDto';
import {
    createProductConfigPackaging,
    deleteProductConfigPackaging,
    updateProductConfigPackaging,
} from '../../api/handlers/productConfig';
import {useSnackBar} from '../SnackBarProvider';
import {MaterialsTable} from './MaterialsTable';

export const ProductConfigMaterialsTable: FC<{productPackagings: ProductPackaging[]; productConfigId: number}> = ({
    productPackagings,
    productConfigId,
}) => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    const updatePackaging = useMutation(updateProductConfigPackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['productConfig', productConfigId]);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const createPackaging = useMutation(createProductConfigPackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['productConfig', productConfigId]);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
    const deletePackaging = useMutation(deleteProductConfigPackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['productConfig', productConfigId]);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });

    const handleCreate = async (data: UpdateProductPackaging) => {
        await createPackaging.mutateAsync({
            productConfigId,
            data,
        });
    };
    const handleUpdate = async (productPackagingId: number, data: UpdateProductPackaging) => {
        await updatePackaging.mutateAsync({
            productConfigId,
            productPackagingId,
            data,
        });
    };
    const handleDelete = async (productPackagingId: number) => {
        await deletePackaging.mutateAsync({productConfigId, productPackagingId});
    };

    return (
        <MaterialsTable
            productPackagings={productPackagings}
            handleCreateMaterial={handleCreate}
            handleUpdateMaterial={handleUpdate}
            handleDeleteMaterial={handleDelete}
        />
    );
};
