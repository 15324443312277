import {Box, Divider, FormControlLabel, ListItem, Radio, RadioGroup, Tab, Tabs} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import React, {ChangeEvent, useRef, useState} from 'react';
import {NavLink, Outlet, useOutletContext, useParams} from 'react-router-dom';
import {WeightedCalculationGroup} from '../../api/dtos/reportsDto';
import {getProductGroupByID, getProductGroupTypeByID} from '../../api/handlers/productGroup';
import {useLanguage} from '../../language/LanguageProvider';
import {useGetReportTexts} from '../../language/useGetReportTexts';
import {useCurrentCompanyId} from '../../lib/hooks/useCurrentCompany';
import {getParagraphs} from '../../utils/createParagraphs';
import {generatePDF} from '../../utils/generatePDF';
import {DataTypeButtonGroup, ReportDataSet} from '../DataTypeButtonGroup';

type ReportType = 'annualReport' | 'keyFigures';
type ReportContextType = {
    report: ReportType;
    currentDataType: ReportDataSet;
    calcGroupValue: keyof WeightedCalculationGroup;
    productGroupName: string | undefined;
    productTypeName: string | undefined;
    exportPDF: () => void;
    isExportingPDF: boolean;
};

export const useReportContext = () => useOutletContext<ReportContextType>();

const ReportTab: React.FC<{currentTab: ReportType}> = ({currentTab}) => {
    const {getLangString} = useLanguage();

    return (
        <>
            <Tabs
                value={currentTab}
                textColor='inherit'
                aria-label='secondary navigation'
                TabIndicatorProps={{sx: {backgroundColor: 'transparent'}}}
            >
                <Tab
                    value={'keyFigures'}
                    label={getLangString('KEY_FIGURES')}
                    component={NavLink}
                    to={'/reports/keyFigures'}
                    sx={{'&.Mui-selected': {fontFamily: 'Inter-Bold'}, '&:hover': {color: '#000'}}}
                />
                <Tab
                    value={'annualReport'}
                    label={getLangString('ANNUAL_REPORT')}
                    component={NavLink}
                    to={'/reports/annualReport'}
                    sx={{'&.Mui-selected': {fontFamily: 'Inter-Bold'}, '&:hover': {color: '#000'}}}
                />
            </Tabs>
            <Divider sx={{mt: 1}} />
        </>
    );
};

const Report: React.FC<{currentTab: ReportType}> = ({currentTab}) => {
    const [currentDataType, setCurrentDataType] = useState<ReportDataSet>('packagingResults');
    const {productTypeID, productGroupID} = useParams();
    const {getLangString} = useLanguage();
    const handleTabChange = (selectedTab: ReportDataSet) => {
        setCurrentDataType(selectedTab);
    };
    const [calcGroupValue, setCalcGroupValue] = React.useState<keyof WeightedCalculationGroup>('total');
    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCalcGroupValue(event.target.value as keyof WeightedCalculationGroup);
    };
    const currentCompanyId = useCurrentCompanyId();
    const productGroupType = useQuery(
        ['productGroupType', productTypeID],
        () => getProductGroupTypeByID(parseInt(productTypeID!)),
        {enabled: !!productTypeID && currentCompanyId > 0},
    );
    const productGroup = useQuery(
        ['productGroup', productGroupID],
        () => getProductGroupByID(parseInt(productGroupID!)),
        {enabled: !!productGroupID && currentCompanyId > 0},
    );

    const productTypeName = productGroupType.data?.productGroupTypeName;
    const productGroupName = productGroup.data?.productGroupName;

    const componentToPrintRef = useRef<HTMLDivElement | null>(null);
    const [isCreatingPDF, setIsCreatingPDF] = useState<boolean>(false);
    const reportTypeString =
        currentTab === 'keyFigures' ? getLangString('KEY_FIGURES') : getLangString('ANNUAL_REPORT');
    const getReportContentText = (): string => {
        if (currentDataType === 'emissionResults') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_PACKAGING');
        } else if (currentDataType === 'valueChainEmissions') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN');
        } else {
            if (currentTab === 'annualReport') {
                return getLangString('TEXT_PACKAGING_CONSUMPTION_ANNUAL_REPORT');
            }
            return getLangString('TEXT_PACKAGING_CONSUMPTION_KEY_FIGURES');
        }
    };

    const createPdfFileName = (): string => {
        let groupName;
        if (!!productGroupID && !!productTypeID) {
            groupName = productGroupName || '';
        } else if (productTypeID) {
            groupName = productTypeName || '';
        } else {
            groupName = getLangString('PRIMARY_GROUPS');
        }
        return reportTypeString + '_' + groupName + '_' + currentDataType;
    };
    const getReportTitleText = (): string => {
        let dataTypeText = '';
        if (currentDataType === 'packagingResults') {
            dataTypeText = getLangString('PACKAGING_CONSUMPTION');
        } else if (currentDataType === 'emissionResults') {
            dataTypeText = getLangString('CLIMATE_FOOTPRINT_PACKAGING');
        } else {
            dataTypeText = getLangString('CLIMATE_FOOTPRINT_VALUE');
        }
        return reportTypeString + ' - ' + dataTypeText;
    };
    const generateAndDownloadPDF = async () => {
        if (componentToPrintRef && componentToPrintRef.current) {
            setIsCreatingPDF(true);
            await generatePDF(
                componentToPrintRef.current,
                createPdfFileName(),
                getReportTitleText(),
                getReportContentText(),
            );
            setIsCreatingPDF(false);
        }
    };
    const reportTextPart1 = () => {
        if (currentDataType === 'emissionResults') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART1__FORMAT');
        }
        if (currentDataType === 'valueChainEmissions') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART1__FORMAT');
        }
        return '';
    };
    const reportTextPart2 = () => {
        if (currentDataType === 'emissionResults') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART2');
        }
        if (currentDataType === 'valueChainEmissions') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART2');
        }
        return '';
    };
    const reportTextPart2Bullets = () => {
        if (currentDataType === 'emissionResults') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_PACKAGING_PART2_BULLETS');
        }
        if (currentDataType === 'valueChainEmissions') {
            return getLangString('TEXT_CLIMATE_FOOTPRINT_VALUE_CHAIN_PART2_BULLETS');
        }
        return '';
    };
    const {getCalculationGroupText} = useGetReportTexts();

    return (
        <Box my={3}>
            <ReportTab currentTab={currentTab} />
            <Box mt={4}>
                <DataTypeButtonGroup currentTab={currentDataType} handleTabChange={handleTabChange} />
            </Box>
            <Box ref={componentToPrintRef}>
                {currentDataType !== 'valueChainEmissions' && (
                    <RadioGroup
                        onChange={handleRadioChange}
                        value={calcGroupValue}
                        row
                        name='radio-buttons-group'
                        sx={{mt: 2, '& span': {fontSize: '0.875rem'}}}
                    >
                        <FormControlLabel value='total' control={<Radio />} label={getCalculationGroupText('total')} />
                        <FormControlLabel value='fpak' control={<Radio />} label={getCalculationGroupText('fpak')} />
                        <FormControlLabel value='dpak' control={<Radio />} label={getCalculationGroupText('dpak')} />
                        <FormControlLabel
                            value='pallet'
                            control={<Radio />}
                            label={getCalculationGroupText('pallet')}
                        />
                    </RadioGroup>
                )}
                <Outlet
                    context={{
                        currentDataType,
                        calcGroupValue,
                        productGroupName,
                        productTypeName,
                        exportPDF: generateAndDownloadPDF,
                        isExportingPDF: isCreatingPDF,
                    }}
                />
                <Box mt={7}>
                    <Divider />
                    <Box mt={4} maxWidth={960} data-html2canvas-ignore='true'>
                        {getParagraphs(reportTextPart1(), 'body1', 'https://ecoinvent.org/the-ecoinvent-database/')}
                        {getParagraphs(reportTextPart2(), 'body1')}
                        {getParagraphs(reportTextPart2Bullets(), 'body1').map((e, i) => (
                            <ListItem key={i}>{e}</ListItem>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export const ReportAnnualPage: React.FC = () => {
    return <Report currentTab={'annualReport'} />;
};

export const ReportKeyFiguresPage: React.FC = () => {
    return <Report currentTab={'keyFigures'} />;
};
