import {PublicClientApplication} from '@azure/msal-browser';
import {axiosInstance} from 'api/axiosInstance';
import {AxiosError, AxiosInstance, AxiosRequestConfig} from 'axios';

/**
 * How often we should refresh the header;
 * TIME IS MILLISECONDS
 */
const refreshTime = 1000 * 30;

/**
 * Axios interceptor for assigning MSAL authorization header.
 */
export const authHeaderInterceptor = {
    /**
     * A closure function that returns the interceptor function.
     * The closure handles the refresh times for the interceptor so that the token only refreshes
     * on interval.
     * The token is set as default headers on the provided http client.
     * @param msalInstance the msal instance to get the token and user account from
     * @param client the http client to assign the token
     * @param scopes the scopes for the auth MSAL request
     * @returns axios request object
     */
    request: (msalInstance: PublicClientApplication, client: AxiosInstance, scopes: string[]) => {
        // This timer is used to determine the refresh interval.
        // to prevent setting and calling MSAL's async function on every request.
        let timer = 0;

        // The interceptor handler
        return async (request: AxiosRequestConfig) => {
            const now = Date.now();

            if (timer !== 0 && now - timer < refreshTime) return request;

            timer = now;

            const msalRequest = {
                scopes,
                account: msalInstance.getAllAccounts()[0],
            };
            const {accessToken} = await msalInstance.acquireTokenSilent(msalRequest);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            console.log('REFRESHED TOKEN');

            return request;
        };
    },
    /**
     * Error handler if the interceptor fails.
     * @param error error from the response
     */
    onError: (error: AxiosError) => {
        console.error(error);
        throw error;
    },
};
