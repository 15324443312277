import {ArrowDropDown} from '@mui/icons-material';
import {Box, Breadcrumbs, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    exportProductGroupYearlyReport,
    exportProductTypeYearlyReport,
    exportProductYearlyReport,
} from '../../api/handlers/yearlyReport';
import {useGetAnnualReport, useGetAnnualReportByGroup, useGetAnnualReportByType} from '../../api/hooks/report';
import {useLanguage} from '../../language/LanguageProvider';
import {useGetReportTexts} from '../../language/useGetReportTexts';
import {handleFileDownload} from '../../utils/handleFileDownload';
import {ButtonWithMenu} from '../ButtonWithMenu';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {LinkRouter} from '../LinkRouter';
import {useReportContext} from '../views/ReportsPage';
import {getReportType} from './getReportType';
import {ReportsContent} from './ReportsContent';

const HeaderText: Record<ReportDataSet, string> = {
    packagingResults: 'Totalt kg emballasje per produkt',
    emissionResults: 'Totalt kg CO2-ekv. per produkt',
    valueChainEmissions: 'Totalt kg CO2-ekv. per produkt',
};

export const AnnualReportsContent: React.FC = () => {
    const {productTypeID, productGroupID} = useParams();
    const {currentDataType, calcGroupValue, productGroupName, productTypeName, exportPDF, isExportingPDF} =
        useReportContext();
    const navigate = useNavigate();
    const {getLangString} = useLanguage();
    const {getCalculationGroupText, getReportDataSetText} = useGetReportTexts();
    const currentCompanyId = useCurrentCompanyId();

    const constructFileName = (groupName: string | undefined) => {
        return (
            getLangString('ANNUAL_REPORT') +
            '_' +
            groupName +
            '_' +
            getReportDataSetText(currentDataType) +
            '_' +
            getCalculationGroupText(calcGroupValue) +
            '.xlsx'
        );
    };
    const annualReportMainProductGroups = useGetAnnualReport(
        currentCompanyId,
        !productGroupID && !productTypeID && currentCompanyId > 0,
    );
    const exportYRMainGroup = useQuery(
        ['exportARMainGroup'],
        () =>
            exportProductYearlyReport({
                companyId: currentCompanyId,
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportMainGroup = () => {
        exportYRMainGroup.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName('hovedgrupper'));
        });
    };

    const annualReportProductGroups = useGetAnnualReportByType(
        currentCompanyId,
        parseInt(productTypeID!),
        !productGroupID && !!productTypeID && currentCompanyId > 0,
    );

    const exportAnnualReportProductGroups = useQuery(
        ['exportARProductGroupType'],
        () =>
            exportProductTypeYearlyReport({
                companyId: currentCompanyId,
                productTypeId: parseInt(productTypeID!),
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportProductGroupType = () => {
        exportAnnualReportProductGroups.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName(productTypeName));
        });
    };
    const annualReportProducts = useGetAnnualReportByGroup(
        currentCompanyId,
        parseInt(productGroupID!),
        !!productGroupID && !!productTypeID && currentCompanyId > 0,
    );
    const exportAnnualReportProducts = useQuery(
        ['exportARProductGroup'],
        () =>
            exportProductGroupYearlyReport({
                companyId: currentCompanyId,
                productGroupId: parseInt(productGroupID!),
                reportType: getReportType(currentDataType, calcGroupValue),
            }),
        {enabled: false},
    );
    const handleExportProductGroup = () => {
        exportAnnualReportProducts.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName(productGroupName));
        });
    };
    const getExportButton = (exportExcelFunc: () => void) => (
        <ButtonWithMenu
            data-html2canvas-ignore='true'
            buttonText={getLangString('EXPORT')}
            buttonProps={{
                endIcon: <ArrowDropDown />,
                size: 'medium',
                variant: 'outlined',
            }}
            loading={isExportingPDF}
            menuItems={[
                {name: 'PDF', onClick: exportPDF},
                {name: 'Excel', onClick: exportExcelFunc},
            ]}
        />
    );

    if (!!productGroupID && !!productTypeID) {
        return (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs sx={{mt: 4}}>
                        <LinkRouter to={`/reports/annualReport`}>{getLangString('PRIMARY_GROUPS')}</LinkRouter>
                        <LinkRouter to={`/reports/annualReport/${productTypeID}`}>{productTypeName}</LinkRouter>
                        <Typography>{productGroupName}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportProductGroup)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={annualReportProducts}
                />
            </>
        );
    } else if (productTypeID) {
        const onChartClick = (productGroupId: number) => {
            navigate(`/reports/annualReport/${productTypeID}/${productGroupId}`);
        };
        return (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs sx={{mt: 4}}>
                        <LinkRouter to={`/reports/annualReport`}>{getLangString('PRIMARY_GROUPS')}</LinkRouter>
                        <Typography>{productTypeName}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportProductGroupType)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={annualReportProductGroups}
                    onChartClick={onChartClick}
                />
            </>
        );
    } else {
        const onChartClick = (productTypeID: number) => {
            navigate(`/reports/annualReport/${productTypeID}`);
        };
        return (
            <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Breadcrumbs sx={{mt: 4}}>
                        <Typography variant={'body1'}>{getLangString('PRIMARY_GROUPS')}</Typography>
                    </Breadcrumbs>
                    {getExportButton(handleExportMainGroup)}
                </Box>
                <ReportsContent
                    headerText={HeaderText[currentDataType]}
                    calcGroup={calcGroupValue}
                    dataType={currentDataType}
                    data={annualReportMainProductGroups}
                    onChartClick={onChartClick}
                />
            </>
        );
    }
};
