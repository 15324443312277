import {axiosInstance} from '../axiosInstance';
import {ProductVolumeDto, UpdateVolumeDto} from '../dtos/productVolumeDto';
import {productPath, volumePath} from '../paths';

export const getProductVolumeByYear = async (year: number, companyId: number) =>
    await axiosInstance
        .get<ProductVolumeDto[]>(`${productPath.volume()}?year=${year}&companyId=${companyId}`)
        .then((res) => res.data);

export const updateSalesVolume = async (data: {volumeId: number; volume: UpdateVolumeDto}) =>
    await axiosInstance.patch(`${volumePath.byId(data.volumeId)}`, data.volume);

export const createSalesVolume = async (data: UpdateVolumeDto) =>
    await axiosInstance.post(`${volumePath.base()}`, data);

export const importVolumeData = async (reqData: {year: number; data: FormData}) =>
    await axiosInstance.post(`${volumePath.import()}?year=${reqData.year}`, reqData.data);

export const exportVolumeData = async (reqData: {year: number; companyId: number}) =>
    await axiosInstance
        .get(`${volumePath.export()}?companyId=${reqData.companyId}&year=${reqData.year}`, {
            responseType: 'blob',
        })
        .then((resp) => resp.data);
