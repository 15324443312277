import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useSnackBar} from '../../components/SnackBarProvider';
import {
    createPackaging,
    createPackagingGroup,
    deletePackaging,
    deletePackagingGroup,
    getPackaging,
    getPackagingGroups,
    updatePackaging,
    updatePackagingGroup,
} from '../handlers/packaging';

export const useGetPackaging = (companyId: number) => {
    return useQuery(['allPackaging'], () => getPackaging(companyId), {enabled: companyId > 0});
};
export const useUpdatePackaging = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(updatePackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['allPackaging']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useCreatePackaging = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createPackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['allPackaging']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeletePackaging = () => {
    const queryClient = useQueryClient();
    return useMutation(deletePackaging, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['allPackaging']);
        },
    });
};

export const useGetPackagingGroups = (companyId: number) => {
    return useQuery(['packagingGroups'], () => getPackagingGroups(companyId), {enabled: companyId > 0});
};
export const useCreatePackagingGroup = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(createPackagingGroup, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['packagingGroups']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useUpdatePackagingGroup = () => {
    const queryClient = useQueryClient();
    const {addSnackBar} = useSnackBar();
    return useMutation(updatePackagingGroup, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['packagingGroups']);
        },
        onError: (error: Error) => {
            addSnackBar({children: error.message, severity: 'error'});
        },
    });
};
export const useDeletePackagingGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(deletePackagingGroup, {
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['packagingGroups']);
        },
    });
};
