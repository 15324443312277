import {WeightedCalculationGroup} from '../api/dtos/reportsDto';
import {ReportDataSet} from '../components/DataTypeButtonGroup';
import {useLanguage} from './LanguageProvider';

export const useGetReportTexts = () => {
    const {getLangString} = useLanguage();

    const calcGroupText: Record<keyof WeightedCalculationGroup, string> = {
        fpak: getLangString('FPAK'),
        dpak: getLangString('DPAK'),
        pallet: getLangString('PALLET'),
        total: getLangString('ALL'),
    };
    const getCalculationGroupText = (calcGroup: keyof WeightedCalculationGroup) => calcGroupText[calcGroup];

    const ReportDataSetText: Record<ReportDataSet, string> = {
        packagingResults: getLangString('PACKAGING_CONSUMPTION'),
        emissionResults: getLangString('CLIMATE_FOOTPRINT_PACKAGING'),
        valueChainEmissions: getLangString('CLIMATE_FOOTPRINT_VALUE'),
    };
    const getReportDataSetText = (reportDataSet: ReportDataSet) => ReportDataSetText[reportDataSet];

    return {getCalculationGroupText, getReportDataSetText};
};
