import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import {ChangeEvent, FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {DeclarationQuestion} from '../../api/dtos/declarationDto';
import {useLanguage} from '../../language/LanguageProvider';
import {QuestionButton} from '../QuestionButton';
import {Attachment} from '../UploadedItem';
import {DeclarationDocuments} from './DeclarationDocuments';
import {SimplePackagingTable} from './SimplePackagingTable';

export type DeclarationQuestionWithAnswer = DeclarationQuestion & {answer: string};
export const DeclarationContent: FC<{
    productId: number;
    localFiles: File[];
    addLocalFiles: (event: ChangeEvent<HTMLInputElement>) => void;
    removeLocalFile: (file: File) => void;
    declarationQuestionsWithAnswers: DeclarationQuestionWithAnswer[];
    updateWasteRegulation: (event: SelectChangeEvent, id: number) => void;
    onSave: () => void;
    attachments?: Attachment[];
    removeAttachment?: (attachment: Attachment) => void;
    isFormValid: boolean;
}> = (props) => {
    const {getLangString} = useLanguage();
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate('/declaration', {replace: true});
    };
    const getOptionElement = (dq: DeclarationQuestionWithAnswer) => {
        if (dq.answerOptions.length === 2) {
            //Ja/Nei-alternativ
            return (
                <RadioGroup
                    row={true}
                    value={dq.answer}
                    name='radio-buttons-group'
                    onChange={(e) => props.updateWasteRegulation(e, dq.wasteRegulationQuestionId)}
                >
                    {dq.answerOptions.map((option) => (
                        <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
            );
        }
        return (
            <Box display={'flex'} alignItems={'center'}>
                <Select
                    displayEmpty
                    value={dq.answer}
                    fullWidth
                    onChange={(event: SelectChangeEvent) =>
                        props.updateWasteRegulation(event, dq.wasteRegulationQuestionId)
                    }
                    sx={{width: '50%'}}
                >
                    <MenuItem disabled value=''>
                        <em>Velg ett av alternativene</em>
                    </MenuItem>
                    {dq.answerOptions.map((answerOption, index) => (
                        <MenuItem key={dq.wasteRegulationQuestionId + index} value={answerOption}>
                            {answerOption}
                        </MenuItem>
                    ))}
                </Select>
                {dq.helperText && (
                    <QuestionButton
                        content={
                            <Box maxWidth={500}>
                                {dq.helperText.map((h) => (
                                    <Box key={h.option} mb={2}>
                                        <Typography color={'primary'}>{h.option}</Typography>
                                        <Typography variant={'body2'}>{h.helperText}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        }
                    />
                )}
            </Box>
        );
    };

    return (
        <Grid container spacing={4} mb={14} alignItems='center' justifyContent='left'>
            <Grid item xs={12} lg={8} mt={2}>
                <Button onClick={navigateBack} startIcon={<ArrowBackSharpIcon fontSize='medium' />}>
                    {getLangString('PRODUCTS')}
                </Button>
            </Grid>
            <Grid item xs={12} lg={8}>
                <SimplePackagingTable productId={props.productId} />
            </Grid>
            <Grid item xs={12} lg={8}>
                <DeclarationDocuments
                    files={props.localFiles}
                    removeFile={props.removeLocalFile}
                    addFile={props.addLocalFiles}
                    attachments={props.attachments}
                    removeAttachment={props.removeAttachment}
                />
            </Grid>
            <Grid item xs={12} lg={8}>
                <Box>
                    {props.declarationQuestionsWithAnswers.map((dq) => (
                        <Box key={dq.question} mb={4}>
                            <Typography variant={'body1'} gutterBottom>
                                {dq.question}
                            </Typography>
                            {getOptionElement(dq)}
                        </Box>
                    ))}
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Button variant='contained' size={'large'} onClick={props.onSave} disabled={!props.isFormValid}>
                    {getLangString('SAVE')}
                </Button>
            </Grid>
        </Grid>
    );
};
