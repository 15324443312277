import {Box, Button, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import {FC, useMemo, useRef, useState} from 'react';
import {AnalysisReportType, CalculateAnalysisResponse} from '../../api/dtos/analysisDto';
import {ValueChainEmissionsResp} from '../../api/dtos/reportsDto';
import {exportAnalysis} from '../../api/handlers/analysis';
import {getPackagingGroups} from '../../api/handlers/packaging';
import {useLanguage} from '../../language/LanguageProvider';
import {useGetReportTexts} from '../../language/useGetReportTexts';
import {getChartColorsPackaging, getChartColorsValueChainEmission} from '../../utils/chartColors';
import {generatePDF} from '../../utils/generatePDF';
import {handleFileDownload} from '../../utils/handleFileDownload';
import {ButtonWithMenu} from '../ButtonWithMenu';
import {DataTypeButtonGroup, ReportDataSet} from '../DataTypeButtonGroup';
import {CircularProgressCentered} from '../ProgressComponents';
import {valueChainEmissionText} from '../reports/transformToChartData';
import {ChartDataPoints, StackedBarChart} from '../StackedBarChart';
import {AnalysisTableRowData, ResultsTable} from './ResultsTable';

const transformToAnalysisChartData = (
    data: CalculateAnalysisResponse[],
    dataType: ReportDataSet,
): ChartDataPoints[] => {
    if (dataType === 'valueChainEmissions') {
        return data.map((d) => ({
            label: d.productName,
            values: Object.keys(d.valueChainEmissions).map((value) => ({
                name: valueChainEmissionText[value as keyof ValueChainEmissionsResp],
                value: d.valueChainEmissions[value as keyof ValueChainEmissionsResp].toFixed(2),
            })),
        }));
    } else {
        return data.map((d) => ({
            label: d.productName,
            values: d.packagings.map((value) => {
                return {
                    name: value.packagingGroupName,
                    value: value[dataType].total.toFixed(2),
                };
            }),
        }));
    }
};
type ValueRecord = {[dataGroupName: string]: {name: string; value: number}[]};
const createDataRecord = (data: CalculateAnalysisResponse[], dataType: ReportDataSet): ValueRecord => {
    return data.reduce((accumulatedRecord: ValueRecord, currCalcResult, i) => {
        let dataValues = {};
        if (dataType === 'valueChainEmissions') {
            dataValues = Object.keys(currCalcResult.valueChainEmissions).reduce(
                (accDataValues: ValueRecord, currEmissionType) => {
                    const valueChainEmissionData = accumulatedRecord[currEmissionType] || [];

                    valueChainEmissionData.push({
                        name: currCalcResult.productName,
                        value: Number(
                            currCalcResult.valueChainEmissions[
                                currEmissionType as keyof ValueChainEmissionsResp
                            ].toFixed(2),
                        ),
                    });
                    return {
                        ...accDataValues,
                        [currEmissionType]: valueChainEmissionData,
                    };
                },
                {},
            );
        } else {
            dataValues = currCalcResult.packagings.reduce((accDataValues: ValueRecord, currPackaging) => {
                const packagingData = accumulatedRecord[currPackaging.packagingGroupName] || [];

                packagingData.push({
                    name: currCalcResult.productName,
                    value: Number(currPackaging[dataType].total.toFixed(2)),
                });

                return {
                    ...accDataValues,
                    [currPackaging.packagingGroupName]: packagingData,
                };
            }, {});
        }
        accumulatedRecord = {
            ...accumulatedRecord,
            ...dataValues,
        };

        return accumulatedRecord;
    }, {});
};
const transformToAnalysisTableData = (
    data: CalculateAnalysisResponse[],
    dataType: ReportDataSet,
): AnalysisTableRowData[] => {
    const dataRecord = createDataRecord(data, dataType);
    return Object.keys(dataRecord).map((pg, i) => ({
        id: pg + '_' + i,
        packagingGroupName: pg,
        dataPoints: dataRecord[pg],
    }));
};

export const AnalysisResults: FC<{
    resultsData: CalculateAnalysisResponse[];
    onRefresh: () => void;
    analysisId: number;
    analysisTitle: string;
    analysisText?: string;
}> = ({resultsData, onRefresh, analysisId, analysisTitle, analysisText}) => {
    const {getLangString} = useLanguage();
    const [currentDataType, setCurrentDataType] = useState<ReportDataSet>('packagingResults');
    const handleTabChange = (selectedTab: ReportDataSet) => {
        setCurrentDataType(selectedTab);
    };

    const currentCompanyId = useCurrentCompanyId();
    const packaging = useQuery(['packagingGroups'], () => getPackagingGroups(currentCompanyId), {
        enabled: currentCompanyId > 0,
    });

    const chartData = useMemo(
        () => transformToAnalysisChartData(resultsData, currentDataType),
        [resultsData, currentDataType],
    );
    const tableData = useMemo(
        () => transformToAnalysisTableData(resultsData, currentDataType),
        [resultsData, currentDataType],
    );

    const [isCreatingPDF, setIsCreatingPDF] = useState<boolean>(false);
    const componentToPrintRef = useRef<HTMLDivElement | null>(null);
    const generateAndDownloadPDF = async () => {
        if (componentToPrintRef && componentToPrintRef.current) {
            setIsCreatingPDF(true);
            await generatePDF(componentToPrintRef.current, analysisTitle, analysisTitle, analysisText);
            setIsCreatingPDF(false);
        }
    };
    const getAnalysisReportType = (currentDataType: ReportDataSet): AnalysisReportType => {
        switch (currentDataType) {
            case 'emissionResults':
                return AnalysisReportType.Emission;
            case 'valueChainEmissions':
                return AnalysisReportType.GWP;
            default:
                return AnalysisReportType.Packaging;
        }
    };
    const {getReportDataSetText} = useGetReportTexts();
    const doExportAnalysis = useQuery(
        ['exportAnalysis'],
        () => exportAnalysis(analysisId, getAnalysisReportType(currentDataType)),
        {enabled: false},
    );
    const constructFileName = () => {
        return getLangString('ANALYSIS') + '_' + analysisTitle + '_' + getReportDataSetText(currentDataType) + '.xlsx';
    };
    const handleExportAnalysis = () => {
        doExportAnalysis.refetch().then((resp) => {
            handleFileDownload(resp.data, constructFileName());
        });
    };

    if (packaging.isLoading) {
        return <CircularProgressCentered />;
    }
    if (packaging.isError) {
        return <Box>Klarte ikke å hente data</Box>;
    }
    const chartColors =
        currentDataType === 'valueChainEmissions'
            ? getChartColorsValueChainEmission()
            : getChartColorsPackaging(packaging.data);
    const tableHeaders = resultsData.map((r) => r.productName);

    return (
        <Box ref={componentToPrintRef}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <DataTypeButtonGroup currentTab={currentDataType} handleTabChange={handleTabChange} />
                <Box display={'flex'} data-html2canvas-ignore='true' height={'fit-content'}>
                    <Button onClick={onRefresh} variant={'contained'} size={'large'}>
                        {getLangString('UPDATE')}
                    </Button>
                    <ButtonWithMenu
                        buttonText={getLangString('EXPORT')}
                        buttonProps={{variant: 'outlined', sx: {ml: 1}, size: 'large'}}
                        menuItems={[
                            {name: 'PDF', onClick: generateAndDownloadPDF},
                            {name: 'Excel', onClick: handleExportAnalysis},
                        ]}
                        loading={isCreatingPDF}
                    />
                </Box>
            </Box>
            <Box mt={4} mb={6}>
                <Typography variant={'h3'}>{getLangString('RESULTS')}</Typography>
                <StackedBarChart
                    id={0}
                    chartName={''}
                    data={chartData}
                    index={0}
                    width={'100%'}
                    colors={chartColors}
                    labelPosition={chartData.length > 10 ? 'vertical' : 'horizontal'}
                    showLegend={true}
                />
            </Box>
            <ResultsTable data={tableData} groupHeaders={tableHeaders} dataType={currentDataType} />
        </Box>
    );
};
