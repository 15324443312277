import {axiosInstance} from '../axiosInstance';
import {TransportDto, UpdateTransport} from '../dtos/transportDto';
import {transportPath} from '../paths';

export const getTransports = async (companyID: number) =>
    await axiosInstance.get<TransportDto[]>(`${transportPath.base()}?companyId=${companyID}`).then((res) => res.data);

export const createTransport = async (data: UpdateTransport) => await axiosInstance.post(transportPath.base(), data);

export const updateTransport = async (reqData: {transportId: number; data: UpdateTransport}) =>
    await axiosInstance.patch(transportPath.byId(reqData.transportId), reqData.data);

export const deleteTransport = async (transportId: number) =>
    await axiosInstance.delete(transportPath.byId(transportId));
