import {PackagingType} from './productConfigDto';
import {ProductPackagingCalculationsResp, ValueChainEmissionsResp} from './reportsDto';

type AnalysisProductGroup = {productGroupId: number; productGroupName: string};
type AnalysisProductGroupType = {productGroupTypeId: number; productGroupTypeName: string};
type AnalysisTransport = {transportId: number; transportName: string};

type AnalysisProductPackaging = {
    productPackagingId: number;
    packagingId: number;
    packagingName: string;
    packagingType: PackagingType;
    netWeightGrams: number;
    recycablePercentage: number;
    comment: string;
};

export type AnalysisProductDto = {
    productId: string;
    productName: string;
    productGroup: AnalysisProductGroup;
    productGroupType: AnalysisProductGroupType;
    transport: AnalysisTransport;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    wastePercentage: number; //decimal
    comment: string;
    productPackagings: AnalysisProductPackaging[];
};

export type CreateAnalysisDto = {
    analysisName: string;
    description: string;
    products: UpdateAnalysisProduct[];
};
export type UpdateAnalysisDto = {
    analysisName: string;
    description: string;
    products: UpdateAnalysisProduct[];
};
export type UpdateAnalysisHeaderDto = {
    analysisName: string;
    description: string;
}
export type UpdateAnalysisProductPackaging = {
    productPackagingId: number;
    packagingId: number;
    packagingType: PackagingType;
    netWeightGrams: number;
    recycablePercentage: number;
    comment: string;
};
export type UpdateAnalysisProduct = {
    productName: string;
    productGroupId: number;
    transportId: number;
    netWeightGrams: number;
    fpakDpak: number;
    dpakPallet: number;
    wastePercentage: number; //decimal
    comment: string;
    productPackagings: UpdateAnalysisProductPackaging[];
    productId?: string; //defined if product already exists, undefined if new product
};

export type Analysis = {
    analysisId: number;
    analysisName: string;
    createdAt: Date;
    updatedAt: Date;
    description: string;
    products: AnalysisProductDto[];
    ownerName: string;
};

export type CalculateAnalysisResponse = {
    productName: string;
    packagings: ProductPackagingCalculationsResp[];
    valueChainEmissions: ValueChainEmissionsResp;
};

export enum AnalysisReportType {
    Packaging = 3,
    Emission = 7,
    GWP = 8,
}
