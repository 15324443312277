import {Add, Delete, Edit} from '@mui/icons-material';
import {Box, Button, IconButton, List, ListItem, ListItemText, Typography} from '@mui/material';
import {AxiosPromise} from 'axios';
import React, {useState} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {MixedRequestError, useProblemDetailsError} from '../../utils/useProblemDetailsError';
import {CustomAlertDialog} from '../CustomAlertDialog';
import {DialogDelete} from '../DialogDelete';
import {EditableText} from '../EditableText';

export type PrimaryGroup = {id: number; name: string};

export const GroupItem: React.FC<
    PrimaryGroup & {
        onEditSave: (id: number, name: string) => AxiosPromise;
        onDeleteClick: (id: number) => void;
    }
> = (props) => {
    const [hovering, setHovering] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = async (name: string) => {
        await props.onEditSave(props.id, name);
        setIsEditing(false);
    };
    const handleDelete = () => {
        props.onDeleteClick(props.id);
    };

    return isEditing ? (
        <EditableText value={props.name} onCancel={() => setIsEditing(false)} onSave={handleSave} />
    ) : (
        <ListItem
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            sx={{height: 48, '&:hover': {backgroundColor: 'rgba(0,0,0,0.02)'}}}
        >
            <ListItemText>{props.name}</ListItemText>
            {hovering && (
                <>
                    <IconButton onClick={() => setIsEditing(true)}>
                        <Edit />
                    </IconButton>
                    <IconButton color={'error'} onClick={handleDelete}>
                        <Delete />
                    </IconButton>
                </>
            )}
        </ListItem>
    );
};
type PrimaryGroupItemsProps = {
    headerText: string;
    groups: PrimaryGroup[];
    onUpdateItem: (id: number, name: string) => AxiosPromise;
    onCreateItem: (name: string) => AxiosPromise;
    onDeleteItem: (id: number) => AxiosPromise;
};
export const PrimaryGroupItems: React.FC<PrimaryGroupItemsProps> = (props) => {
    const {getLangString} = useLanguage();
    const [addingProductGroup, setAddingProductGroup] = useState(false);
    const [deletingProductGroupId, setDeletingProductGroupId] = useState<number | undefined>();
    const addProductGroupType = () => {
        setAddingProductGroup(true);
    };
    const handleCreate = async (name: string) => {
        await props.onCreateItem(name);
        setAddingProductGroup(false);
    };
    const {translatedError, translate, clearTranslatedError} = useProblemDetailsError({
        ILLEGAL_DELETE_ENTITY_HAS_CHILDREN: getLangString('ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN'),
    });
    const handleDelete = async () => {
        if (deletingProductGroupId) {
            clearTranslatedError();
            try {
                await props.onDeleteItem(deletingProductGroupId);
                setDeletingProductGroupId(undefined);
            } catch (e) {
                translate(e as MixedRequestError);
            }
        }
    };
    const dismissAlertDialog = () => {
        setDeletingProductGroupId(undefined);
        clearTranslatedError();
    };
    return (
        <Box mt={8} sx={{maxWidth: 400, mb: 5}}>
            <Typography variant={'h4'}>{props.headerText}</Typography>
            <List>
                {props.groups.map((group) => (
                    <GroupItem
                        key={group.id}
                        id={group.id}
                        name={group.name}
                        onEditSave={props.onUpdateItem}
                        onDeleteClick={setDeletingProductGroupId}
                    />
                ))}
            </List>
            {addingProductGroup ? (
                <EditableText onCancel={() => setAddingProductGroup(false)} onSave={handleCreate} />
            ) : (
                <Button onClick={addProductGroupType} startIcon={<Add />} variant={'text'}>
                    Legg til gruppe
                </Button>
            )}
            <DialogDelete
                isOpen={!!deletingProductGroupId}
                headerText={'Slette hovedgruppe'}
                bodyText={'Vil du slette denne gruppen?'}
                onCancel={() => setDeletingProductGroupId(undefined)}
                onDelete={handleDelete}
            />
            <CustomAlertDialog
                isOpen={!!translatedError}
                headerText={getLangString('ERROR_MSG__ILLEGAL_DELETE_ENTITY_HAS_CHILDREN_HEADER')}
                bodyText={translatedError!}
                onClose={dismissAlertDialog}
            />
        </Box>
    );
};
