/**
 * Configuration object for Azure AD B2C
 */
import {Configuration, LogLevel} from '@azure/msal-browser';
import {APP_CONFIG} from './AppConfig';

export const B2C_POLICIES = {
    authorities: {
        ...APP_CONFIG.b2c.authorities,
    },
    authorityDomain: APP_CONFIG.b2c.authorityDomain,
};
export const MSAL_CONFIG: Configuration = {
    //
    auth: {
        clientId: APP_CONFIG.b2c.clientId,
        authority: B2C_POLICIES.authorities.signIn.authority,
        knownAuthorities: [B2C_POLICIES.authorityDomain],
        redirectUri: window.location.origin,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        //
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: unknown) => {
                if (containsPii) {
                    return;
                }

                // disable logs in other environments
                if (process.env.NODE_ENV !== 'development') return;
                return;

                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const FORGOT_PASSWORD_REQUEST = {
    ...B2C_POLICIES.authorities.forgotPassword,
};
export const LOGIN_REQUEST = {
    scopes: [...B2C_POLICIES.authorities.signIn.scopes],
};
