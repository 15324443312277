import {Box, Button, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useCurrentCompanyId} from 'lib/hooks/useCurrentCompany';
import {FC, useEffect, useState} from 'react';
import {UpdateAnalysisProduct} from '../../api/dtos/analysisDto';
import {useGetAnalysisProducts} from '../../api/hooks/analysis';
import {useGetProductGroupTypes} from '../../api/hooks/product';
import {useLanguage} from '../../language/LanguageProvider';
import {CircularProgressCentered} from '../ProgressComponents';

export const SelectExistingProduct: FC<{
    onSelectProduct: (product: UpdateAnalysisProduct) => void;
    onCancel: () => void;
}> = ({onSelectProduct, onCancel}) => {
    const {getLangString} = useLanguage();
    const [selectedGroupTypeId, setSelectedGroupTypeId] = useState<number | undefined>();
    const [selectedProductIndex, setSelectedProductIndex] = useState<string | undefined>();

    const [filteredProducts, setFilteredProducts] = useState<UpdateAnalysisProduct[]>([]);

    const currentCompanyId = useCurrentCompanyId();

    const analysisProducts = useGetAnalysisProducts(currentCompanyId);
    const {data, isLoading, isError} = useGetProductGroupTypes(currentCompanyId);

    useEffect(() => {
        if (analysisProducts.data) {
            const filtered = analysisProducts.data.filter(
                (ap) => ap.productGroupType.productGroupTypeId === selectedGroupTypeId,
            );
            const newData = filtered.map((p) => ({
                ...p,
                productGroupId: p.productGroup.productGroupId,
                transportId: p.transport.transportId,
            }));
            setFilteredProducts(newData);
        }
    }, [analysisProducts.data, selectedGroupTypeId]);

    const handleGroupTypeChange = (e: SelectChangeEvent) => {
        setSelectedGroupTypeId(parseInt(e.target.value));
    };
    const handleProductChange = (e: SelectChangeEvent) => {
        setSelectedProductIndex(e.target.value);
    };
    const handleSelectProduct = async () => {
        const product = selectedProductIndex && filteredProducts[parseInt(selectedProductIndex)];
        if (product) {
            await onSelectProduct(product);
            setSelectedGroupTypeId(undefined);
            setSelectedProductIndex(undefined);
        }
    };

    if (isError) {
        return <Box>Noe feilet</Box>;
    }
    if (isLoading || analysisProducts.isLoading) {
        return <CircularProgressCentered />;
    }
    return (
        <>
            <Select
                value={String(selectedGroupTypeId)}
                onChange={handleGroupTypeChange}
                renderValue={selectedGroupTypeId ? undefined : () => <div>Velg gruppe</div>}
                disabled={data.length === 0}
                sx={{mr: 1}}
            >
                {data.map((productType) => (
                    <MenuItem key={productType.productGroupTypeId} value={productType.productGroupTypeId}>
                        {productType.productGroupTypeName}
                    </MenuItem>
                ))}
            </Select>
            <Select
                value={String(selectedProductIndex)}
                onChange={handleProductChange}
                disabled={!selectedGroupTypeId}
                renderValue={selectedProductIndex ? undefined : () => <div>{getLangString('SELECT_PRODUCT')}</div>}
            >
                {filteredProducts.map((product, index) => (
                    <MenuItem key={index} value={index.toString()}>
                        {product.productName}
                    </MenuItem>
                ))}
            </Select>
            <Button onClick={onCancel} sx={{ml: 2}}>
                {getLangString('CANCEL')}
            </Button>
            <Button onClick={handleSelectProduct} variant={'contained'} disabled={!selectedProductIndex} sx={{ml: 1}}>
                {getLangString('SELECT')}
            </Button>
        </>
    );
};
