/**
 * Role types
 */
export enum Role {
    None = -1,
    User = 1,
    CompanyAdmin = 2,
    SuperUser = 3,
}

export const RolesToLowered = Object.values(Role)
    .filter((v) => {
        return typeof v !== 'number';
    })
    .map((r: unknown) => {
        const letters = (r as string).split('');
        letters[0] = letters[0].toLowerCase();
        return letters.join('');
    });

/**
 * A users role with the role type and the id of the user/entity role
 */
export interface EntityRole {
    /**
     * The role type
     */
    roleId: Role;
    /**
     * The id of the role type.
     * If the role is Cadet, this id is the id of the Cadet.
     */
    roleEntityId: number;
}
