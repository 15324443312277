import {TextSnippet} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import React from 'react';

const UploadedItem: React.FC<{
    fileName: string;
    onRemoveFile?: () => void;
}> = ({fileName, onRemoveFile}) => {
    return (
        <ListItem
            secondaryAction={
                <IconButton onClick={onRemoveFile}>
                    <DeleteIcon />
                </IconButton>
            }
        >
            <ListItemIcon>
                <TextSnippet />
            </ListItemIcon>
            <ListItemText primary={fileName} />
        </ListItem>
    );
};

export type Attachment = {
    declarationAttachmentId: number;
    attachmentId: number;
    attachmentName: string;
    contentType: string;
    extension: string;
};
export const UploadedAttachment: React.FC<{
    attachment: Attachment;
    handleRemoveAttachment: (attachment: Attachment) => void;
}> = ({attachment, handleRemoveAttachment}) => {
    const doRemoveAttachment = () => {
        handleRemoveAttachment(attachment);
    };
    return <UploadedItem fileName={attachment.attachmentName} onRemoveFile={doRemoveAttachment} />;
};

export const UploadedFile: React.FC<{
    file: File;
    handleRemoveFile: (file: File) => void;
}> = ({file, handleRemoveFile}) => {
    const doRemoveAttachment = () => {
        handleRemoveFile(file);
    };
    return <UploadedItem fileName={file.name} onRemoveFile={doRemoveAttachment} />;
};
