import {axiosInstance} from '../axiosInstance';
import {CreateProductConfigRequest, ProductConfigResponse, UpdateProductPackaging} from '../dtos/productConfigDto';
import {productConfigPath} from '../paths';

export const getProductConfigs = async () =>
    await axiosInstance.get<ProductConfigResponse[]>(productConfigPath.active()).then((res) => res.data);

export const getProductConfigById = async (productId: number) =>
    await axiosInstance.get<ProductConfigResponse>(productConfigPath.byId(productId)).then((res) => res.data);

export const getValidProductConfigYears = async (companyId: number) =>
    await axiosInstance
        .get<number[]>(`${productConfigPath.base()}/GetValidProductConfigYears?companyId=${companyId}`)
        .then((res) => res.data);

export const createProductConfig = async (data: CreateProductConfigRequest) =>
    await axiosInstance.post<{productConfigId: number}>(productConfigPath.base(), data).then((res) => res.data);

export const updateProductConfig = async (reqData: {productConfigId: number; data: CreateProductConfigRequest}) =>
    await axiosInstance.patch<{productConfigId: number}, CreateProductConfigRequest>(
        productConfigPath.byId(reqData.productConfigId),
        reqData.data,
    );
export const deleteProductConfig = async (productConfigID: number) =>
    await axiosInstance.delete(productConfigPath.byId(productConfigID));

export const updateProductConfigPackaging = async (reqData: {
    productConfigId: number;
    productPackagingId: number;
    data: UpdateProductPackaging;
}) =>
    await axiosInstance.patch(
        `${productConfigPath.byId(reqData.productConfigId)}/UpdateProductPackaging/${reqData.productPackagingId}`,
        reqData.data,
    );

export const createProductConfigPackaging = async (reqData: {productConfigId: number; data: UpdateProductPackaging}) =>
    await axiosInstance.post(`${productConfigPath.byId(reqData.productConfigId)}/CreateProductPackaging`, reqData.data);
export const deleteProductConfigPackaging = async (reqData: {productConfigId: number; productPackagingId: number}) =>
    await axiosInstance.delete(
        `${productConfigPath.byId(reqData.productConfigId)}/DeleteProductPackaging/${reqData.productPackagingId}`,
    );
