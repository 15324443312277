import {ReportsData, ValueChainEmissionsResp, WeightedCalculationGroup} from '../../api/dtos/reportsDto';
import {ReportDataSet} from '../DataTypeButtonGroup';
import {TableRowData} from './ReportTable';

type ValueRecord = {[dataGroupName: string]: {name: string; value: number}[]};
type DataRecord = {[year: number]: ValueRecord};

const createDataRecord = (
    data: ReportsData,
    calcGroup: keyof WeightedCalculationGroup,
    dataType: ReportDataSet,
): DataRecord => {
    return data.reportData.reduce((accumulatedRecord: DataRecord, currKeyFigure, i) => {
        currKeyFigure.calculations.forEach((currentCalc) => {
            let dataValues = {};
            if (dataType === 'valueChainEmissions') {
                dataValues = Object.keys(currentCalc.valueChainEmissions).reduce(
                    (accDataValues: ValueRecord, currEmissionType) => {
                        const valueChainEmissionData = accumulatedRecord[currentCalc.year]
                            ? accumulatedRecord[currentCalc.year][currEmissionType] || []
                            : [];

                        valueChainEmissionData.push({
                            name: currKeyFigure.groupingName,
                            value: Number(
                                currentCalc.valueChainEmissions[
                                    currEmissionType as keyof ValueChainEmissionsResp
                                ].toFixed(2),
                            ),
                        });
                        return {
                            ...accDataValues,
                            [currEmissionType]: valueChainEmissionData,
                        };
                    },
                    {},
                );
            } else {
                dataValues = currentCalc.packagings.reduce((accDataValues: ValueRecord, currPackaging) => {
                    const packagingData = accumulatedRecord[currentCalc.year]
                        ? accumulatedRecord[currentCalc.year][currPackaging.packagingGroupName] || []
                        : [];

                    packagingData.push({
                        name: currKeyFigure.groupingName,
                        value: Number(currPackaging[dataType][calcGroup].toFixed(2)),
                    });

                    return {
                        ...accDataValues,
                        [currPackaging.packagingGroupName]: packagingData,
                    };
                }, {});
            }
            accumulatedRecord = {
                ...accumulatedRecord,
                [currentCalc.year]: {
                    ...accumulatedRecord[currentCalc.year],
                    ...dataValues,
                },
            };
        });
        return accumulatedRecord;
    }, {});
};

export const transformToTableData = (
    data: ReportsData | undefined,
    calcGroup: keyof WeightedCalculationGroup,
    dataType: ReportDataSet,
): TableRowData[] => {
    if (data) {
        const dataRecord = createDataRecord(data, calcGroup, dataType);
        let tableRows: TableRowData[] = [];
        Object.keys(dataRecord).forEach((year) => {
            const packagingGroup = dataRecord[parseInt(year) as keyof DataRecord];
            const data: TableRowData[] = Object.keys(packagingGroup).map((pg) => ({
                id: year + '_' + pg,
                year: parseInt(year),
                packagingGroupName: pg,
                dataPoints: packagingGroup[pg],
            }));
            tableRows = tableRows.concat(data);
        });
        return tableRows;
    }

    return [];
};

export const getYearsInDataSet = (data: TableRowData[]): number[] => {
    return data.reduce((acc: number[], curr) => {
        const exists = acc.some((el) => el === curr.year);
        if (!exists) {
            acc.push(curr.year);
        }
        return acc;
    }, []);
};
