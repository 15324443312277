import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from '@azure/msal-browser';
import {FORGOT_PASSWORD_REQUEST} from '../../config/AzureB2cConfig';

export const MsalSetup = (msalInstance: PublicClientApplication) => {
    //
    // Event listeners for handling events returned from MSAL/B2C
    msalInstance.addEventCallback((message: EventMessage) => {
        if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
            const {payload} = message;
            const {account} = payload as AuthenticationResult;
            msalInstance.setActiveAccount(account);
        }

        if (message.eventType === EventType.LOGIN_FAILURE) {
            //
            // Error message that indicated forgot password
            if (message.error?.message?.includes('AADB2C90118')) {
                // Start login redirect for forgot password
                msalInstance.loginRedirect({
                    ...FORGOT_PASSWORD_REQUEST,
                });
            }
        }
    });
};
