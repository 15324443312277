import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {FC} from 'react';
import {useLanguage} from '../language/LanguageProvider';

type DialogProps = {
    isOpen: boolean;
    headerText: string;
    bodyText: string;
    onClose: () => void;
};
export const CustomAlertDialog: FC<DialogProps> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <Dialog open={props.isOpen}>
            <DialogTitle variant={'h3'}>{props.headerText}</DialogTitle>
            <DialogContent>
                <Typography>{props.bodyText}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{getLangString('OK')}</Button>
            </DialogActions>
        </Dialog>
    );
};
