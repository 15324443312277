import {QuestionMarkRounded} from '@mui/icons-material';
import {Box, IconButton, Popover} from '@mui/material';
import React from 'react';

type Props = {
    content: JSX.Element;
};
export const QuestionButton: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                onClick={handleClick}
                size={'small'}
                sx={{ml: 1, border: '1px solid #d1d1d1', borderRadius: '50%'}}
            >
                <QuestionMarkRounded />
            </IconButton>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>{props.content}</Box>
            </Popover>
        </>
    );
};
