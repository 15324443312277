import {ArrowBack} from '@mui/icons-material';
import {Button} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

type Props = {
    text: string;
};
export const BackButton: React.FC<Props> = ({text}) => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Button onClick={goBack} startIcon={<ArrowBack />}>
            {text}
        </Button>
    );
};
