import {Box, Typography} from '@mui/material';
import React from 'react';
import {Bar, BarChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {ChartColors} from '../utils/chartColors';

export type ChartDataPoints = {
    label: number | string;
    values: {
        name: string;
        value: string;
    }[];
};

export type StackedBarChartProps = {
    id: number;
    chartName: string;
    data: ChartDataPoints[];
    width: string;
    index: number;
    onChartClick?: (id: number) => void;
    colors: ChartColors;
    maxValue?: number;
    labelPosition?: 'horizontal' | 'vertical';
    showLegend?: boolean;
};

export const StackedBarChart: React.FC<StackedBarChartProps> = (props) => {
    const handleChartClick = () => props.onChartClick && props.onChartClick(props.id);

    const getBarElements = () => {
        const names = props.data.reduce((acc: string[], curr) => {
            curr.values.forEach((value) => {
                if (!acc.some((el) => el === value.name)) {
                    acc.push(value.name);
                }
            });
            return acc;
        }, []);

        return names.map((name, i) => {
            return <Bar key={i} dataKey={name} stackId='a' fill={props.colors[name] || '#009192'} />;
        });
    };
    const getTransformedData = () => {
        return props.data.map((d) => {
            const object = {label: d.label};
            const properties = d.values.reduce((acc, curr) => ({...acc, [curr.name]: curr.value}), {});
            return {...object, ...properties};
        });
    };
    return (
        <ResponsiveContainer width={props.width} height={300}>
            <BarChart
                data={getTransformedData()}
                margin={{
                    top: 20,
                    right: 0,
                    left: props.index === 0 ? 0 : 60,
                    bottom: 48,
                }}
                barCategoryGap={4}
            >
                {props.labelPosition === 'horizontal' ? (
                    <XAxis dataKey={'label'} type={'category'}>
                        <Label
                            onClick={handleChartClick}
                            value={props.chartName}
                            offset={60}
                            position={'bottom'}
                            style={props.onChartClick && {textDecoration: 'underline', cursor: 'pointer'}}
                        />
                    </XAxis>
                ) : (
                    <XAxis dataKey={'label'} type={'category'} angle={90} textAnchor={'start'}>
                        <Label
                            onClick={handleChartClick}
                            value={props.chartName}
                            offset={30}
                            position={'bottom'}
                            style={props.onChartClick && {textDecoration: 'underline', cursor: 'pointer'}}
                        />
                    </XAxis>
                )}
                <YAxis domain={props.maxValue ? [0, props.maxValue] : undefined} hide={props.index !== 0} />
                <Tooltip />
                {getBarElements()}
                {props.showLegend && <Legend layout={'horizontal'} />}
            </BarChart>
        </ResponsiveContainer>
    );
};
export type ChartData = {
    id: number;
    chartName: string;
    data: ChartDataPoints[];
    maxValue: number;
};
type BarChartGroupProps = {
    chartsData: ChartData[];
    onClick?: (id: number) => void;
    colorRecord: ChartColors;
};
export const StackedBarChartGroup: React.FC<BarChartGroupProps> = (props) => {
    const handleChartClick = (id: number) => {
        props.onClick && props.onClick(id);
    };
    const chartsWithData = props.chartsData.filter((d) => d.data.length > 0);
    return (
        <>
            <Box display={'flex'} mt={2}>
                {chartsWithData.map((d, i) => (
                    <StackedBarChart
                        key={d.id}
                        id={d.id}
                        chartName={d.chartName}
                        data={d.data}
                        index={i}
                        width={100 / chartsWithData.length + '%'}
                        onChartClick={props.onClick && handleChartClick}
                        colors={props.colorRecord}
                        maxValue={d.maxValue}
                    />
                ))}
            </Box>
            <Box mt={3} mx={5}>
                <LegendChartGroup legendItems={props.colorRecord} />
            </Box>
        </>
    );
};

const LegendChartGroup: React.FC<{legendItems: ChartColors}> = ({legendItems}) => {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
            {Object.keys(legendItems).map((key) => (
                <Box key={key} color={legendItems[key]} display={'flex'} alignItems={'center'} mr={2}>
                    <Box sx={{width: 12, height: 12, backgroundColor: legendItems[key], mr: 1}} />
                    <Typography color={'inherit'} variant={'body2'}>
                        {key}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};
